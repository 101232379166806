// React
import React from 'react';

// Constants
import { TABLE_WITH_FILTERS_HEIGHT, DETAIL_PANEL_PROPS } from 'constants/table';
import { ROW_NUMBER_PROPS, ROW_EXPAND_PROPS } from 'constants/tableHead';

// Elements
import MatchupTable from 'components/atoms/MatchupTable/MatchupTable';
import GamesTable from 'components/molecules/GamesTable/GamesTable';

export const BASIC_TABLE = {
  layoutMode: 'grid-no-grow',
  enableFullScreenToggle: false,
  enableHiding: false,
  enableDensityToggle: false,
  enableFilterMatchHighlighting: false,
  enableColumnActions: false,
  enableStickyHeader: true,
  enableColumnFilters: false,
  muiTableContainerProps: { sx: { maxHeight: TABLE_WITH_FILTERS_HEIGHT } },
};

export const HIDE_TOP_TOOLBAR = {
  ...BASIC_TABLE,
  enableTopToolbar: false,
};

export const HIDE_PAGINATION = {
  ...BASIC_TABLE,
  enableBottomToolbar: false,
  enablePagination: false,
  enableTableFooter: false,
};

export const ROW_NUMBERS = {
  ...BASIC_TABLE,
  enableRowNumbers: true,
  displayColumnDefOptions: {
    'mrt-row-numbers': ROW_NUMBER_PROPS,
  },
};

export const DETAIL_PANEL_TABLE = {
  ...BASIC_TABLE,
  enableExpandAll: false,
  displayColumnDefOptions: {
    'mrt-row-expand': ROW_EXPAND_PROPS,
  },
  muiDetailPanelProps: DETAIL_PANEL_PROPS,
};

export const ENABLE_FILTERS = {
  ...BASIC_TABLE,
  columnFilterDisplayMode: 'popover',
  enableFacetedValues: true,
  enableColumnFilters: true,
};

export const ENABLE_GROUPING = {
  ...BASIC_TABLE,
  enableGrouping: true,
  enableExpandAll: false,
};

export const FULL_HEIGHT = {
  muiTableContainerProps: { sx: { maxHeight: 'inherit' } },
};

export const MATCHES_TABLE = (columnsData, isTeamData) => ({
  ...HIDE_TOP_TOOLBAR,
  ...HIDE_PAGINATION,
  ...DETAIL_PANEL_TABLE,
  enableTableFooter: true,
  enableStickyFooter: true,
  muiTableFooterCellProps: { sx: { justifyContent: 'center' } },
  renderDetailPanel: ({
    row: {
      original: { data: detailData },
    },
  }) => <GamesTable data={detailData} columns={columnsData} showLink />,
  initialState: {
    columnPinning: {
      left: isTeamData
        ? ['date', 'image', 'mrt-row-expand']
        : ['date', 'image_1', 'image_2', 'mrt-row-expand'],
    },
  },
});

export const MATCHUPS_TABLE = (columnsData) => ({
  ...HIDE_TOP_TOOLBAR,
  ...HIDE_PAGINATION,
  ...DETAIL_PANEL_TABLE,
  renderDetailPanel: ({
    row: {
      original: { data: detailData },
    },
  }) => <MatchupTable data={detailData} columns={columnsData} />,
  initialState: {
    columnPinning: { left: ['mrt-row-expand', 'image'] },
  },
});

export const PERFORMANCE_TABLE = (columnsData) => ({
  ...HIDE_TOP_TOOLBAR,
  ...HIDE_PAGINATION,
  ...DETAIL_PANEL_TABLE,
  enableTableFooter: true,
  enableStickyFooter: true,
  muiTableFooterCellProps: { sx: { justifyContent: 'center' } },
  renderDetailPanel: ({
    row: {
      original: { data: detailData },
    },
  }) => <GamesTable data={detailData} columns={columnsData} />,
  initialState: {
    columnPinning: { left: ['date', 'image', 'mrt-row-expand'] },
  },
});

export const HOURS_TABLE = (columnsData) => ({
  ...HIDE_TOP_TOOLBAR,
  ...HIDE_PAGINATION,
  ...DETAIL_PANEL_TABLE,
  renderDetailPanel: ({
    row: {
      original: { data: detailData },
    },
  }) => <GamesTable data={detailData} columns={columnsData} />,
  initialState: {
    columnPinning: { left: ['hour', 'mrt-row-expand'] },
  },
});
