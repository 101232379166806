// React
import React, { useState } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// React Router
import { Link } from 'react-router-dom';

// Constants
import { RED_MAIN, WHITE_MAIN, YELLOW_MAIN } from 'constants/color';

// Utils
import { replaceWithBr } from 'utils/text';

// Assets
import TimerIcon from '@mui/icons-material/Timer';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Elements
import { Box, Tab, IconButton, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// Component
const VODsCompetitive = ({ data }) => {
  const [value, setValue] = useState('0');

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {data.map(({ link, duration, goal }, vodIndex) => (
            <Tab
              key={`${link}-${goal}-${duration}`}
              value={vodIndex.toString()}
              label={
                <Box display="flex" alignItems="center" px={1}>
                  <IconButton
                    component={Link}
                    size="small"
                    sx={{ p: 1 / 2 }}
                    to={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YouTubeIcon fontSize="small" sx={{ color: RED_MAIN }} />
                  </IconButton>
                  <Typography variant="body2" component="span" color={WHITE_MAIN} ml={1}>
                    VOD {vodIndex + 1}
                  </Typography>
                </Box>
              }
            />
          ))}
        </TabList>
        {data.map(({ link, duration, goal, conclusions, notes }, vodIndex) => (
          <TabPanel key={`${link}-${goal}-${duration}`} value={vodIndex.toString()}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" textTransform="uppercase">
                {goal}
              </Typography>
              <Box display="flex" alignItems="center" ml={2}>
                <TimerIcon fontSize="small" sx={{ color: YELLOW_MAIN }} />
                <Typography variant="body1" ml={1 / 2}>
                  {duration}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="h6">Conclusions:</Typography>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: replaceWithBr(conclusions) }}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="h6">Notes:</Typography>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: replaceWithBr(notes) }}
              />
            </Box>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

VODsCompetitive.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VODsCompetitive;
