// Styles
import styled from 'styled-components';

export const StyledBox = styled.div`
  && {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: ${({ theme }) => theme.palette.black.main};
    background: ${({ theme }) => theme.palette.white.main};
    z-index: 1;
  }
`;

export const StyledInfo = styled.div`
  && {
    padding: 0 5px;
  }
`;

export const StyledTimer = styled.div`
  && {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    min-height: 20px;
    font-size: 11px;
    color: ${({ theme }) => theme.palette.black.main};
    background: ${({ theme }) => theme.palette.white.main};
    padding: 0 5px;
    z-index: 1;
  }
`;
