// React
import React, { useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Assets
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Elements
import { Checkbox, TextField, Autocomplete, Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Component
const MultiAutocomplete = ({
  name,
  label,
  optionLabel,
  options,
  value,
  compareValue,
  disabled,
  onChangeFunction,
  onChangeField,
  error,
  helperText,
  isOptionEqualToValue,
}) => {
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(value);
  }, [value]);

  const handleAutocompleteChange = (_, newValue) => {
    setNewData(newValue);
    onChangeFunction(onChangeField, newValue);
  };

  const renderTags = (el, getTagProps) =>
    el.map((option, index) => (
      <Chip key={option.id} label={option[optionLabel]} {...getTagProps({ index })} />
    ));

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      {option[optionLabel]}
    </li>
  );

  const getOptionLabel = (option) => option[optionLabel];

  const renderInput = (params) => (
    <TextField {...params} label={label} error={error} helperText={helperText} />
  );

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      disableCloseOnSelect
      disabled={disabled}
      name={name}
      options={options}
      value={newData}
      onChange={handleAutocompleteChange}
      isOptionEqualToValue={
        isOptionEqualToValue == null
          ? (option, valueData) => option[compareValue] === valueData[compareValue]
          : isOptionEqualToValue
      }
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

MultiAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeFunction: PropTypes.func.isRequired,
  onChangeField: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  compareValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isOptionEqualToValue: PropTypes.func,
};

MultiAutocomplete.defaultProps = {
  name: null,
  optionLabel: 'name',
  value: null,
  compareValue: 'id',
  disabled: false,
  error: false,
  helperText: '',
  isOptionEqualToValue: null,
};

export default MultiAutocomplete;
