// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchSoloQLpByTeamReport } from 'services/soloq';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { ROW_NUMBERS, HIDE_PAGINATION } from 'constants/mrt';
import { SOLOQ_LP_BY_TEAMS_COLS } from 'constants/tableHead';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';

const SoloQLpByTeamReportView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'SoloQ LP by Team';

  const fetchData = () => {
    setIsLoading(true);

    fetchSoloQLpByTeamReport().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();

      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const columns = useMemo(() => SOLOQ_LP_BY_TEAMS_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...ROW_NUMBERS,
    ...HIDE_PAGINATION,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default SoloQLpByTeamReportView;
