// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const ShopData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue() || Object.keys(getValue()).length === 0) {
    return null;
  }

  return (
    <Box display="flex" gap={5}>
      {Object.entries(getValue()).map(([time, items]) => (
        <Box key={time} display="flex" flexDirection="column">
          <Typography variant="body2" component="span" textAlign="center" sx={{ fontSize: 12 }}>
            {secondsToHms(time)}
          </Typography>
          <Box display="flex">
            {items.map(({ id, name, image, sell }) => {
              const count = id.split('_')[1];
              const data = [];

              for (let i = 0; i < count; i += 1) {
                data.push(
                  <ImageItem
                    key={`${id}_${i}`}
                    src={image}
                    alt={name}
                    ban={sell}
                    width={28}
                    height={28}
                  />,
                );
              }

              return data;
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

ShopData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default ShopData;
