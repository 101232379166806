// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchRunes = () =>
  axiosLoadingInstance
    .get('runes/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importRunes = () =>
  axiosInstance
    .get('runes/import/')
    .then((res) => ({ data: res.data, message: 'Runes have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteRune = (id) =>
  axiosInstance
    .delete(`runes/${id}/`)
    .then((res) => ({ data: res.data, message: 'Rune has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
