// React
import React from 'react';

// React Router
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { WHITE_MAIN } from 'constants/color';

// Elements
import { Typography, Card, CardActionArea, CardContent } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const LinkCard = ({ text, link, item, width, height, autoWidth, autoHeight }) => (
  <Card>
    <Link to={link}>
      <CardActionArea>
        <CardContent>
          <Typography
            variant="body2"
            component="p"
            fontWeight="bold"
            textAlign="center"
            color={WHITE_MAIN}
            gutterBottom
          >
            {text}
          </Typography>
          <ImageItem
            src={item?.image_url}
            alt={item?.name}
            width={width}
            height={height}
            autoWidth={autoWidth}
            autoHeight={autoHeight}
          />
        </CardContent>
      </CardActionArea>
    </Link>
  </Card>
);

LinkCard.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  item: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  autoWidth: PropTypes.bool,
  autoHeight: PropTypes.bool,
};

LinkCard.defaultProps = {
  item: {},
  width: 64,
  height: 64,
  autoWidth: false,
  autoHeight: false,
};

export default LinkCard;
