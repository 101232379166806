export const BLUE = 'BLUE';
export const RED = 'RED';

export const SIDES = [
  {
    id: 1,
    value: BLUE,
    label: BLUE,
  },
  {
    id: 2,
    value: RED,
    label: RED,
  },
];
