// React
import React, { useContext, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminNationalitiesURL } from 'routes/admin';

// Services
import { addNationality } from 'services/nationalities';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminNationalitiesAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Nationalities ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    short: '',
    image_url: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    short: Yup.string().required('Required'),
    image_url: Yup.string().url().required('Required'),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'short',
      label: 'Short',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFn={addNationality}
      formikRedirect={adminNationalitiesURL}
      formikBtnText="Add nationality"
    />
  );
};

export default AdminNationalitiesAddView;
