// Styles
import styled from 'styled-components';

export const StyledBox = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.main};
  z-index: 9999;
  opacity: ${({ loading }) => (loading ? 1 : 0)};
  visibility: ${({ loading }) => (loading ? 'visible' : 'hidden')};
  transition: ${({ loading }) => (loading ? 'none' : 'all 0.5s ease')};
`;
