// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Constants
import { MEAL_ATE } from 'constants/performance';

// Elements
import { Grid, Box, FormControlLabel, Checkbox } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';
import Select from 'components/atoms/Select/Select';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';

// Component
const PerformanceManageData = ({
  id,
  label,
  sportId,
  lunchId,
  snackId,
  dinnerId,
  disabled,
  values,
  options,
  touched,
  errors,
  handleChange,
  setFieldValue,
}) => {
  const performanceMealComponent = (mealId, mealLabel) => {
    const tempInfoId = `${mealId}_info`;

    return (
      <Box>
        <Select
          key={mealId}
          disabled={MEAL_ATE.length === 0}
          error={touched[mealId] && !!errors[mealId]}
          helperText={touched[mealId] && errors[mealId]}
          name={mealId}
          label={mealLabel}
          options={MEAL_ATE}
          value={values[mealId]}
          onChange={handleChange}
          displayEmpty={false}
        />
        <TextField
          key={tempInfoId}
          error={touched[tempInfoId] && !!errors[tempInfoId]}
          helperText={touched[tempInfoId] && errors[tempInfoId]}
          name={tempInfoId}
          label="Info"
          value={values[tempInfoId]}
          onChange={handleChange}
          fullWidth
        />
      </Box>
    );
  };

  return (
    <Grid container key={id}>
      <Grid item xs={8} sm={6} md={8} lg={6} xl={3}>
        <Autocomplete
          key={id}
          id={id}
          label={label}
          options={options}
          onChange={(_, newValue) => {
            setFieldValue(id, newValue);
          }}
          disabled={disabled || options.length === 0}
          error={touched[id] && errors[id] && true}
          helperText={touched[id] && errors[id]}
          value={values[id]}
        />
      </Grid>
      <Grid item xs={4} xl={1}>
        <Box mt={2}>
          <FormControlLabel
            key={sportId}
            control={<Checkbox id={sportId} name={sportId} />}
            label="Sport"
            checked={values[sportId]}
            onChange={handleChange}
          />
        </Box>
      </Grid>
      <Grid item xs={12} xl={8 / 3}>
        {performanceMealComponent(lunchId, 'Lunch')}
      </Grid>
      <Grid item xs={12} xl={8 / 3}>
        {performanceMealComponent(snackId, 'Snack')}
      </Grid>
      <Grid item xs={12} xl={8 / 3}>
        {performanceMealComponent(dinnerId, 'Dinner')}
      </Grid>
    </Grid>
  );
};

PerformanceManageData.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sportId: PropTypes.string.isRequired,
  lunchId: PropTypes.string.isRequired,
  snackId: PropTypes.string.isRequired,
  dinnerId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

PerformanceManageData.defaultProps = {
  disabled: false,
};

export default PerformanceManageData;
