// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSocialMediaData } from 'services/socialMedia';

// Constants
import { HIDE_TOP_TOOLBAR } from 'constants/mrt';
import { SOCIAL_MEDIA_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid } from '@mui/material';

const SocialMediaView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [dataLEC, setDataLEC] = useState([]);
  const [dataLFL, setDataLFL] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Social Media post game';

  const fetchData = () => {
    setIsLoading(true);

    fetchSocialMediaData().then((res) => {
      setDataLEC(res?.lec || []);
      setDataLFL(res?.lfl || []);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();

      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const columns = useMemo(() => SOCIAL_MEDIA_COLS, []);
  const tableLEC = useMaterialReactTable({
    columns,
    data: dataLEC,
    ...HIDE_TOP_TOOLBAR,
  });
  const tableLFL = useMaterialReactTable({
    columns,
    data: dataLFL,
    ...HIDE_TOP_TOOLBAR,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <Box my={2}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <PageTitle title="LEC" variant="h6" component="h2" />
                <MaterialReactTable table={tableLEC} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTitle title="LFL" variant="h6" component="h2" />
                <MaterialReactTable table={tableLFL} />
              </Grid>
            </Grid>
          </Box>
        </>
      }
    />
  );
};

export default SocialMediaView;
