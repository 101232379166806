// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// Styles
import { StyledNavLink } from './MenuItem.styles';

const MenuItem = ({ text, link, icon, closeFn, inset }) => (
  <StyledNavLink to={link}>
    <ListItemButton onClick={closeFn}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={text} inset={inset} />
    </ListItemButton>
  </StyledNavLink>
);

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.element,
  closeFn: PropTypes.func.isRequired,
  inset: PropTypes.bool,
};

MenuItem.defaultProps = {
  icon: null,
  inset: false,
};

export default MenuItem;
