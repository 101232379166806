// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import CardTitle from 'components/atoms/CardTitle/CardTitle';
import RoleChip from 'components/atoms/RoleChip/RoleChip';
import CardValue from 'components/atoms/CardValue/CardValue';

// Component
const RosterCard = ({ data }) => (
  <Card>
    <CardContent>
      <CardTitle text="Roster" />
      {data.map(({ role: playerRole, player: { id: playerId, name: playerName } }, index) => (
        <Box
          key={playerId}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={index > 0 ? 0.25 : 0}
        >
          <RoleChip text={playerRole} role={playerRole} />
          <CardValue value={playerName} />
        </Box>
      ))}
    </CardContent>
  </Card>
);

RosterCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RosterCard;
