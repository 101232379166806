// Axios
import { axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchSoloQCountReport = async (startDate, endDate, playerMainRole) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}soloq/report/count/`);

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSoloQHoursReport = async (startDate, endDate, playerMainRole) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}soloq/report/hours/`);

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSoloQChampionPoolReport = async (playerMainRole) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}soloq/report/champion-pool/`);

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSoloQMatchesReport = async (playerMainRole) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}soloq/report/matches/`);

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSoloQLpByTeamReport = async () => {
  const url = new URL(`${process.env.REACT_APP_API_URL}soloq/report/lp-by-team/`);

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};
