// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Router
import { useNavigate } from 'react-router-dom';

// Elements
import { Chip } from '@mui/material';

// Component
const TeamChip = ({ text, ml, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <Chip
      label={text}
      size="small"
      onClick={handleClick}
      sx={{
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 700,
        ml,
        pl: 0.5,
        pr: 0.5,
      }}
    />
  );
};

TeamChip.propTypes = {
  text: PropTypes.string.isRequired,
  ml: PropTypes.number,
  link: PropTypes.string,
};

TeamChip.defaultProps = {
  ml: 0,
  link: null,
};

export default TeamChip;
