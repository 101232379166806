// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchSplits = () =>
  axiosLoadingInstance
    .get('splits/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSplitsVisible = () =>
  axiosLoadingInstance
    .get('splits/?visible=true')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleSplit = (id) =>
  axiosLoadingInstance
    .get(`splits/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleSplitBySlugs = (leagueSlug, splitSlug) =>
  axiosLoadingInstance
    .get(`splits/by-slugs/${leagueSlug}/${splitSlug}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importDataForSplit = (id) =>
  axiosInstance
    .get(`splits/import-data/${id}/`)
    .then((res) => ({ data: res.data, message: 'Data have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSplitSoloQ = async (data) =>
  axiosInstance
    .post('splits/soloq/', data)
    .then((res) => ({ data: res.data, message: 'Data have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSplitWinRate = async (splitId, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}splits/win-rate/`);

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSplitLPGain = async (splitId, role, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}splits/lp-gain/`);

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const addSplit = (data) =>
  axiosInstance
    .post('splits/add/', data)
    .then((res) => ({ data: res.data, message: 'Split has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateSplit = (id, data) =>
  axiosInstance
    .put(`splits/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Split has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteSplit = (id) =>
  axiosInstance
    .delete(`splits/${id}/`)
    .then((res) => ({ data: res.data, message: 'Split has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
