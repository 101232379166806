const ANY = 'ANY';
const TOP = 'TOP';
const JNG = 'JNG';
const MID = 'MID';
const BOT = 'BOT';
const SUP = 'SUP';

export const ROLES = [
  {
    id: 1,
    value: TOP,
    label: TOP,
  },
  {
    id: 2,
    value: JNG,
    label: JNG,
  },
  {
    id: 3,
    value: MID,
    label: MID,
  },
  {
    id: 4,
    value: BOT,
    label: BOT,
  },
  {
    id: 5,
    value: SUP,
    label: SUP,
  },
];

export const ROLES_ANY = [
  {
    id: 1,
    value: ANY,
    label: ANY,
  },
  {
    id: 2,
    value: TOP,
    label: TOP,
  },
  {
    id: 3,
    value: JNG,
    label: JNG,
  },
  {
    id: 4,
    value: MID,
    label: MID,
  },
  {
    id: 5,
    value: BOT,
    label: BOT,
  },
  {
    id: 6,
    value: SUP,
    label: SUP,
  },
];

export const ROLES_LIST = ROLES.map((el) => el.value);

export const SYNERGIES = [
  {
    id: 1,
    value: `${BOT}_${SUP}`,
    label: `${BOT}/${SUP}`,
  },
  {
    id: 2,
    value: `${JNG}_${SUP}`,
    label: `${JNG}/${SUP}`,
  },
  {
    id: 3,
    value: `${JNG}_${MID}`,
    label: `${JNG}/${MID}`,
  },
  {
    id: 4,
    value: `${JNG}_${TOP}`,
    label: `${JNG}/${TOP}`,
  },
];

export const T1_COORDS = {
  TOP: {
    BLUE: [0.08, 0.7],
    RED: [0.3, 0.92],
  },
  JNG: {
    BLUE: [0.25, 0.25],
    RED: [0.75, 0.75],
  },
  MID: {
    BLUE: [0.46, 0.46],
    RED: [0.54, 0.54],
  },
  BOT: {
    BLUE: [0.7, 0.11],
    RED: [0.89, 0.3],
  },
  SUP: {
    BLUE: [0.7, 0.05],
    RED: [0.95, 0.3],
  },
};
