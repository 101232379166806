// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Routes
import { splitsSingleBasicURL } from 'routes/app';

// Services
import { fetchSplitsVisible } from 'services/splits';

// Constants
import { SPLITS_COLS } from 'constants/tableHead';

// Elements
import ItemsListTemplate from 'components/molecules/ItemsListTemplate/ItemsListTemplate';

const SplitsView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Splits';

  const fetchData = () => {
    setIsLoading(true);

    fetchSplitsVisible().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const columns = useMemo(() => SPLITS_COLS, []);

  return (
    <ItemsListTemplate
      title={title}
      data={data}
      columns={columns}
      rowActions={{ baseLink: splitsSingleBasicURL, pathVariables: ['league_slug', 'slug'] }}
    />
  );
};

export default SplitsView;
