// React
import React, { useContext, useState, useEffect } from 'react';

// Router
import { useParams, useLocation } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleGameOverview } from 'services/games';

// Constants
import { BLUE_MAIN, RED_MAIN } from 'constants/color';
import { GAME_MENU } from 'constants/menu';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Grid, Box } from '@mui/material';
import GameCard from 'components/organisms/GameCard/GameCard';
import GameDraft from 'components/organisms/GameDraft/GameDraft';
import GameTeamStatistics from 'components/atoms/GameTeamStatistics/GameTeamStatistics';
import ChartArea from 'components/organisms/ChartArea/ChartArea';
import GameDmgStatistics from 'components/organisms/GameDmgStatistics/GameDmgStatistics';

const GameSingleView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { gameId } = useParams();
  const { pathname } = useLocation();
  const [game, setGame] = useState(null);
  const [gameMostDmg, setGameMostDmg] = useState(null);
  const [menu, setMenu] = useState([]);
  const [menu2, setMenu2] = useState([]);
  const [title, setTitle] = useState('');

  const fetchInfo = (gameIdValue) => {
    setIsLoading(true);

    fetchSingleGameOverview(gameIdValue).then((res) => {
      setGame(res.data);

      const newMenu = [];

      res.games.forEach((item, index) => {
        newMenu.push({
          id: index,
          title: `Game ${index + 1}`,
          link: pathname.replace(gameId, item),
        });
      });

      setMenu(newMenu);
      setMenu2(GAME_MENU);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (gameId) {
      fetchInfo(gameId);
    }
  }, [gameId]);

  useEffect(() => {
    if (game) {
      setTitle(`${game.name}`);

      let mostGameDmg = 0;

      Object.keys(game.dmg).forEach((side) => {
        game.dmg[side].forEach(({ value }) => {
          if (value > mostGameDmg) {
            mostGameDmg = value;
          }
        });
      });

      setGameMostDmg(mostGameDmg);
    }
  }, [game]);

  useEffect(() => {
    setTitleSEO(`${title} Overview`);
  }, [title]);

  return (
    <ViewWithSidebar
      menu={menu}
      menu2={menu2}
      component={
        !game || Object.keys(game).length === 0 ? null : (
          <>
            <Box mb={4}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item sm={7} md={5} lg sx={{ order: { xs: 1, lg: 'inherit' } }}>
                  <GameDraft picks={game?.draft?.blue?.picks} bans={game?.draft?.blue?.bans} />
                </Grid>
                <Grid item sm={7} md={10} lg={3} xl={2}>
                  <GameCard
                    data={{
                      date: game?.date,
                      patch: game?.patch,
                      index: game?.index,
                      blue_team: game?.blue_team,
                      red_team: game?.red_team,
                      winner_side: game?.winner_side,
                    }}
                  />
                </Grid>
                <Grid item sm={7} md={5} lg sx={{ order: { xs: 2, lg: 'inherit' } }}>
                  <GameDraft
                    picks={game?.draft?.red?.picks}
                    bans={game?.draft?.red?.bans}
                    side="red"
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container alignItems="center" justifyContent="space-around" spacing={4}>
              <Grid item xs={12} lg={4}>
                <GameTeamStatistics blue={game?.statistics?.blue} red={game?.statistics?.red} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Box>
                  <ChartArea data={game?.gold} chartKey="value" timeline={game?.timeline} />
                </Box>
                <Box mt={1}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <GameDmgStatistics
                        data={game?.dmg?.blue}
                        color={BLUE_MAIN}
                        highValue={gameMostDmg}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <GameDmgStatistics
                        data={game?.dmg?.red}
                        color={RED_MAIN}
                        highValue={gameMostDmg}
                        inverse
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        )
      }
    />
  );
};

export default GameSingleView;
