// React
import React, { useContext, useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Routes
import { homeURL, editorDraftBasicURL, settingsBasicURL } from 'routes/app';
import { adminHomeURL } from 'routes/admin';

// Constants
import { TABLE_BACKGROUND_A50 } from 'constants/color';
import { USER_MENU, ADMIN_MENU } from 'constants/menu';

// Assets
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';

// Elements
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from '@mui/material';
import MenuItem from 'components/atoms/MenuItem/MenuItem';

// Component
const Menu = ({ isAdmin }) => {
  const { user } = useContext(Context);
  const [currentMenu, setCurrentMenu] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState({});

  useEffect(() => {
    const teamAccess = user?.teams_access?.length > 0;

    setCurrentMenu(isAdmin ? ADMIN_MENU : USER_MENU(teamAccess));
  }, [user, isAdmin]);

  useEffect(() => {
    const data = currentMenu.reduce((acc, _, index) => {
      acc[index] = false;
      return acc;
    }, {});

    setOpen(data);
  }, [currentMenu]);

  const toggleDrawer = (value) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDrawer(value);
  };

  const handleClick = (index) => {
    setOpen((prevValue) => ({ ...prevValue, [index]: !open[index] }));
  };

  const closeMenu = () => {
    setOpenDrawer(false);
    setOpen(false);
  };

  return (
    <>
      <IconButton edge="start" aria-label="Menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
        <Box role="presentation" sx={{ width: 250 }}>
          <List component="nav">
            {isAdmin ? (
              <>
                <MenuItem
                  text="Back to app"
                  link={homeURL}
                  icon={<ArrowBackIosNewIcon />}
                  closeFn={closeMenu}
                />
                <Divider />
              </>
            ) : null}
            {currentMenu.map(({ id, title, icon, link, items }, index) => {
              if (link) {
                return (
                  <MenuItem
                    key={id}
                    text={title}
                    link={link}
                    icon={icon}
                    closeFn={toggleDrawer(false)}
                  />
                );
              }

              return (
                <React.Fragment key={id}>
                  <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} />
                    {open[index] ? (
                      <ExpandLess fontSize="small" />
                    ) : (
                      <ExpandMore fontSize="small" />
                    )}
                  </ListItemButton>
                  <Collapse in={open[index]} timeout="auto" unmountOnExit>
                    <List disablePadding sx={{ background: TABLE_BACKGROUND_A50 }}>
                      {items
                        ? items.map(({ id: itemId, title: itemTitle, link: itemLink }) => (
                            <MenuItem
                              key={itemId}
                              text={itemTitle}
                              link={itemLink}
                              closeFn={closeMenu}
                              inset
                            />
                          ))
                        : null}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
            {!isAdmin ? (
              <>
                <Divider />
                <MenuItem
                  text="Settings"
                  link={settingsBasicURL}
                  icon={<SettingsIcon />}
                  closeFn={closeMenu}
                />
              </>
            ) : null}
            {!isAdmin && user.is_editor ? (
              <>
                <Divider />
                <MenuItem
                  text="Draft Editor"
                  link={editorDraftBasicURL}
                  icon={<EditNoteIcon />}
                  closeFn={closeMenu}
                />
              </>
            ) : null}
            {!isAdmin && user.is_admin ? (
              <>
                <Divider />
                <MenuItem
                  text="Admin dashboard"
                  link={adminHomeURL}
                  icon={<AdminPanelSettingsIcon />}
                  closeFn={closeMenu}
                />
              </>
            ) : null}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

Menu.propTypes = {
  isAdmin: PropTypes.bool,
};

Menu.defaultProps = {
  isAdmin: false,
};

export default Menu;
