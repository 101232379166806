// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Dayjs
import dayjs from 'dayjs';

// Services
import { fetchSingleSplitBySlugs, fetchSplitWinRate } from 'services/splits';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import ChartScatter from 'components/molecules/ChartScatter/ChartScatter';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const SplitSingleWinRateView = () => {
  const {
    setTitleSEO,
    filters: {
      split: { id, rangeDate, patches },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { leagueSlug, splitSlug } = useParams();
  const [split, setSplit] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (leagueValue, splitValue) => {
    setIsLoading(true);

    fetchSingleSplitBySlugs(leagueValue, splitValue).then((res) => setSplit(res));
  };

  const fetchData = (splitId, startDateData, endDateData) => {
    setIsLoading(true);

    fetchSplitWinRate(splitId, startDateData, endDateData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(leagueSlug, splitSlug);
    }

    renderAfterCalled.current = true;
  }, [leagueSlug, splitSlug]);

  useEffect(() => {
    if (split) {
      fetchData(split.id, rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate, split]);

  useEffect(() => {
    if (split) {
      const tempTitle = `${split.league_name} ${split.name} Win Rate`;
      const startDate = split?.start_date ? dayjs(split?.start_date) : null;
      const endDate = split?.end_date ? dayjs(split?.end_date) : null;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);

      if (id !== split.id || (rangeDate[0] === null && rangeDate[1] === null)) {
        setFilters((prevValue) => ({
          ...prevValue,
          split: {
            ...prevValue.split,
            id: split.id,
            rangeDate: [startDate, endDate],
          },
        }));
      }
    }
  }, [split]);

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters rangeDateFilter patchesFilter={patches} type="split" />
          <ChartScatter data={data} />
        </>
      }
    />
  );
};

export default SplitSingleWinRateView;
