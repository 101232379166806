// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { YELLOW_MAIN } from 'constants/color';

// Utils
import { replaceWithBr } from 'utils/text';

// Assets
import TimerIcon from '@mui/icons-material/Timer';

// Elements
import { Box, Typography } from '@mui/material';

// Component
const BasicData = ({ data, type }) => (
  <Box p={3}>
    <Box display="flex" alignItems="center">
      <Typography variant="h5" textTransform="uppercase">
        {type === 'T' ? data?.topic : data?.name}
      </Typography>
      <Box display="flex" alignItems="center" ml={2}>
        <TimerIcon fontSize="small" sx={{ color: YELLOW_MAIN }} />
        <Typography variant="body1" ml={1 / 2}>
          {data?.duration}
        </Typography>
      </Box>
    </Box>
    <Box mt={3}>
      <Typography variant="h6">Notes:</Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: replaceWithBr(data?.notes) }}
      />
    </Box>
  </Box>
);

BasicData.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BasicData;
