// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const TeamData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue() || getValue().length === 0) {
    return null;
  }

  return (
    <Box display="flex" gap={0.25}>
      {getValue().map(({ role, champion: { id, name, image_url } }) => (
        <Box
          key={id}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ImageItem src={image_url} alt={name} />
          <Typography variant="caption" component="span" mt={0.5}>
            {role}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

TeamData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default TeamData;
