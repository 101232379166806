// React
import React, { useState, useRef } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Button } from '@mui/material';

// Component
const FileUpload = ({ id, label, onChange }) => {
  const [selectedFileName, setSelectedFileName] = useState(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();

    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setSelectedFileName(file ? file.name : null);
    onChange(id, file);
  };

  return (
    <Box display="flex" alignItems="center">
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button variant="outlined" color="primary" onClick={handleButtonClick}>
        {selectedFileName || label}
      </Button>
    </Box>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FileUpload;
