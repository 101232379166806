// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Router
import { useParams } from 'react-router-dom';

// Yup
import * as Yup from 'yup';

// Routes
import { adminPlayerUrnsURL } from 'routes/admin';

// Services
import { fetchPlayers } from 'services/players';
import { fetchSinglePlayerUrn, updatePlayerUrn } from 'services/playerUrns';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminPlayerUrnsEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSinglePlayerUrn(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });

    fetchPlayers().then((res) => setOptions(res));
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.urn} - Player URNs ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    urn: data?.urn || '',
    player: (options.length > 0 && data?.player) || null,
    start_date: data?.start_date || '',
    end_date: data?.end_date || '',
  };
  const validationSchema = Yup.object().shape({
    urn: Yup.string().required('Required'),
    player: Yup.object().required('Required'),
    start_date: Yup.string(),
    end_date: Yup.string(),
  });
  const fields1 = [
    {
      id: 'urn',
      label: 'Urn',
      typeField: 'textfield',
    },
    {
      id: 'player',
      label: 'Player',
      typeField: 'autocomplete',
      options,
    },
    {
      id: 'start_date',
      label: 'Start',
      typeField: 'textfield',
    },
    {
      id: 'end_date',
      label: 'End',
      typeField: 'textfield',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFn={updatePlayerUrn}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminPlayerUrnsURL}
      formikBtnText="Update player urn"
    />
  );
};

export default AdminPlayerUrnsEditView;
