// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Typography } from '@mui/material';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';

// Component
const WinRateText = ({ data }) => (
  <Typography variant="body2" component="p" textAlign="center">
    {data?.wins}W - {data?.losses}L (<GamePercentageData value={data?.value} />)
  </Typography>
);

WinRateText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WinRateText;
