// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Grid } from '@mui/material';
import Sidebar from 'components/molecules/Sidebar/Sidebar';

// Component
const ViewWithSidebar = ({ component, menu, menu2 }) => (
  <Grid container spacing={4} sx={{ minHeight: { lg: 'calc(100vh - 64px)' } }}>
    <Grid item xs={12} lg={2} xl={1.5}>
      <Sidebar list={menu} list2={menu2} />
    </Grid>
    <Grid item xs={12} lg={10} xl={10.5}>
      {component}
    </Grid>
  </Grid>
);

ViewWithSidebar.propTypes = {
  component: PropTypes.node,
  menu: PropTypes.array,
  menu2: PropTypes.array,
};

ViewWithSidebar.defaultProps = {
  component: null,
  menu: null,
  menu2: null,
};

export default ViewWithSidebar;
