// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchUnknownUrns = () =>
  axiosLoadingInstance
    .get('unknown-urns/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleUnknownUrn = (id) =>
  axiosLoadingInstance
    .get(`unknown-urns/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const assignUnknownUrnsToPlayersInDatabase = (data) =>
  axiosInstance
    .post('unknown-urns/assign-to-players-in-database/', data)
    .then((res) => ({
      data: res.data,
      message: 'Unknown urns have been assigned to players!',
      error: 0,
    }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const assignUnknownUrnToPlayer = (data) =>
  axiosInstance
    .post('unknown-urns/assign/', data)
    .then((res) => ({ data: res.data, message: 'Unknown urn has been assigned to player!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteUnknownUrn = (id) =>
  axiosInstance
    .delete(`unknown-urns/${id}/`)
    .then((res) => ({ data: res.data, message: 'Unknown urn has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
