// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Routes
import { playersSingleBasicURL } from 'routes/app';

// Services
import { fetchPlayersVisible } from 'services/players';

// Utils
import { getSelectOptions } from 'utils/table';

// Constants
import { PLAYERS_COLS } from 'constants/tableHead';

// Elements
import ItemsListTemplate from 'components/molecules/ItemsListTemplate/ItemsListTemplate';

const PlayersView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Players';

  const fetchData = () => {
    setIsLoading(true);

    fetchPlayersVisible().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const columns = useMemo(() => getSelectOptions(PLAYERS_COLS, data), [data]);

  return (
    <ItemsListTemplate
      title={title}
      data={data}
      columns={columns}
      rowActions={{ baseLink: playersSingleBasicURL, pathVariables: ['slug'] }}
      options={{ filter: true }}
    />
  );
};

export default PlayersView;
