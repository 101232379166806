// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchLeagues = () =>
  axiosLoadingInstance
    .get('leagues/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleLeague = (id) =>
  axiosLoadingInstance
    .get(`leagues/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addLeague = (data) =>
  axiosInstance
    .post('leagues/add/', data)
    .then((res) => ({ data: res.data, message: 'League has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateLeague = (id, data) =>
  axiosInstance
    .put(`leagues/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'League has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteLeague = (id) =>
  axiosInstance
    .delete(`leagues/${id}/`)
    .then((res) => ({ data: res.data, message: 'League has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
