// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchChampionPoolLeaguepedia } from 'services/dataLeaguepedia';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import TeamChampionPool from 'components/organisms/TeamChampionPool/TeamChampionPool';
import DataFilters from 'components/organisms/DataFilters/DataFilters';

const TeamSingleChampionPoolLeaguepediaView = () => {
  const {
    setTitleSEO,
    filters: {
      team: { rangeDate },
    },
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (teamId, startDateData, endDateData) => {
    setIsLoading(true);

    fetchChampionPoolLeaguepedia(teamId, startDateData, endDateData).then((res) => {
      setData(res.data);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(team.id, rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate, team]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Champion Pool`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters rangeDateFilter type="team" />
          <TeamChampionPool data={data} />
        </>
      }
    />
  );
};

export default TeamSingleChampionPoolLeaguepediaView;
