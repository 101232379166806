// Prop Types
import PropTypes from 'prop-types';

// Component
const ListData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue() || getValue().length === 0) {
    return null;
  }

  return getValue()
    .map(({ name }) => name)
    .join(', ');
};

ListData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default ListData;
