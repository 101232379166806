export const getSelectOptions = (cols, data) =>
  cols.map((el) => {
    const newEl = { ...el };

    if (
      el.filterVariant === 'select' ||
      el.filterVariant === 'multi-select' ||
      el.filterVariant === 'autocomplete'
    ) {
      let filterList = [...new Set(data.map((item) => item[el.accessorKey]))];

      filterList = [
        ...new Set(
          filterList
            .map((item) => {
              const singleItem = item;
              const splitItem = singleItem.split(',');

              return splitItem.map((it) => it.trim());
            })
            .flat(),
        ),
      ];

      filterList.sort();

      if (el.filterVariant === 'text') {
        newEl.filterFn = 'includesString';
      }

      newEl.filterSelectOptions = filterList;
    }

    return newEl;
  });
