// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Recharts
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  Tooltip,
  PolarAngleAxis,
  ResponsiveContainer,
} from 'recharts';

// Theme
import { compareScale } from 'theme/theme';

// Elements
import ChartTooltip from 'components/molecules/ChartTooltip/ChartTooltip';

// Component
const ChartRadar = ({ data }) => {
  const players = Object.keys(data[0]).filter((key) => key !== 'name');

  const renderRadars = players.map((player, index) => (
    <Radar
      key={player}
      name={player}
      dataKey={(elData) => elData[player].value}
      stroke={compareScale[index]}
      fill={compareScale[index]}
      fillOpacity={0.6}
    />
  ));

  return (
    <ResponsiveContainer width="100%" aspect={1.25}>
      <RadarChart
        cx="50%"
        cy="50%"
        data={data}
        margin={{ top: 25, right: 25, bottom: 25, left: 25 }}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        {renderRadars}
        <Legend />
        <Tooltip content={<ChartTooltip radar />} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

ChartRadar.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ChartRadar;
