// Theme
import { palette } from 'theme/theme';

export const {
  black: { main: BLACK_MAIN, a50: BLACK_A50, a10: BLACK_A10 },
  white: { main: WHITE_MAIN, a50: WHITE_A50, a10: WHITE_A10 },
  blue: { main: BLUE_MAIN, a50: BLUE_A50, a10: BLUE_A10 },
  red: { main: RED_MAIN, a50: RED_A50, a10: RED_A10 },
  green: { main: GREEN_MAIN, a50: GREEN_A50, a10: GREEN_A10 },
  yellow: { main: YELLOW_MAIN, a50: YELLOW_A50, a10: YELLOW_A10 },
  gray: { main: GRAY_MAIN, a50: GRAY_A50, a10: GRAY_A10 },
  top: { main: TOP_MAIN, a50: TOP_A50, a10: TOP_A10 },
  jng: { main: JNG_MAIN, a50: JNG_A50, a10: JNG_A10 },
  mid: { main: MID_MAIN, a50: MID_A50, a10: MID_A10 },
  bot: { main: BOT_MAIN, a50: BOT_A50, a10: BOT_A10 },
  sup: { main: SUP_MAIN, a50: SUP_A50, a10: SUP_A10 },
  tableBackground: { main: TABLE_BACKGROUND_MAIN, a50: TABLE_BACKGROUND_A50 },
} = palette;
