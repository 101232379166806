// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, RED_MAIN, WHITE_MAIN, YELLOW_MAIN } from 'constants/color';

// Elements
import { Typography } from '@mui/material';

// Component
const GameDiffData = ({ cell, value, small, center, fixNumbers, postFix }) => {
  let color = WHITE_MAIN;
  let valueData = null;

  if (cell !== null) {
    valueData = cell.getValue();
  } else if (value !== null) {
    valueData = value;
  }

  if (valueData !== null) {
    if (typeof valueData === 'number') {
      if (valueData > 0) {
        valueData = `+${fixNumbers ? valueData.toFixed(2) : valueData}`;
        color = GREEN_MAIN;
      } else if (valueData < 0) {
        valueData = fixNumbers ? valueData.toFixed(2) : valueData;
        color = RED_MAIN;
      }
    } else if (typeof valueData === 'string') {
      if (valueData === 'ENEMY') {
        color = YELLOW_MAIN;
      }
    }
  }

  if (valueData === null || valueData === undefined || valueData === '') {
    return null;
  }

  return (
    <Typography
      variant="body2"
      component="p"
      color={color}
      sx={{ fontSize: small && 12 }}
      textAlign={center ? 'center' : 'left'}
    >
      {valueData}
      {postFix}
    </Typography>
  );
};

GameDiffData.propTypes = {
  cell: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  center: PropTypes.bool,
  fixNumbers: PropTypes.bool,
  postFix: PropTypes.string,
};

GameDiffData.defaultProps = {
  cell: null,
  value: null,
  small: false,
  center: false,
  fixNumbers: false,
  postFix: '',
};

export default GameDiffData;
