// Router
import { NavLink } from 'react-router-dom';

// Styles
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
  && {
    color: inherit;
    text-decoration: none;
  }
`;
