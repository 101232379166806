// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchGameErrors = () =>
  axiosLoadingInstance
    .get('game-errors/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteGameErrorScrim = () =>
  axiosInstance
    .delete(`game-errors/repairable/SCRIM/`)
    .then((res) => ({ data: res.data, message: 'Game errors have been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteGameErrorCompetitive = () =>
  axiosInstance
    .delete(`game-errors/repairable/COMPETITIVE/`)
    .then((res) => ({ data: res.data, message: 'Game errors have been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteGameError = (id) =>
  axiosInstance
    .delete(`game-errors/${id}/`)
    .then((res) => ({ data: res.data, message: 'Game error has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
