// React
import React from 'react';

// Elements
import { Box, Typography } from '@mui/material';

// Component
const BiggerScreen = () => (
  <Box my={4}>
    <Typography variant="h6" component="p" textAlign="center">
      Check data on bigger screen
    </Typography>
  </Box>
);

export default BiggerScreen;
