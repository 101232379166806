// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleSplitBySlugs, fetchSplitLPGain } from 'services/splits';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { ROW_NUMBERS, HIDE_PAGINATION } from 'constants/mrt';
import { SPLIT_PLAYERS_LP_GAIN_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';

const SplitSingleLpGainView = () => {
  const {
    setTitleSEO,
    filters: {
      split: { id, soloQRangeDate, roleAll },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { leagueSlug, splitSlug } = useParams();
  const [split, setSplit] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (leagueValue, splitValue) => {
    setIsLoading(true);

    fetchSingleSplitBySlugs(leagueValue, splitValue).then((res) => {
      setSplit(res);
    });
  };

  const fetchData = (splitId, roleData, startDateData, endDateData) => {
    setIsLoading(true);

    fetchSplitLPGain(splitId, roleData, startDateData, endDateData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(leagueSlug, splitSlug);
    }

    renderAfterCalled.current = true;
  }, [leagueSlug, splitSlug]);

  useEffect(() => {
    if (split) {
      const tempTitle = `${split.league_name} ${split.name} LP Gain`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);

      if (id !== split.id) {
        setFilters((prevValue) => ({
          ...prevValue,
          split: {
            ...prevValue.split,
            id: split.id,
          },
        }));
      }
    }
  }, [split]);

  useEffect(() => {
    if (split) {
      fetchData(split.id, roleAll, soloQRangeDate[0], soloQRangeDate[1]);
    }
  }, [soloQRangeDate, roleAll, split]);

  const columns = useMemo(() => SPLIT_PLAYERS_LP_GAIN_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...ROW_NUMBERS,
    ...HIDE_PAGINATION,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters soloQRangeDateFilter roleAllFilter type="split" />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default SplitSingleLpGainView;
