// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Box, Typography, Card, CardContent } from '@mui/material';
import TeamCard from 'components/molecules/TeamCard/TeamCard';

// Component
const GameCard = ({ data }) => {
  const logoSize = 56;
  const fontSize = 12;
  const {
    blue_team: blueTeam,
    red_team: redTeam,
    winner_side: winnserSide,
    index,
    date,
    patch,
  } = data;
  const isBlueWin = winnserSide === 'BLUE';

  if (!blueTeam || !redTeam) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2" component="p" textAlign="center" fontSize={fontSize}>
            Game {index}
          </Typography>
          <Typography variant="body2" component="p" textAlign="center">
            {date}
          </Typography>
          <Typography variant="body2" component="p" textAlign="center" fontSize={fontSize}>
            Patch {patch}
          </Typography>
          <Box display="flex" mt={1}>
            <TeamCard team={blueTeam} isWin={isBlueWin} logoSize={logoSize} />
            <Typography variant="h6" component="p" mx={2} mt={2}>
              VS.
            </Typography>
            <TeamCard team={redTeam} isWin={!isBlueWin} logoSize={logoSize} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

GameCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GameCard;
