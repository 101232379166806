// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Constants
import { GREEN_A10, RED_A10 } from 'constants/color';

// Elements
import { Box, Typography } from '@mui/material';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const ReasonData = ({ reason, data }) => {
  const summonerSpellImageSize = 24;
  const summonerSpellFontSize = 11;
  const components = [];

  if (reason === 'LOCAL GOLD') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p" mr={1 / 2}>
          G:
        </Typography>
        <GameDiffData value={data.local_gold} />
      </Box>,
    );
  }

  if (reason === 'BOUNTY') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p" mr={1 / 2}>
          B:
        </Typography>
        <Typography variant="body2" component="p">
          {data.bounty}
        </Typography>
      </Box>,
    );
  }

  if (reason === 'NON SPENT GOLD') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p" mr={1 / 2}>
          NSG:
        </Typography>
        <Typography variant="body2" component="p">
          {data.non_spent_gold}
        </Typography>
      </Box>,
    );
  }

  if (reason === 'CW IN INVENTORY') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p" mr={1 / 2}>
          CW:
        </Typography>
        <Typography variant="body2" component="p">
          {data.cw_in_inventory}
        </Typography>
      </Box>,
    );
  }

  if (reason === 'NO USED WARDS') {
    components.push(
      <Box key={reason}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography variant="body2" component="p" mr={1 / 2}>
            LNW:
          </Typography>
          <Typography variant="body2" component="p">
            {data.last_nw_time ? secondsToHms(data.last_nw_time) : 'N/A'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography variant="body2" component="p" mr={1 / 2}>
            LCW:
          </Typography>
          <Typography variant="body2" component="p">
            {data.last_cw_time ? secondsToHms(data.last_cw_time) : 'N/A'}
          </Typography>
        </Box>
      </Box>,
    );
  }

  if (reason === 'NUMBERS ADVANTAGE' || reason === 'NUMBERS DISADVANTAGE') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p">
          {data.team_count} vs. {data.enemy_count}
        </Typography>
      </Box>,
    );
  }

  if (reason === 'GOLD ADVANTAGE' || reason === 'GOLD DISADVANTAGE') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <GameDiffData value={data.team_gold - data.enemy_gold} />
      </Box>,
    );
  }

  if (reason === 'UNSPENT GOLD' || reason === 'ENEMY UNSPENT GOLD') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <GameDiffData value={data.team_unspent_gold - data.enemy_unspent_gold} />
      </Box>,
    );
  }

  if (reason === 'MORE DEATHS' || reason === 'LESS DEATHS') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p">
          {data.team_died} - {data.enemy_died}
        </Typography>
      </Box>,
    );
  }

  if (reason === 'ITEMS DIFF') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p">
          {data.team_items} - {data.enemy_items}
        </Typography>
      </Box>,
    );
  }

  if (reason === 'VISION DIFF') {
    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="p">
          {data.team_vision} - {data.enemy_vision}
        </Typography>
      </Box>,
    );
  }

  if (reason === 'IMPORTANT CDs') {
    const teamCDs = data?.team_cds?.data;
    const enemyCDs = data?.enemy_cds?.data;

    components.push(
      <Box key={reason} display="flex" justifyContent="flex-end" alignItems="center">
        {Object.keys(teamCDs).length > 0 ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            bgcolor={GREEN_A10}
            p={1 / 4}
          >
            {Object.keys(teamCDs).map((el) =>
              teamCDs[el].map((single) => (
                <Box key={single.name} display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="body2" component="p" fontSize={summonerSpellFontSize}>
                    {single.cd}
                  </Typography>
                  <ImageItem
                    src={single.image}
                    alt={single.name}
                    width={summonerSpellImageSize}
                    height={summonerSpellImageSize}
                  />
                  <Typography variant="body2" component="p" fontSize={summonerSpellFontSize}>
                    {el[0]}
                  </Typography>
                </Box>
              )),
            )}
          </Box>
        ) : null}
        {Object.keys(enemyCDs).length > 0 ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            bgcolor={RED_A10}
            p={1 / 4}
          >
            {Object.keys(enemyCDs).map((el) =>
              enemyCDs[el].map((single) => (
                <Box key={single.name} display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="body2" component="p" fontSize={summonerSpellFontSize}>
                    {single.cd}
                  </Typography>
                  <ImageItem
                    src={single.image}
                    alt={single.name}
                    width={summonerSpellImageSize}
                    height={summonerSpellImageSize}
                  />
                  <Typography variant="body2" component="p" fontSize={summonerSpellFontSize}>
                    {el[0]}
                  </Typography>
                </Box>
              )),
            )}
          </Box>
        ) : null}
      </Box>,
    );
  }

  return <Box>{components.map((item) => item)}</Box>;
};

ReasonData.propTypes = {
  reason: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default ReasonData;
