// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { BLACK_MAIN, BLUE_MAIN, RED_MAIN } from 'constants/color';

// Elements
import { Typography, Box, useMediaQuery } from '@mui/material';
import DraftPick from 'components/molecules/DraftPick/DraftPick';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const GameDraft = ({ picks, bans, side }) => {
  const isDesktopViewport = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isBlueSide = side === 'blue';

  const renderPicks = () => (
    <Box display="flex" alignItems="center" gap={1}>
      {picks.map((item) => (
        <DraftPick key={item.id} data={item} horizontal />
      ))}
    </Box>
  );

  const renderBans = () => (
    <Box display="flex" alignItems="center" gap={1} mt={2}>
      {bans.map((item) => (
        <ImageItem
          key={`${item.id}${item.order}`}
          src={item.image_url}
          alt={item.name}
          width={40}
          height={40}
          ban
          noborder
        />
      ))}
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={{ xs: 'center', lg: isBlueSide ? 'flex-end' : 'flex-start' }}
      ml={{ lg: side !== 'blue' ? 3 : 0 }}
      mr={{ lg: isBlueSide ? 3 : 0 }}
    >
      {!isDesktopViewport ? (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={isBlueSide ? BLUE_MAIN : RED_MAIN}
          color={BLACK_MAIN}
          mb={1}
        >
          <Typography variant="body2" component="p" fontWeight="bold" textAlign="center">
            {side.toUpperCase()}
          </Typography>
        </Box>
      ) : null}
      {renderPicks()}
      {renderBans()}
    </Box>
  );
};

GameDraft.propTypes = {
  picks: PropTypes.array,
  bans: PropTypes.array,
  side: PropTypes.string,
};

GameDraft.defaultProps = {
  picks: [],
  bans: [],
  side: 'blue',
};

export default GameDraft;
