// Styles
import styled from 'styled-components';

// Elements
import { Typography } from '@mui/material';

export const StyledMap = styled.div`
  && {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    background: url(${({ image }) => image}) center/cover no-repeat;
    border: 2px solid
      ${({ theme, side }) =>
        side ? theme.palette[side.toLowerCase()].main : theme.palette.black.main};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }

    canvas {
      width: ${({ heatmap }) => heatmap && '100%'};
      height: ${({ heatmap }) => heatmap && '100%'};
    }
  }
`;

export const StyledCanvas = styled.canvas`
  && {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const StyledCount = styled(Typography)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.palette.black.main};
    background: ${({ theme }) => theme.palette.white.main};
    z-index: 1;
  }
`;
