// React
import React, { useContext } from 'react';

// Router
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

// Context
import { Context } from 'context';

// Helmet
import { Helmet } from 'react-helmet-async';

// Routes
import {
  homeURL,
  scrimsMatchesURL,
  scrimsDraftsURL,
  scrimsChampionPoolURL,
  scrimsPerformanceURL,
  scrimsBasicURL,
  dailyEditURL,
  dailyAddURL,
  dailyReportBasicURL,
  soloQLpByTeamReportBasicURL,
  soloQMatchesReportBasicURL,
  soloQChampionPoolReportBasicURL,
  soloQHoursReportBasicURL,
  soloQCountReportBasicURL,
  splitsSingleMatchesURL,
  splitsSingleSynergiesURL,
  splitsSingleChampionPoolURL,
  splitsSinglePicksURL,
  splitsSingleTeamsStatisticsURL,
  splitsSinglePlayersStatisticsURL,
  splitsSingleWinRateURL,
  splitsSingleSoloQURL,
  splitsSingleLpGainURL,
  splitsSingleURL,
  splitsBasicURL,
  teamsSingleMatchesURL,
  teamsSingleDraftsPatternsLeaguepediaURL,
  teamsSingleDraftsPatternsURL,
  teamsSingleDraftsLeagupediaURL,
  teamsSingleDraftsURL,
  teamsSingleChampionPoolLeaguepediaURL,
  teamsSingleChampionPoolURL,
  teamsSingleFlexLeaguepediaURL,
  teamsSingleFlexURL,
  teamsSingleSynergiesLeaguepediaURL,
  teamsSingleSynergiesURL,
  teamsSinglePicksLeaguepediaURL,
  teamsSinglePicksURL,
  teamsSingleEnemyDataLeaguepediaURL,
  teamsSingleEnemyDataURL,
  teamsSingleLvL1WardsURL,
  teamsSingleLvL1PositionsURL,
  teamsSingleJungleStatisticsURL,
  teamsSingleJungleURL,
  teamsSingleShopURL,
  teamsSingleWardsStatisticsURL,
  teamsSingleWardsObjectivesURL,
  teamsSingleWardsSurvivedURL,
  teamsSingleWardsTimelineURL,
  teamsSingleWardsURL,
  teamsSingleOracleLensURL,
  teamsSingleHeatmapURL,
  teamsSinglePositionsOnMapURL,
  teamsSingleObjectivesURL,
  teamsSingleSummonersURL,
  teamsSingleRecallsURL,
  teamsSingleGraphsURL,
  teamsSingleWinRateURL,
  teamsSinglePerformanceURL,
  teamsSingleScoresURL,
  teamsSingleHoursURL,
  teamsSingleURL,
  teamsBasicURL,
  playersSingleChampionPoolURL,
  playersSingleStatisticsURL,
  playersSingleGraphsURL,
  playersSingleHoursURL,
  playersSingleURL,
  playersBasicURL,
  championsSingleURL,
  championsBasicURL,
  gamesSingleTeamFightsURL,
  gamesSinglePositionsOnMapURL,
  gamesSingleScoreboardURL,
  gamesSingleURL,
  searchVodBasicURL,
  searchCounterBasicURL,
  searchChampionsDataBasicURL,
  searchWorldsMatchupsBasicURL,
  comparePlayersBasicURL,
  replaysBasicURL,
  performanceEditURL,
  performanceAddURL,
  performanceReportBasicURL,
  performanceBasicURL,
  scoutingSoloqView,
  scoutingCompetitiveView,
  settingsBasicURL,
  editorDraftBasicURL,
  editorDraftEditURL,
  socialMediaH2HBasicURL,
  socialMediaBasicURL,
} from 'routes/app';
import {
  adminHomeURL,
  adminOptionsURL,
  adminOptionsAddURL,
  adminOptionsEditURL,
  adminUsersURL,
  adminUsersAddURL,
  adminUsersEditURL,
  adminUsersPasswordURL,
  adminTagsURL,
  adminTagsAddURL,
  adminTagsEditURL,
  adminChampionsURL,
  adminChampionsAddURL,
  adminChampionsEditURL,
  adminChampionSpellsURL,
  adminItemsURL,
  adminItemsAddURL,
  adminItemsEditURL,
  adminPatchesURL,
  adminPatchesAddURL,
  adminPatchesEditURL,
  adminSummonerSpellsURL,
  adminRunesURL,
  adminKeystonesURL,
  adminPerksURL,
  adminShardsURL,
  adminRegionsURL,
  adminRegionsAddURL,
  adminRegionsEditURL,
  adminServersURL,
  adminServersAddURL,
  adminServersEditURL,
  adminTiersURL,
  adminTiersAddURL,
  adminTiersEditURL,
  adminAccountsURL,
  adminAccountsAddURL,
  adminAccountsEditURL,
  adminRankedGamesURL,
  adminNationalitiesURL,
  adminNationalitiesAddURL,
  adminNationalitiesEditURL,
  adminLeaguesURL,
  adminLeaguesAddURL,
  adminLeaguesEditURL,
  adminSplitsURL,
  adminSplitsAddURL,
  adminSplitsEditURL,
  adminTeamsURL,
  adminTeamsAddURL,
  adminTeamsEditURL,
  adminPlayersURL,
  adminPlayersAddURL,
  adminPlayersEditURL,
  adminPlayerUrnsURL,
  adminPlayerUrnsAddURL,
  adminPlayerUrnsEditURL,
  adminMatchesURL,
  adminMatchesEditURL,
  adminGamesURL,
  adminGamesEditURL,
  adminUnknownUrnsURL,
  adminUnknownUrnsEditURL,
  adminGameErrorsURL,
} from 'routes/admin';

// Elements
import Loading from 'components/atoms/Loading/Loading';
import { Container } from '@mui/material';
import Header from 'components/organisms/Header/Header';

// Views
import HomeView from 'views/HomeView';
import LoginView from 'views/LoginView';
import ScrimsMatchesView from 'views/Scrims/ScrimsMatchesView';
import ScrimsDraftsView from 'views/Scrims/ScrimsDraftsView';
import ScrimsChampionPoolView from 'views/Scrims/ScrimsChampionPoolView';
import ScrimsPerformanceView from 'views/Scrims/ScrimsPerformanceView';
import DailyEditView from 'views/Daily/DailyEditView';
import DailyAddView from 'views/Daily/DailyAddView';
import DailyReportView from 'views/Daily/DailyReportView';
import SoloQLpByTeamReportView from 'views/SoloQ/SoloQLpByTeamReportView';
import SoloQMatchesReportView from 'views/SoloQ/SoloQMatchesReportView';
import SoloQChampionPoolReportView from 'views/SoloQ/SoloQChampionPoolReportView';
import SoloQHoursReportView from 'views/SoloQ/SoloQHoursReportView';
import SoloQCountReportView from 'views/SoloQ/SoloQCountReportView';
import ScrimsView from 'views/Scrims/ScrimsView';
import SplitSingleMatchesView from 'views/Splits/SplitSingleMatchesView';
import SplitSingleChampionPoolView from 'views/Splits/SplitSingleChampionPoolView';
import SplitSingleSynergiesView from 'views/Splits/SplitSingleSynergiesView';
import SplitSinglePicksView from 'views/Splits/SplitSinglePicksView';
import SplitSingleTeamsStatisticsView from 'views/Splits/SplitSingleTeamsStatisticsView';
import SplitSinglePlayersStatisticsView from 'views/Splits/SplitSinglePlayersStatisticsView';
import SplitSingleWinRateView from 'views/Splits/SplitSingleWinRateView';
import SplitSingleSoloQView from 'views/Splits/SplitSingleSoloQView';
import SplitSingleLpGainView from 'views/Splits/SplitSingleLpGainView';
import SplitSingleView from 'views/Splits/SplitSingleView';
import SplitsView from 'views/Splits/SplitsView';
import TeamSingleMatchesView from 'views/Teams/TeamSingleMatchesView';
import TeamSingleDraftsPatternsLeaguepediaView from 'views/Teams/Leaguepedia/TeamSingleDraftsPatternsLeaguepediaView';
import TeamSingleDraftsPatternsView from 'views/Teams/TeamSingleDraftsPatternsView';
import TeamSingleDraftsLeaguepediaView from 'views/Teams/Leaguepedia/TeamSingleDraftsLeaguepediaView';
import TeamSingleDraftsView from 'views/Teams/TeamSingleDraftsView';
import TeamSingleChampionPoolLeaguepediaView from 'views/Teams/Leaguepedia/TeamSingleChampionPoolLeaguepediaView';
import TeamSingleChampionPoolView from 'views/Teams/TeamSingleChampionPoolView';
import TeamSingleFlexLeaguepediaView from 'views/Teams/Leaguepedia/TeamSingleFlexLeaguepediaView';
import TeamSingleFlexView from 'views/Teams/TeamSingleFlexView';
import TeamSingleSynergiesLeaguepediaView from 'views/Teams/Leaguepedia/TeamSingleSynergiesLeaguepediaView';
import TeamSingleSynergiesView from 'views/Teams/TeamSingleSynergiesView';
import TeamSinglePicksLeaguepediaView from 'views/Teams/Leaguepedia/TeamSinglePicksLeaguepediaView';
import TeamSinglePicksView from 'views/Teams/TeamSinglePicksView';
import TeamSingleEnemyDataLeaguepediaView from 'views/Teams/Leaguepedia/TeamSingleEnemyDataLeaguepediaView';
import TeamSingleEnemyDataView from 'views/Teams/TeamSingleEnemyDataView';
import TeamSingleLvL1WardsView from 'views/Teams/TeamSingleLvL1WardsView';
import TeamSingleLvL1Positions from 'views/Teams/TeamSingleLvL1PositionsView';
import TeamSingleJungleStatisticsView from 'views/Teams/TeamSingleJungleStatisticsView';
import TeamSingleJungleView from 'views/Teams/TeamSingleJungleView';
import TeamSingleShopView from 'views/Teams/TeamSingleShopView';
import TeamSingleWardsStatisticsView from 'views/Teams/TeamSingleWardsStatisticsView';
import TeamSingleWardsObjectivesView from 'views/Teams/TeamSingleWardsObjectivesView';
import TeamSingleWardsSurvivedView from 'views/Teams/TeamSingleWardsSurvivedView';
import TeamSingleWardsTimelineView from 'views/Teams/TeamSingleWardsTimelineView';
import TeamSingleWardsView from 'views/Teams/TeamSingleWardsView';
import TeamSingleOracleLensView from 'views/Teams/TeamSingleOracleLensView';
import TeamSingleHeatmapView from 'views/Teams/TeamSingleHeatmapView';
import TeamSinglePositionsOnMapView from 'views/Teams/TeamSinglePositionsOnMapView';
import TeamSingleObjectivesView from 'views/Teams/TeamSingleObjectivesView';
import TeamSingleSummonersView from 'views/Teams/TeamSingleSummonersView';
import TeamSingleRecallsView from 'views/Teams/TeamSingleRecallsView';
import TeamSingleGraphsView from 'views/Teams/TeamSingleGraphsView';
import TeamSingleWinRateView from 'views/Teams/TeamSingleWinRateView';
import TeamSinglePerformanceView from 'views/Teams/TeamSinglePerformanceView';
import TeamSingleScoresView from 'views/Teams/TeamSingleScoresView';
import TeamSingleHoursView from 'views/Teams/TeamSingleHoursView';
import TeamSingleView from 'views/Teams/TeamSingleView';
import TeamsView from 'views/Teams/TeamsView';
import PlayerSingleChampionPoolView from 'views/Players/PlayerSingleChampionPoolView';
import PlayerSingleStatisticsView from 'views/Players/PlayerSingleStatisticsView';
import PlayerSingleGraphsView from 'views/Players/PlayerSingleGraphsView';
import PlayerSingleHoursView from 'views/Players/PlayerSingleHoursView';
import PlayerSingleView from 'views/Players/PlayerSingleView';
import PlayersView from 'views/Players/PlayersView';
import ChampionSingleView from 'views/Champions/ChampionSingleView';
import ChampionsView from 'views/Champions/ChampionsView';
import GameSingleTeamFightsView from 'views/Games/GameSingleTeamFightsView';
import GameSinglePositionsOnMapView from 'views/Games/GameSinglePositionsOnMapView';
import GameSingleScoreboardView from 'views/Games/GameSingleScoreboardView';
import GameSingleView from 'views/Games/GameSingleView';
import SearchVodView from 'views/Search/SearchVodView';
import SearchCounterView from 'views/Search/SearchCounterView';
import SearchChampionsDataView from 'views/Search/SearchChampionsDataView';
import SearchWorldsMatchupsView from 'views/Search/SearchWorldsMatchupsView';
import ComparePlayersView from 'views/Compare/ComparePlayersView';
import ReplaysView from 'views/Replays/ReplaysView';
import PerformanceEditView from 'views/Performance/PerformanceEditView';
import PerformanceAddView from 'views/Performance/PerformanceAddView';
import PerformanceReportView from 'views/Performance/PerformanceReportView';
import PerformanceView from 'views/Performance/PerformanceView';
import ScoutingSoloqView from 'views/Scouting/ScoutingSoloqView';
import ScoutingCompetitiveView from 'views/Scouting/ScoutingCompetitiveView';
import SettingsView from 'views/Settings/SettingsView';

// Views Editor
import EditorDraftEditView from 'views/Editor/EditorDraft/EditorDraftEditView';
import EditorDraftView from 'views/Editor/EditorDraft/EditorDraftView';

// Views Social Media
import SocialMediaH2HView from 'views/SocialMedia/SocialMediaH2HView';
import SocialMediaView from 'views/SocialMedia/SocialMediaView';

// Views Admin
import AdminHomeView from 'views/Admin/AdminHomeView';
import AdminOptionsView from 'views/Admin/Options/AdminOptionsView';
import AdminOptionsAddView from 'views/Admin/Options/AdminOptionsAddView';
import AdminOptionsEditView from 'views/Admin/Options/AdminOptionsEditView';
import AdminUsersView from 'views/Admin/Users/AdminUsersView';
import AdminUsersAddView from 'views/Admin/Users/AdminUsersAddView';
import AdminUsersEditView from 'views/Admin/Users/AdminUsersEditView';
import AdminUsersEditPasswordView from 'views/Admin/Users/AdminUsersEditPasswordView';
import AdminTagsView from 'views/Admin/Tags/AdminTagsView';
import AdminTagsAddView from 'views/Admin/Tags/AdminTagsAddView';
import AdminTagsEditView from 'views/Admin/Tags/AdminTagsEditView';
import AdminChampionsView from 'views/Admin/Champions/AdminChampionsView';
import AdminChampionsAddView from 'views/Admin/Champions/AdminChampionsAddView';
import AdminChampionsEditView from 'views/Admin/Champions/AdminChampionsEditView';
import AdminChampionSpellsView from 'views/Admin/ChampionSpells/AdminChampionSpellsView';
import AdminItemsView from 'views/Admin/Items/AdminItemsView';
import AdminItemsAddView from 'views/Admin/Items/AdminItemsAddView';
import AdminItemsEditView from 'views/Admin/Items/AdminItemsEditView';
import AdminPatchesView from 'views/Admin/Patches/AdminPatchesView';
import AdminPatchesAddView from 'views/Admin/Patches/AdminPatchesAddView';
import AdminPatchesEditView from 'views/Admin/Patches/AdminPatchesEditView';
import AdminSummonerSpellsView from 'views/Admin/SummonerSpells/AdminSummonerSpellsView';
import AdminRunesView from 'views/Admin/Runes/AdminRunesView';
import AdminKeystonesView from 'views/Admin/Keystones/AdminKeystonesView';
import AdminPerksView from 'views/Admin/Perks/AdminPerksView';
import AdminShardsView from 'views/Admin/Shards/AdminShardsView';
import AdminRegionsView from 'views/Admin/Regions/AdminRegionsView';
import AdminRegionsAddView from 'views/Admin/Regions/AdminRegionsAddView';
import AdminRegionsEditView from 'views/Admin/Regions/AdminRegionsEditView';
import AdminServersView from 'views/Admin/Servers/AdminServersView';
import AdminServersAddView from 'views/Admin/Servers/AdminServersAddView';
import AdminServersEditView from 'views/Admin/Servers/AdminServersEditView';
import AdminTiersView from 'views/Admin/Tiers/AdminTiersView';
import AdminTiersAddView from 'views/Admin/Tiers/AdminTiersAddView';
import AdminTiersEditView from 'views/Admin/Tiers/AdminTiersEditView';
import AdminAccountsView from 'views/Admin/Accounts/AdminAccountsView';
import AdminAccountsAddView from 'views/Admin/Accounts/AdminAccountsAddView';
import AdminAccountsEditView from 'views/Admin/Accounts/AdminAccountsEditView';
import AdminRankedGamesView from 'views/Admin/RankedGames/AdminRankedGamesView';
import AdminNationalitiesView from 'views/Admin/Nationalities/AdminNationalitiesView';
import AdminNationalitiesAddView from 'views/Admin/Nationalities/AdminNationalitiesAddView';
import AdminNationalitiesEditView from 'views/Admin/Nationalities/AdminNationalitiesEditView';
import AdminLeaguesView from 'views/Admin/Leagues/AdminLeaguesView';
import AdminLeaguesAddView from 'views/Admin/Leagues/AdminLeaguesAddView';
import AdminLeaguesEditView from 'views/Admin/Leagues/AdminLeaguesEditView';
import AdminSplitsView from 'views/Admin/Splits/AdminSplitsView';
import AdminSplitsAddView from 'views/Admin/Splits/AdminSplitsAddView';
import AdminSplitsEditView from 'views/Admin/Splits/AdminSplitsEditView';
import AdminTeamsView from 'views/Admin/Teams/AdminTeamsView';
import AdminTeamsAddView from 'views/Admin/Teams/AdminTeamsAddView';
import AdminTeamsEditView from 'views/Admin/Teams/AdminTeamsEditView';
import AdminPlayersView from 'views/Admin/Players/AdminPlayersView';
import AdminPlayersAddView from 'views/Admin/Players/AdminPlayersAddView';
import AdminPlayersEditView from 'views/Admin/Players/AdminPlayersEditView';
import AdminPlayerUrnsView from 'views/Admin/PlayerUrns/AdminPlayerUrnsView';
import AdminPlayerUrnsAddView from 'views/Admin/PlayerUrns/AdminPlayerUrnsAddView';
import AdminPlayerUrnsEditView from 'views/Admin/PlayerUrns/AdminPlayerUrnsEditView';
import AdminMatchesView from 'views/Admin/Matches/AdminMatchesView';
import AdminMatchesEditView from 'views/Admin/Matches/AdminMatchesEditView';
import AdminGamesView from 'views/Admin/Games/AdminGamesView';
import AdminGamesEditView from 'views/Admin/Games/AdminGamesEditView';
import AdminUnknownUrnsView from 'views/Admin/UnknownUrns/AdminUnknownUrnsView';
import AdminUnknownUrnsEditView from 'views/Admin/UnknownUrns/AdminUnknownUrnsEditView';
import AdminGameErrorsView from 'views/Admin/GameErrors/AdminGameErrorsView';

export const App = () => {
  const { token, titleSEO, isLoading, user } = useContext(Context);
  const { pathname } = useLocation();

  if (!token) {
    return <LoginView />;
  }

  if (pathname.startsWith('/admin') && Object.keys(user).length > 0 && !user.is_admin) {
    return <Navigate to={homeURL} replace />;
  }

  if (Object.keys(user).length > 0 && !user.is_editor) {
    if (pathname.startsWith('/editor') || pathname.startsWith('/performance/')) {
      return <Navigate to={homeURL} replace />;
    }
  }

  return (
    <>
      <Loading loading={+isLoading} />
      <Helmet>
        <title>{titleSEO}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap"
        />
      </Helmet>
      <Header />
      <Container style={{ paddingTop: 80, paddingBottom: 16 }}>
        <Routes>
          <Route exact path={homeURL} element={<HomeView />} />
          <Route path={scrimsMatchesURL} element={<ScrimsMatchesView />} />
          <Route path={scrimsDraftsURL} element={<ScrimsDraftsView />} />
          <Route path={scrimsChampionPoolURL} element={<ScrimsChampionPoolView />} />
          <Route path={scrimsPerformanceURL} element={<ScrimsPerformanceView />} />
          <Route path={scrimsBasicURL} element={<ScrimsView />} />
          <Route path={dailyEditURL} element={<DailyEditView />} />
          <Route path={dailyAddURL} element={<DailyAddView />} />
          <Route path={dailyReportBasicURL} element={<DailyReportView />} />
          <Route path={soloQLpByTeamReportBasicURL} element={<SoloQLpByTeamReportView />} />
          <Route path={soloQMatchesReportBasicURL} element={<SoloQMatchesReportView />} />
          <Route path={soloQChampionPoolReportBasicURL} element={<SoloQChampionPoolReportView />} />
          <Route path={soloQHoursReportBasicURL} element={<SoloQHoursReportView />} />
          <Route path={soloQCountReportBasicURL} element={<SoloQCountReportView />} />
          <Route path={splitsSingleMatchesURL} element={<SplitSingleMatchesView />} />
          <Route path={splitsSingleChampionPoolURL} element={<SplitSingleChampionPoolView />} />
          <Route path={splitsSingleSynergiesURL} element={<SplitSingleSynergiesView />} />
          <Route path={splitsSinglePicksURL} element={<SplitSinglePicksView />} />
          <Route
            path={splitsSingleTeamsStatisticsURL}
            element={<SplitSingleTeamsStatisticsView />}
          />
          <Route
            path={splitsSinglePlayersStatisticsURL}
            element={<SplitSinglePlayersStatisticsView />}
          />
          <Route path={splitsSingleWinRateURL} element={<SplitSingleWinRateView />} />
          <Route path={splitsSingleSoloQURL} element={<SplitSingleSoloQView />} />
          <Route path={splitsSingleLpGainURL} element={<SplitSingleLpGainView />} />
          <Route path={splitsSingleURL} element={<SplitSingleView />} />
          <Route path={splitsBasicURL} element={<SplitsView />} />
          <Route path={teamsSingleMatchesURL} element={<TeamSingleMatchesView />} />
          <Route
            path={teamsSingleDraftsPatternsLeaguepediaURL}
            element={<TeamSingleDraftsPatternsLeaguepediaView />}
          />
          <Route path={teamsSingleDraftsPatternsURL} element={<TeamSingleDraftsPatternsView />} />
          <Route
            path={teamsSingleDraftsLeagupediaURL}
            element={<TeamSingleDraftsLeaguepediaView />}
          />
          <Route path={teamsSingleDraftsURL} element={<TeamSingleDraftsView />} />
          <Route
            path={teamsSingleChampionPoolLeaguepediaURL}
            element={<TeamSingleChampionPoolLeaguepediaView />}
          />
          <Route path={teamsSingleChampionPoolURL} element={<TeamSingleChampionPoolView />} />
          <Route path={teamsSingleFlexLeaguepediaURL} element={<TeamSingleFlexLeaguepediaView />} />
          <Route path={teamsSingleFlexURL} element={<TeamSingleFlexView />} />
          <Route
            path={teamsSingleSynergiesLeaguepediaURL}
            element={<TeamSingleSynergiesLeaguepediaView />}
          />
          <Route path={teamsSingleSynergiesURL} element={<TeamSingleSynergiesView />} />
          <Route
            path={teamsSinglePicksLeaguepediaURL}
            element={<TeamSinglePicksLeaguepediaView />}
          />
          <Route path={teamsSinglePicksURL} element={<TeamSinglePicksView />} />
          <Route
            path={teamsSingleEnemyDataLeaguepediaURL}
            element={<TeamSingleEnemyDataLeaguepediaView />}
          />
          <Route path={teamsSingleEnemyDataURL} element={<TeamSingleEnemyDataView />} />
          <Route path={teamsSingleLvL1WardsURL} element={<TeamSingleLvL1WardsView />} />
          <Route path={teamsSingleLvL1PositionsURL} element={<TeamSingleLvL1Positions />} />
          <Route
            path={teamsSingleJungleStatisticsURL}
            element={<TeamSingleJungleStatisticsView />}
          />
          <Route path={teamsSingleJungleURL} element={<TeamSingleJungleView />} />
          <Route path={teamsSingleShopURL} element={<TeamSingleShopView />} />
          <Route path={teamsSingleWardsStatisticsURL} element={<TeamSingleWardsStatisticsView />} />
          <Route path={teamsSingleWardsObjectivesURL} element={<TeamSingleWardsObjectivesView />} />
          <Route path={teamsSingleWardsSurvivedURL} element={<TeamSingleWardsSurvivedView />} />
          <Route path={teamsSingleWardsTimelineURL} element={<TeamSingleWardsTimelineView />} />
          <Route path={teamsSingleWardsURL} element={<TeamSingleWardsView />} />
          <Route path={teamsSingleOracleLensURL} element={<TeamSingleOracleLensView />} />
          <Route path={teamsSingleHeatmapURL} element={<TeamSingleHeatmapView />} />
          <Route path={teamsSinglePositionsOnMapURL} element={<TeamSinglePositionsOnMapView />} />
          <Route path={teamsSingleObjectivesURL} element={<TeamSingleObjectivesView />} />
          <Route path={teamsSingleSummonersURL} element={<TeamSingleSummonersView />} />
          <Route path={teamsSingleRecallsURL} element={<TeamSingleRecallsView />} />
          <Route path={teamsSingleGraphsURL} element={<TeamSingleGraphsView />} />
          <Route path={teamsSingleWinRateURL} element={<TeamSingleWinRateView />} />
          <Route path={teamsSinglePerformanceURL} element={<TeamSinglePerformanceView />} />
          <Route path={teamsSingleScoresURL} element={<TeamSingleScoresView />} />
          <Route path={teamsSingleHoursURL} element={<TeamSingleHoursView />} />
          <Route path={teamsSingleURL} element={<TeamSingleView />} />
          <Route path={teamsBasicURL} element={<TeamsView />} />
          <Route path={playersSingleChampionPoolURL} element={<PlayerSingleChampionPoolView />} />
          <Route path={playersSingleStatisticsURL} element={<PlayerSingleStatisticsView />} />
          <Route path={playersSingleGraphsURL} element={<PlayerSingleGraphsView />} />
          <Route path={playersSingleHoursURL} element={<PlayerSingleHoursView />} />
          <Route path={playersSingleURL} element={<PlayerSingleView />} />
          <Route path={playersBasicURL} element={<PlayersView />} />
          <Route path={championsSingleURL} element={<ChampionSingleView />} />
          <Route path={championsBasicURL} element={<ChampionsView />} />
          <Route path={gamesSingleTeamFightsURL} element={<GameSingleTeamFightsView />} />
          <Route path={gamesSinglePositionsOnMapURL} element={<GameSinglePositionsOnMapView />} />
          <Route path={gamesSingleScoreboardURL} element={<GameSingleScoreboardView />} />
          <Route path={gamesSingleURL} element={<GameSingleView />} />
          <Route path={searchVodBasicURL} element={<SearchVodView />} />
          <Route path={searchCounterBasicURL} element={<SearchCounterView />} />
          <Route path={searchChampionsDataBasicURL} element={<SearchChampionsDataView />} />
          <Route path={searchWorldsMatchupsBasicURL} element={<SearchWorldsMatchupsView />} />
          <Route path={comparePlayersBasicURL} element={<ComparePlayersView />} />
          <Route path={replaysBasicURL} element={<ReplaysView />} />
          <Route path={performanceEditURL} element={<PerformanceEditView />} />
          <Route path={performanceAddURL} element={<PerformanceAddView />} />
          <Route path={performanceReportBasicURL} element={<PerformanceReportView />} />
          <Route path={performanceBasicURL} element={<PerformanceView />} />
          <Route path={scoutingSoloqView} element={<ScoutingSoloqView />} />
          <Route path={scoutingCompetitiveView} element={<ScoutingCompetitiveView />} />
          <Route path={settingsBasicURL} element={<SettingsView />} />
          {/* Editor */}
          <Route path={editorDraftEditURL} element={<EditorDraftEditView />} />
          <Route path={editorDraftBasicURL} element={<EditorDraftView />} />
          {/* Social Media */}
          <Route path={socialMediaH2HBasicURL} element={<SocialMediaH2HView />} />
          <Route path={socialMediaBasicURL} element={<SocialMediaView />} />
          {/* Admin */}
          <Route path={adminHomeURL} element={<AdminHomeView />} />
          <Route path={adminOptionsURL} element={<AdminOptionsView />} />
          <Route path={adminOptionsAddURL} element={<AdminOptionsAddView />} />
          <Route path={adminOptionsEditURL} element={<AdminOptionsEditView />} />
          <Route path={adminUsersURL} element={<AdminUsersView />} />
          <Route path={adminUsersAddURL} element={<AdminUsersAddView />} />
          <Route path={adminUsersEditURL} element={<AdminUsersEditView />} />
          <Route path={adminUsersPasswordURL} element={<AdminUsersEditPasswordView />} />
          <Route path={adminTagsURL} element={<AdminTagsView />} />
          <Route path={adminTagsAddURL} element={<AdminTagsAddView />} />
          <Route path={adminTagsEditURL} element={<AdminTagsEditView />} />
          <Route path={adminChampionsURL} element={<AdminChampionsView />} />
          <Route path={adminChampionsAddURL} element={<AdminChampionsAddView />} />
          <Route path={adminChampionsEditURL} element={<AdminChampionsEditView />} />
          <Route path={adminChampionSpellsURL} element={<AdminChampionSpellsView />} />
          <Route path={adminItemsURL} element={<AdminItemsView />} />
          <Route path={adminItemsAddURL} element={<AdminItemsAddView />} />
          <Route path={adminItemsEditURL} element={<AdminItemsEditView />} />
          <Route path={adminPatchesURL} element={<AdminPatchesView />} />
          <Route path={adminPatchesAddURL} element={<AdminPatchesAddView />} />
          <Route path={adminPatchesEditURL} element={<AdminPatchesEditView />} />
          <Route path={adminSummonerSpellsURL} element={<AdminSummonerSpellsView />} />
          <Route path={adminRunesURL} element={<AdminRunesView />} />
          <Route path={adminKeystonesURL} element={<AdminKeystonesView />} />
          <Route path={adminPerksURL} element={<AdminPerksView />} />
          <Route path={adminShardsURL} element={<AdminShardsView />} />
          <Route path={adminRegionsURL} element={<AdminRegionsView />} />
          <Route path={adminRegionsAddURL} element={<AdminRegionsAddView />} />
          <Route path={adminRegionsEditURL} element={<AdminRegionsEditView />} />
          <Route path={adminServersURL} element={<AdminServersView />} />
          <Route path={adminServersAddURL} element={<AdminServersAddView />} />
          <Route path={adminServersEditURL} element={<AdminServersEditView />} />
          <Route path={adminTiersURL} element={<AdminTiersView />} />
          <Route path={adminTiersAddURL} element={<AdminTiersAddView />} />
          <Route path={adminTiersEditURL} element={<AdminTiersEditView />} />
          <Route path={adminAccountsURL} element={<AdminAccountsView />} />
          <Route path={adminAccountsAddURL} element={<AdminAccountsAddView />} />
          <Route path={adminAccountsEditURL} element={<AdminAccountsEditView />} />
          <Route path={adminRankedGamesURL} element={<AdminRankedGamesView />} />
          <Route path={adminNationalitiesURL} element={<AdminNationalitiesView />} />
          <Route path={adminNationalitiesAddURL} element={<AdminNationalitiesAddView />} />
          <Route path={adminNationalitiesEditURL} element={<AdminNationalitiesEditView />} />
          <Route path={adminLeaguesURL} element={<AdminLeaguesView />} />
          <Route path={adminLeaguesAddURL} element={<AdminLeaguesAddView />} />
          <Route path={adminLeaguesEditURL} element={<AdminLeaguesEditView />} />
          <Route path={adminSplitsURL} element={<AdminSplitsView />} />
          <Route path={adminSplitsAddURL} element={<AdminSplitsAddView />} />
          <Route path={adminSplitsEditURL} element={<AdminSplitsEditView />} />
          <Route path={adminTeamsURL} element={<AdminTeamsView />} />
          <Route path={adminTeamsAddURL} element={<AdminTeamsAddView />} />
          <Route path={adminTeamsEditURL} element={<AdminTeamsEditView />} />
          <Route path={adminPlayersURL} element={<AdminPlayersView />} />
          <Route path={adminPlayersAddURL} element={<AdminPlayersAddView />} />
          <Route path={adminPlayersEditURL} element={<AdminPlayersEditView />} />
          <Route path={adminPlayerUrnsURL} element={<AdminPlayerUrnsView />} />
          <Route path={adminPlayerUrnsAddURL} element={<AdminPlayerUrnsAddView />} />
          <Route path={adminPlayerUrnsEditURL} element={<AdminPlayerUrnsEditView />} />
          <Route path={adminMatchesURL} element={<AdminMatchesView />} />
          <Route path={adminMatchesEditURL} element={<AdminMatchesEditView />} />
          <Route path={adminGamesURL} element={<AdminGamesView />} />
          <Route path={adminGamesEditURL} element={<AdminGamesEditView />} />
          <Route path={adminUnknownUrnsURL} element={<AdminUnknownUrnsView />} />
          <Route path={adminUnknownUrnsEditURL} element={<AdminUnknownUrnsEditView />} />
          <Route path={adminGameErrorsURL} element={<AdminGameErrorsView />} />
        </Routes>
      </Container>
    </>
  );
};

export default App;
