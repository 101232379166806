// React
import React, { useMemo } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { CHAMPION_POOL_COLS } from 'constants/tableHead';

// Elements
import { Box } from '@mui/material';
import ChampionPool from 'components/molecules/ChampionPool/ChampionPool';

// Component
const TeamChampionPool = ({ data, enemy }) => {
  const columns = useMemo(() => CHAMPION_POOL_COLS, []);

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: '1fr',
        sm: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
        xl: 'repeat(5, 1fr)',
      }}
      gap={1}
      mt={1}
    >
      {Object.entries(data).map(([key, value]) => (
        <ChampionPool
          key={key}
          title={`${enemy ? 'ENEMY ' : ''}${key} (${value.count} games)`}
          data={value.data}
          columns={columns}
        />
      ))}
    </Box>
  );
};

TeamChampionPool.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  enemy: PropTypes.bool,
};

TeamChampionPool.defaultProps = {
  enemy: false,
};

export default TeamChampionPool;
