// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { BLACK_MAIN, WHITE_MAIN, WHITE_A10, BLUE_A10 } from 'constants/color';

// Elements
import { Box, Typography, useMediaQuery } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const GameScoreboard = ({ data, side, openModal }) => {
  const isDesktopViewport = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  let championImageSize = 32;
  let itemImageSize = (championImageSize / 2) * 1.5;

  if (useMediaQuery((theme) => theme.breakpoints.up('sm'))) {
    championImageSize = 64;
    itemImageSize = championImageSize / 2;
  }

  const renderSummonerSpells = (summonerSpells) =>
    summonerSpells.map(({ id, name, image_url }, index) => (
      <Box key={id} mt={index > 0 ? 0.5 : 0}>
        <ImageItem
          src={image_url}
          alt={name}
          width={championImageSize / 2 - 1}
          height={championImageSize / 2 - 1}
        />
      </Box>
    ));

  // const renderRunes = (runes) =>
  //   runes.map(({ id, name, image_url }, index) => (
  //     <Box key={id} mt={index > 0 ? 0.5 : 0}>
  //       <ImageItem
  //         src={image_url}
  //         alt={name}
  //         width={championImageSize / 2 - 1}
  //         height={championImageSize / 2 - 1}
  //       />
  //     </Box>
  //   ));

  const renderItems = (items) =>
    items.map(({ id, name, image_url }, index) => (
      <Box key={id} ml={index > 0 ? 0.5 : 0}>
        {image_url ? (
          <ImageItem src={image_url} alt={name} width={itemImageSize} height={itemImageSize} />
        ) : (
          <Box width={itemImageSize} height={itemImageSize} />
        )}
      </Box>
    ));

  return data?.map(
    ({
      id: pickId,
      name: pickName,
      image_url: pickImageURL,
      kills,
      deaths,
      assists,
      level,
      summoner_spells,
      items,
      trinket,
      // runes,
    }) => (
      <Box
        key={pickId}
        display="flex"
        flexDirection={side === 'red' && isDesktopViewport && 'row-reverse'}
        alignItems="center"
        mb={1}
        sx={{
          backgroundColor: WHITE_A10,
          padding: isDesktopViewport ? 1 : 0.5,
          cursor: 'pointer',
          transition: 'all .5s ease',
          '&:hover': {
            backgroundColor: BLUE_A10,
          },
        }}
        onClick={() => openModal(pickId)}
      >
        <Box
          display="flex"
          flexDirection="column"
          ml={side === 'red' && isDesktopViewport ? 0.5 : 0}
          mr={side === 'blue' || !isDesktopViewport ? 0.5 : 0}
        >
          {renderSummonerSpells(summoner_spells)}
        </Box>
        <Box position="relative">
          <ImageItem
            src={pickImageURL}
            alt={pickName}
            width={championImageSize}
            height={championImageSize}
            noborder
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 2,
              right: (side === 'blue' || !isDesktopViewport) && 2,
              left: side === 'red' && isDesktopViewport && 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: isDesktopViewport ? 20 : 12,
              height: isDesktopViewport ? 20 : 12,
              color: BLACK_MAIN,
              backgroundColor: WHITE_MAIN,
            }}
          >
            <Typography
              variant="body2"
              component="p"
              sx={{ fontSize: isDesktopViewport ? 12 : 10, fontWeight: 'bold' }}
            >
              {level}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          ml={side === 'blue' || !isDesktopViewport ? 1 : 0}
          mr={side === 'red' && isDesktopViewport ? 1 : 0}
        >
          {/* {renderRunes(runes)} */}
        </Box>
        <Typography
          variant="body1"
          component="p"
          mx={isDesktopViewport ? 2 : 1}
          textAlign="center"
          fontSize={isDesktopViewport ? 14 : 12}
          width={isDesktopViewport ? 80 : 40}
        >
          {kills}/{deaths}/{assists}
        </Typography>
        <Box
          display="flex"
          flexDirection={side === 'red' && isDesktopViewport && 'row-reverse'}
          alignItems="center"
        >
          {renderItems(items)}
        </Box>
        <Box
          ml={side === 'blue' || !isDesktopViewport ? 1 : 0}
          mr={side === 'red' && isDesktopViewport ? 1 : 0}
        >
          <ImageItem
            src={trinket?.image_url}
            alt={trinket?.name}
            width={championImageSize / 2}
            height={championImageSize / 2}
          />
        </Box>
      </Box>
    ),
  );
};

GameScoreboard.propTypes = {
  data: PropTypes.array,
  side: PropTypes.string,
  openModal: PropTypes.func.isRequired,
};

GameScoreboard.defaultProps = {
  data: [],
  side: 'blue',
};

export default GameScoreboard;
