// React
import React, { useContext } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Services
import { fetchReplayGameURL, fetchReplayGameFilenameURL } from 'services/games';

// Constants
import { BLUE_MAIN } from 'constants/color';

// Assets
import DownloadIcon from '@mui/icons-material/Download';

// Elements
import { IconButton } from '@mui/material';

// Component
const ReplayData = ({ gameId }) => {
  if (!gameId) {
    return null;
  }

  const { setIsLoading } = useContext(Context);

  const handleDownload = async (e) => {
    try {
      e.stopPropagation();

      setIsLoading(true);

      fetchReplayGameURL(gameId)
        .then((response) => response)
        .then((blob) => {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: 'application/octet-stream' }),
          );

          fetchReplayGameFilenameURL(gameId).then((filename) => {
            const a = document.createElement('a');

            a.href = url;
            a.download = filename;
            a.click();
            a.remove();

            setIsLoading(false);
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IconButton aria-label="download" onClick={handleDownload} size="small">
      <DownloadIcon fontSize="small" sx={{ color: BLUE_MAIN }} />
    </IconButton>
  );
};

ReplayData.propTypes = {
  gameId: PropTypes.string,
};

ReplayData.defaultProps = {
  gameId: null,
};

export default ReplayData;
