// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchPicksLeaguepedia } from 'services/dataLeaguepedia';

// Constants
import { HIDE_PAGINATION } from 'constants/mrt';
import { CHAMPION_PICKS_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';

const TeamSinglePicksLeaguepediaView = () => {
  const {
    setTitleSEO,
    filters: {
      team: { pick, rangeDate, side, roleAll },
    },
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (typeData, sideData, roleData, teamId, startDateData, endDateData) => {
    setIsLoading(true);

    fetchPicksLeaguepedia(typeData, sideData, roleData, teamId, startDateData, endDateData).then(
      (res) => {
        setData(res.data);

        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(pick, side, roleAll, team.id, rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate, pick, side, roleAll, team]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Picks`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  useEffect(() => {
    setColumns(CHAMPION_PICKS_COLS(pick));
  }, [pick]);

  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_PAGINATION,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters rangeDateFilter pickFilter sideFilter roleAllFilter type="team" />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default TeamSinglePicksLeaguepediaView;
