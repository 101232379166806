// React
import React, { useState, useEffect } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Router
import { useLocation, matchPath } from 'react-router-dom';

// Constants
import {
  SCRIM_MENU,
  SPLIT_MENU,
  TEAM_MENU,
  TEAM_LEAGUEPEDIA_MENU,
  PLAYER_MENU,
  SOLOQ_REPORT_MENU,
  SOCIAL_MEDIA_MENU,
} from 'constants/menu';

// Elements
import { Box } from '@mui/material';
import TabMenu from 'components/atoms/TabMenu/TabMenu';

// Component
const Sidebar = ({ list, list2 }) => {
  const { pathname } = useLocation();
  const [menu, setMenu] = useState([]);
  const [routeMatch, setRouteMatch] = useState([]);
  const [menu2, setMenu2] = useState([]);
  const [routeMatch2, setRouteMatch2] = useState([]);

  const useRouteMatch = (patterns, secondaryMenu) => {
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch =
        secondaryMenu && secondaryMenu.length > 0
          ? matchPath({ path: pattern, end: false }, pathname)
          : matchPath(pattern, pathname);

      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  };

  useEffect(() => {
    if (list) {
      setMenu(list);
    } else if (pathname.includes('/scrims')) {
      setMenu(SCRIM_MENU);
    } else if (pathname.includes('/split')) {
      setMenu(SPLIT_MENU);
    } else if (pathname.includes('/team')) {
      setMenu(TEAM_MENU);
    } else if (pathname.includes('/player')) {
      setMenu(PLAYER_MENU);
    } else if (pathname.includes('/soloq')) {
      setMenu(SOLOQ_REPORT_MENU);
    } else if (pathname.includes('/social-media')) {
      setMenu(SOCIAL_MEDIA_MENU);
    }

    if (pathname.includes('/leaguepedia')) {
      setMenu(TEAM_LEAGUEPEDIA_MENU);
    }

    if (list2) {
      setMenu2(list2);
    }

    if ((menu && menu.length > 0) || (menu2 && menu2.length > 0)) {
      if (menu && menu.length > 0) {
        setRouteMatch(
          useRouteMatch(
            menu.map(({ link }) => link),
            menu2,
          ),
        );
      }

      if (menu2 && menu2.length > 0) {
        setRouteMatch2(useRouteMatch(menu2.map(({ link }) => link)));
      }
    }
  }, [list, list2, pathname, menu, menu2]);

  return (menu && menu.length > 0) || (menu2 && menu2.length > 0) ? (
    <Box sx={{ height: '100%', borderRight: { lg: 1 }, borderColor: { lg: 'divider' } }}>
      {menu && menu.length > 0 && routeMatch?.pattern?.path && (
        <TabMenu data={menu} currentTab={routeMatch.pattern.path} routeMatch={routeMatch} />
      )}
      {menu2 && menu2.length > 0 && routeMatch2?.pattern?.path && (
        <Box mt={{ xs: 4, lg: 16 }}>
          <TabMenu data={menu2} currentTab={routeMatch2.pattern.path} routeMatch={routeMatch2} />
        </Box>
      )}
    </Box>
  ) : null;
};

Sidebar.propTypes = {
  list: PropTypes.array,
  list2: PropTypes.array,
};

Sidebar.defaultProps = {
  list: null,
  list2: null,
};

export default Sidebar;
