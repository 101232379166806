export const DRAFT_TEAM_TYPE = [
  {
    id: 'ANY',
    name: 'ANY',
    value: 'ANY',
  },
  {
    id: 'TEAM',
    name: 'TEAM',
    value: 'TEAM',
  },
  {
    id: 'ENEMY',
    name: 'ENEMY',
    value: 'ENEMY',
  },
];

export const DRAFT_SIDE_TYPE = [
  {
    id: 'BOTH',
    name: 'BOTH',
    value: 'BOTH',
  },
  {
    id: 'BLUE',
    name: 'BLUE',
    value: 'BLUE',
  },
  {
    id: 'RED',
    name: 'RED',
    value: 'RED',
  },
];

export const SEARCH_TEAM_C1_TYPE = [
  {
    id: 'ANY',
    name: 'ANY',
    value: 'ANY',
  },
  {
    id: 'BLUE',
    name: 'BLUE',
    value: 'BLUE',
  },
  {
    id: 'RED',
    name: 'RED',
    value: 'RED',
  },
];

export const SEARCH_TEAM_TYPE = [
  {
    id: 'ANY',
    name: 'ANY',
    value: 'ANY',
  },
  {
    id: 'SAME',
    name: 'SAME AS C1',
    value: 'SAME',
  },
  {
    id: 'DIFFERENT',
    name: 'DIFFERENT FROM C1',
    value: 'DIFFERENT',
  },
];
