// Axios
import { axiosInstance } from 'utils/axios';

export const fetchScoutingSoloqData = (data) =>
  axiosInstance
    .post('scouting/soloq/', data)
    .then((res) => ({ data: res.data, message: 'Data have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchScoutingCompetitiveData = (data) =>
  axiosInstance
    .post('scouting/competitive/', data)
    .then((res) => ({ data: res.data, message: 'Data have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
