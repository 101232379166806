// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Utils
import { generateUniqueKey } from 'utils/key';

// Assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Elements
import { Grid, Box, Button, IconButton, Typography } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';

// Component
const VODsCompetitiveManageData = ({ value, handleChange, setFieldValue }) => {
  const addNewVodsC = () => {
    setFieldValue('data', [
      ...value,
      {
        key: generateUniqueKey(),
        link: '',
        duration: '',
        goal: '',
        conclusions: '',
        notes: '',
      },
    ]);
  };

  const removeVodsC = (index) => {
    const updatedVodsC = [...value];
    updatedVodsC.splice(index, 1);

    setFieldValue('data', updatedVodsC);
  };

  return (
    <Box>
      <Typography variant="subtitle1">VODs competitive</Typography>
      {value.map(({ key, link, duration, goal, conclusions, notes }, vodsCIndex) => (
        <Box key={key} mt={vodsCIndex > 0 ? 3 : 0}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                name={`data[${vodsCIndex}].link`}
                label="Link"
                value={link || ''}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name={`data[${vodsCIndex}].duration`}
                label="Duration"
                value={duration || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name={`data[${vodsCIndex}].goal`}
                label="Goal"
                value={goal || ''}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name={`data[${vodsCIndex}].conclusions`}
                label="Conclusions"
                value={conclusions || ''}
                onChange={handleChange}
                rows={4}
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name={`data[${vodsCIndex}].notes`}
                label="Notes"
                value={notes || ''}
                onChange={handleChange}
                rows={7}
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box mt={2}>
                <IconButton aria-label="delete" onClick={() => removeVodsC(vodsCIndex)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Button variant="text" size="small" startIcon={<AddIcon />} onClick={addNewVodsC}>
        Add vod
      </Button>
    </Box>
  );
};

VODsCompetitiveManageData.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default VODsCompetitiveManageData;
