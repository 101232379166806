// Yup
import * as Yup from 'yup';

const MIN_CHAMPIONS = 1;
const MAX_CHAMPIONS = 5;

const MIN_TEAM_ROLES = 2;
const MAX_TEAM_ROLES = 5;

export const SEARCH_COUNTER_CHAMPIONS_SCHEMA = Yup.array()
  .min(MIN_CHAMPIONS, `You must choose at least ${MIN_CHAMPIONS} champions!`)
  .max(MAX_CHAMPIONS, `You can choose up to ${MAX_CHAMPIONS} champions!`)
  .required('Required');

export const SEARCH_COUNTER_TEAM_ROLES_SCHEMA = Yup.array()
  .min(MIN_TEAM_ROLES, `You must choose at least ${MIN_TEAM_ROLES} roles!`)
  .max(MAX_TEAM_ROLES, `You can choose up to ${MAX_TEAM_ROLES} roles!`)
  .required('Required');
