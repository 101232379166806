// React
import React, { createContext, useState, useMemo } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Cookies
import { useCookies } from 'react-cookie';

// Constants
import { FILTER_CONTEXT } from 'constants/filter';

// Context
export const Context = createContext({});

// Component
const ContextProvider = ({ children }) => {
  const [cookie] = useCookies(['token']);
  const [token, setToken] = useState(cookie.token || null);
  const [titleSEO, setTitleSEO] = useState('Loading...');
  const [user, setUser] = useState({});
  const [currentTeamId, setCurrentTeamId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalTableData, setModalTableData] = useState(null);
  const [isModalTableOpen, setIsModalTableOpen] = useState(false);
  const [filters, setFilters] = useState(FILTER_CONTEXT);

  const value = useMemo(
    () => ({
      token,
      setToken,
      titleSEO,
      setTitleSEO,
      user,
      setUser,
      currentTeamId,
      setCurrentTeamId,
      isDialogOpen,
      setIsDialogOpen,
      filters,
      setFilters,
      isLoading,
      setIsLoading,
      modalTableData,
      setModalTableData,
      isModalTableOpen,
      setIsModalTableOpen,
    }),
    [
      token,
      setToken,
      titleSEO,
      setTitleSEO,
      user,
      setUser,
      currentTeamId,
      setCurrentTeamId,
      isDialogOpen,
      setIsDialogOpen,
      filters,
      setFilters,
      isLoading,
      setIsLoading,
      modalTableData,
      setModalTableData,
      isModalTableOpen,
      setIsModalTableOpen,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ContextProvider;
