// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchFlexLeaguepedia } from 'services/dataLeaguepedia';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION } from 'constants/mrt';
import { TEAM_FLEX_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';

const TeamSingleFlexLeaguepediaView = () => {
  const {
    setTitleSEO,
    filters: {
      team: { rangeDate, side },
    },
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (sideData, teamId, startDateData, endDateData) => {
    setIsLoading(true);

    fetchFlexLeaguepedia(sideData, teamId, startDateData, endDateData).then((res) => {
      setData(res.data);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(side, team.id, rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate, side, team]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Flex`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  const columns = useMemo(() => TEAM_FLEX_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters rangeDateFilter sideFilter type="team" />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default TeamSingleFlexLeaguepediaView;
