export const formatDatetime = (datetime) => {
  const newDatetime = new Date(datetime);

  const year = newDatetime.getFullYear();
  const month = newDatetime.getMonth() + 1;
  const day = newDatetime.getDate();
  const hours = newDatetime.getHours();
  const minutes = newDatetime.getMinutes();
  const seconds = newDatetime.getSeconds();

  const textMonth = month < 10 ? `0${month}` : month;
  const textDay = day < 10 ? `0${day}` : day;
  const textHours = hours < 10 ? `0${hours}` : hours;
  const textMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const textSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${year}-${textMonth}-${textDay} ${textHours}:${textMinutes}:${textSeconds}`;
};

export const secondsToHms = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60);

  const textHours = hours < 10 ? `0${hours}` : hours;
  const textMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const textSeconds = seconds < 10 ? `0${seconds}` : seconds;

  if (hours === 0) {
    return `${textMinutes}:${textSeconds}`;
  }

  return `${textHours}:${textMinutes}:${textSeconds}`;
};
