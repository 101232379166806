// Axios
import { axiosLoadingInstance, axiosInstance } from 'utils/axios';

export const fetchSocialMediaData = async () =>
  axiosLoadingInstance
    .get('social-media/data/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSocialMediaGameURL = (id) =>
  axiosInstance
    .get(`social-media/file/${id}/`, { responseType: 'blob', withCredentials: true })
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSocialMediaH2HData = (data) =>
  axiosInstance
    .post('social-media/h2h/', data, { responseType: 'blob', withCredentials: true })
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
