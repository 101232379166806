// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Router
import { Link } from 'react-router-dom';

// Elements
import { Tabs, Tab, useMediaQuery } from '@mui/material';

const LinkTab = (props) => <Tab component={Link} {...props} />;

// Component
const TabMenu = ({ data, currentTab, routeMatch }) => {
  const isDesktopViewport = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const generateLink = (link, params) => {
    let newLink = link;

    if (params) {
      Object.keys(params).forEach((key) => {
        newLink = newLink.replace(`:${key}`, params[key]);
      });
    }

    return newLink;
  };

  return (
    <Tabs
      value={currentTab}
      orientation={isDesktopViewport ? 'vertical' : 'horizontal'}
      aria-label="Navigation"
      TabIndicatorProps={{
        sx: {
          width: '2px',
        },
      }}
      variant="scrollable"
      scrollButtons="auto"
      visibleScrollbar={!isDesktopViewport}
    >
      {data.map(({ id, title, link, basic }, index) => {
        const newLink = basic ? generateLink(link, routeMatch?.params) : link;

        return (
          <LinkTab
            key={id}
            value={link}
            label={title}
            to={newLink}
            sx={{ marginLeft: { xs: index > 0 ? 2 : 0, lg: 0 }, padding: 0, minHeight: 40 }}
          />
        );
      })}
    </Tabs>
  );
};

TabMenu.propTypes = {
  data: PropTypes.array.isRequired,
  currentTab: PropTypes.string.isRequired,
  routeMatch: PropTypes.object.isRequired,
};

export default TabMenu;
