// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchDrafts } from 'services/data';

// Elements
import DraftsTemplate from 'components/templates/DraftsTemplate/DraftsTemplate';

const TeamSingleDraftsView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        result,
        patch,
        patches,
        opponent,
        opponents,
        blitz,
        draftFilter,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    resultData,
    teamId,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
    draftFilterData,
  ) => {
    setIsLoading(true);

    fetchDrafts(
      itemType,
      sideData,
      resultData,
      teamId,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
      draftFilterData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        team: { ...prevValue.team, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        result,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
        draftFilter,
      );
    }
  }, [matchType, rangeDate, side, result, team, patch, opponent, blitz, draftFilter]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Drafts`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <DraftsTemplate data={data} team={team} title={title} patches={patches} opponents={opponents} />
  );
};

export default TeamSingleDraftsView;
