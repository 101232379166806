// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const PicksData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue() || getValue().length === 0) {
    return null;
  }

  return (
    <Box display="grid" gap={1}>
      {getValue().map(({ id, name, image_url, wins, losses }) => (
        <Box key={id} display="grid" gridTemplateColumns="1fr 2fr 2fr" alignItems="center" gap={2}>
          <ImageItem src={image_url} alt={name} />
          <Typography variant="body2" component="span" textAlign="left">
            {name}
          </Typography>
          <Typography variant="body2" component="span" textAlign="left">
            {wins}W - {losses}L
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

PicksData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default PicksData;
