// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Services
import { addStaticData } from 'services/admin';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Assets
import AddIcon from '@mui/icons-material/Add';

// Elements
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const AdminHomeView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingState, setLoadingState] = useState(false);
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    setIsLoading(false);

    if (!renderAfterCalled.current) {
      setTitleSEO(`Home ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const importData = () => {
    setLoadingState(true);

    addStaticData().then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      setLoadingState(false);
    });
  };

  return (
    <Box>
      <Typography variant="h6" component="p">
        Actions
      </Typography>
      <Box mt={1}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={loadingState}
          loadingIndicator="Importing..."
          startIcon={<AddIcon fontSize="small" />}
          onClick={importData}
        >
          Import Static Data
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default AdminHomeView;
