// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Yup
import * as Yup from 'yup';

// Routes
import { adminTeamsURL } from 'routes/admin';

// Services
import { fetchSplits } from 'services/splits';
import { fetchSingleTeam, importRosterForTeam, updateTeam } from 'services/teams';
import { fetchPlayersByRole } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { TEAM_PLAYERS } from 'constants/player';

// Assets
import RefreshIcon from '@mui/icons-material/Refresh';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminTeamsEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [splitOptions, setSplitOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState({
    ALL: [],
    TOP: [],
    JNG: [],
    MID: [],
    BOT: [],
    SUP: [],
  });
  const [currentSplits, setCurrentSplits] = useState([]);
  const [currentPlayers, setCurrentPlayers] = useState(JSON.parse(JSON.stringify(TEAM_PLAYERS)));
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleTeam(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });

    fetchSplits().then((res) => setSplitOptions(res));

    fetchPlayersByRole().then((res) => setPlayerOptions(res));
  };

  const importRoster = () => {
    setBtnLoading(true);

    importRosterForTeam(id).then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchData();

      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      if (data.splits && data.splits.length > 0) {
        const splits = data.splits.map(({ split }) => split);

        setCurrentSplits(splits);
      }

      if (data.players && data.players.length > 0) {
        const players = JSON.parse(JSON.stringify(TEAM_PLAYERS));

        data.players.map((el) => {
          const subData = el.is_sub;
          const isSub = subData ? 'sub' : 'player';
          const role = el.role.toLowerCase();
          const fieldName = `${isSub}_${role}`;

          if (!subData) {
            players[fieldName] = el.player;
          } else {
            players[fieldName].push(el.player);
          }

          return true;
        });

        setCurrentPlayers(players);
      }

      setTitleSEO(`Edit ${data?.name} - Teams ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    name: data?.name || '',
    short: data?.short || '',
    provider_id: data?.provider_id || '',
    leaguepedia: data?.leaguepedia || '',
    splits: currentSplits,
    image_url: data?.image_url || '',
    player_top: currentPlayers.player_top,
    player_jng: currentPlayers.player_jng,
    player_mid: currentPlayers.player_mid,
    player_bot: currentPlayers.player_bot,
    player_sup: currentPlayers.player_sup,
    sub_top: currentPlayers.sub_top,
    sub_jng: currentPlayers.sub_jng,
    sub_mid: currentPlayers.sub_mid,
    sub_bot: currentPlayers.sub_bot,
    sub_sup: currentPlayers.sub_sup,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    short: Yup.string().required('Required'),
    provider_id: Yup.string(),
    leaguepedia: Yup.string().required('Required'),
    image_url: Yup.string().url(),
    player_top: Yup.object().nullable(),
    player_jng: Yup.object().nullable(),
    player_mid: Yup.object().nullable(),
    player_bot: Yup.object().nullable(),
    player_sup: Yup.object().nullable(),
    sub_top: Yup.array().nullable(),
    sub_jng: Yup.array().nullable(),
    sub_mid: Yup.array().nullable(),
    sub_bot: Yup.array().nullable(),
    sub_sup: Yup.array().nullable(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'short',
      label: 'Short',
      typeField: 'textfield',
    },
    {
      id: 'provider_id',
      label: 'Provider ID',
      typeField: 'textfield',
    },
  ];
  const fields2 = [
    {
      id: 'splits',
      label: 'Splits',
      typeField: 'multiautocomplete',
      options: splitOptions,
    },
    {
      id: 'leaguepedia',
      label: 'Leaguepedia',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
  ];
  const fields3 = [
    {
      id: 'player_top',
      label: 'TOP',
      typeField: 'autocomplete',
      options: playerOptions.TOP,
    },
    {
      id: 'player_jng',
      label: 'JNG',
      typeField: 'autocomplete',
      options: playerOptions.JNG,
    },
    {
      id: 'player_mid',
      label: 'MID',
      typeField: 'autocomplete',
      options: playerOptions.MID,
    },
    {
      id: 'player_bot',
      label: 'BOT',
      typeField: 'autocomplete',
      options: playerOptions.BOT,
    },
    {
      id: 'player_sup',
      label: 'SUP',
      typeField: 'autocomplete',
      options: playerOptions.SUP,
    },
  ];
  const fields4 = [
    {
      id: 'sub_top',
      label: 'SUBS TOP',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
    },
    {
      id: 'sub_jng',
      label: 'SUBS JNG',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
    },
    {
      id: 'sub_mid',
      label: 'SUBS MID',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
    },
    {
      id: 'sub_bot',
      label: 'SUBS BOT',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
    },
    {
      id: 'sub_sup',
      label: 'SUBS SUP',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
    },
  ];
  const buttons = [
    {
      id: 1,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import roster',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importRoster,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFields4={fields4}
      formikFn={updateTeam}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminTeamsURL}
      formikBtnText="Update team"
      additionalBtns={buttons}
    />
  );
};

export default AdminTeamsEditView;
