// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Yup
import * as Yup from 'yup';

// Routes
import { adminAccountsURL } from 'routes/admin';

// Services
import { fetchServers } from 'services/servers';
import {
  fetchSingleAccount,
  updateAccount,
  addRankedGamesForAccount,
  assignRankedGamesToAccount,
} from 'services/accounts';
import { fetchPlayers } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Assets
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminAccountsEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [serverOptions, setServerOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleAccount(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });

    fetchServers().then((res) => setServerOptions(res));
    fetchPlayers().then((res) => setPlayerOptions(res));
  };

  const addRankedGames = () => {
    setBtnLoading(true);

    addRankedGamesForAccount(id).then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      setBtnLoading(false);
    });
  };

  const assignRankedGames = () => {
    setBtnLoading(true);

    assignRankedGamesToAccount(id).then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data.name} - Accounts ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    name: data?.name || '',
    tag: data?.tag || '',
    server: (serverOptions.length > 0 && data?.server) || null,
    hidden: data?.hidden || false,
    player: (playerOptions.length > 0 && data?.player) || null,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    server: Yup.object().typeError('Choose server!').required('Required'),
    hidden: Yup.boolean(),
    player: Yup.object().nullable(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
      disabled: true,
    },
    {
      id: 'tag',
      label: 'Tag',
      typeField: 'textfield',
      disabled: true,
    },
    {
      id: 'hidden',
      label: 'Hidden',
      typeField: 'checkbox',
    },
  ];
  const fields2 = [
    {
      id: 'server',
      label: 'Server',
      typeField: 'autocomplete',
      options: serverOptions,
      disabled: true,
    },
    {
      id: 'player',
      label: 'Player',
      typeField: 'autocomplete',
      options: playerOptions,
    },
  ];
  const buttons = [
    {
      id: 1,
      loadingState: btnLoading,
      loadingText: 'Adding...',
      btnText: 'Add ranked games',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: addRankedGames,
    },
    {
      id: 2,
      loadingState: btnLoading,
      loadingText: 'Assigning...',
      btnText: 'Assign ranked games',
      btnIcon: <EditIcon fontSize="small" />,
      btnFn: assignRankedGames,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFn={updateAccount}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminAccountsURL}
      formikBtnText="Update account"
      additionalBtns={buttons}
    />
  );
};

export default AdminAccountsEditView;
