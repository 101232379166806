// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import {
  GREEN_MAIN,
  BLUE_MAIN,
  RED_MAIN,
  TOP_MAIN,
  JNG_MAIN,
  MID_MAIN,
  BOT_MAIN,
  SUP_MAIN,
} from 'constants/color';

// Recharts
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

// Elements
import { useMediaQuery } from '@mui/material';
import ChartTooltip from 'components/molecules/ChartTooltip/ChartTooltip';

// Component
const ChartPie = ({ data, type, chartKey }) => {
  const colors = {
    win_rate: [GREEN_MAIN, RED_MAIN],
    roles: [TOP_MAIN, JNG_MAIN, MID_MAIN, BOT_MAIN, SUP_MAIN],
    lanes: [TOP_MAIN, MID_MAIN, BOT_MAIN],
    side: [BLUE_MAIN, RED_MAIN],
  };

  const newData = Object.entries(data).map(([name, value]) => ({
    name,
    value: chartKey ? value[chartKey] : value,
  }));

  const isDesktopViewport = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <PieChart>
        <Tooltip content={<ChartTooltip />} />
        <Pie
          data={newData}
          outerRadius={isDesktopViewport ? 65 : 60}
          startAngle={90}
          endAngle={-270}
          dataKey="value"
          isAnimationActive={false}
        >
          {newData?.map((item, index) => (
            <Cell key={item.name} fill={colors[type][index % colors[type].length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

ChartPie.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  chartKey: PropTypes.string,
};

ChartPie.defaultProps = {
  data: {},
  type: '',
  chartKey: '',
};

export default ChartPie;
