// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { RED_MAIN } from 'constants/color';

// Assets
import YouTubeIcon from '@mui/icons-material/YouTube';

// Elements
import { IconButton } from '@mui/material';

// Component
const VODData = ({ cell, value }) => {
  const { getValue } = cell;
  const href = value || (cell && getValue());

  if (!href) {
    return null;
  }

  const openVOD = (e) => {
    e.stopPropagation();

    window.open(href, '_blank');
  };

  return (
    <IconButton aria-label="vod" onClick={openVOD} size="small">
      <YouTubeIcon sx={{ fontSize: 20, color: RED_MAIN }} />
    </IconButton>
  );
};

VODData.propTypes = {
  cell: PropTypes.object,
  value: PropTypes.string,
};

VODData.defaultProps = {
  cell: {},
  value: '',
};

export default VODData;
