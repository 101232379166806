// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSinglePlayerBySlug } from 'services/players';
import { fetchPlayerGraphs } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Grid } from '@mui/material';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PlayerInfo from 'components/organisms/PlayerInfo/PlayerInfo';
import ChartArea from 'components/organisms/ChartArea/ChartArea';

const PlayerSingleGraphsView = () => {
  const {
    setTitleSEO,
    filters: {
      player: {
        matchTypeS,
        rangeDate,
        side,
        graphPlayerType,
        graphDataPlayerType,
        playerMainRole,
        patch,
        patches,
        opponent,
        opponents,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { playerSlug } = useParams();
  const [player, setPlayer] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);

  const fetchInfo = (playerValue) => {
    setIsLoading(true);

    fetchSinglePlayerBySlug(playerValue).then((res) => setPlayer(res));
  };

  const fetchData = (
    playerId,
    graphPlayerTypeData,
    graphDataPlayerTypeData,
    itemType,
    sideData,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
    playerMainRoleData,
  ) => {
    setIsLoading(true);

    fetchPlayerGraphs(
      playerId,
      graphPlayerTypeData,
      graphDataPlayerTypeData,
      itemType,
      sideData,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
      playerMainRoleData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        player: { ...prevValue.player, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(playerSlug);
    }

    renderAfterCalled.current = true;
  }, [playerSlug]);

  useEffect(() => {
    if (player) {
      fetchData(
        player.id,
        graphPlayerType,
        graphDataPlayerType,
        matchTypeS,
        side,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
        playerMainRole,
      );
    }
  }, [
    player,
    graphPlayerType,
    graphDataPlayerType,
    matchTypeS,
    rangeDate,
    side,
    patch,
    opponent,
    blitz,
    playerMainRole,
  ]);

  useEffect(() => {
    if (player) {
      setTitle(`${player.name}`);
    }
  }, [player]);

  useEffect(() => {
    setTitleSEO(`${title} Graphs`);
  }, [title]);

  return (
    <ViewWithSidebar
      component={
        player ? (
          <>
            <DataFilters
              matchTypeSFilter
              rangeDateFilter
              sideFilter
              graphPlayerTypeFilter
              graphDataPlayerTypeFilter
              patchesFilter={patches}
              opponentsFilter={opponents}
              blitzFilter
              playerMainRoleFilter
              type="player"
            />
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <PlayerInfo player={player} title={title} />
              </Grid>
              <Grid item xs={12} md={8} lg={9} xl={10}>
                <ChartArea data={Object.values(data)} chartKey="value" />
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )
      }
    />
  );
};

export default PlayerSingleGraphsView;
