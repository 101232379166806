// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Context
import { Context } from 'context';

// Routes
import { performanceReportBasicURL } from 'routes/app';

// Services
import { fetchPerformanceData } from 'services/performance';

// Constants
import { GREEN_A10 } from 'constants/color';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid, Chip } from '@mui/material';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PerformanceCard from 'components/molecules/PerformanceCard/PerformanceCard';

const PerformanceView = () => {
  const navigate = useNavigate();
  const {
    user,
    setTitleSEO,
    filters: {
      performance: { rangeDate },
    },
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);
  const title = 'Performance';

  const handleClick = () => {
    navigate(performanceReportBasicURL);
  };

  const fetchData = (startDateData, endDateData) => {
    setIsLoading(true);

    fetchPerformanceData(startDateData, endDateData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate]);

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <PageTitle title={title} disableGutterBottom />
        <Chip
          label="REPORT"
          size="small"
          onClick={handleClick}
          sx={{
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 700,
            ml: 2,
            pl: 0.5,
            pr: 0.5,
          }}
        />
      </Box>
      <DataFilters rangeDateFilter type="performance" />
      {data && Object.keys(data).length > 0 && Object.keys(data).length > 0 && (
        <Grid container>
          {Object.keys(data).map((day) => (
            <Grid key={day} item xs={12} sm={6} md={4} lg={3} xl={12 / 7}>
              <PerformanceCard
                id={data[day].id || null}
                title={day}
                background={data[day].free || data[day].game_day ? GREEN_A10 : null}
                data={data[day].data}
                freeDay={data[day].free}
                gameDay={data[day].game_day}
                sportDay={!data[day].free && data[day].sport_day}
                edit={user?.is_editor}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default PerformanceView;
