// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchCurrentUserTeamData = () =>
  axiosLoadingInstance
    .get('teams/current-user-team/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchTeams = () =>
  axiosLoadingInstance
    .get('teams/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchTeamsVisible = () =>
  axiosLoadingInstance
    .get('teams/?visible=true')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleTeam = (id) =>
  axiosLoadingInstance
    .get(`teams/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleTeamBySlug = (teamSlug) =>
  axiosLoadingInstance
    .get(`teams/${teamSlug}/?key=slug`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importAllRosters = () =>
  axiosInstance
    .get(`teams/import-rosters/`)
    .then((res) => ({ data: res.data, message: 'Rosters have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importAllImages = () =>
  axiosInstance
    .get(`teams/import-images/`)
    .then((res) => ({ data: res.data, message: 'Images have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importRosterForTeam = (id) =>
  axiosInstance
    .get(`teams/import-roster/${id}/`)
    .then((res) => ({ data: res.data, message: 'Roster has been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addTeam = (data) =>
  axiosInstance
    .post('teams/add/', data)
    .then((res) => ({ data: res.data, message: 'Team has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateTeam = (id, data) =>
  axiosInstance
    .put(`teams/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Team has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteTeam = (id) =>
  axiosInstance
    .delete(`teams/${id}/`)
    .then((res) => ({ data: res.data, message: 'Team has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
