// Axios
import { axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchDraftsLeaguepedia = async (side, teamId, startDate, endDate, draftFilter) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data-leaguepedia/drafts/`);

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.post(url, draftFilter);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchDraftsPatternsLeaguepedia = async (side, teamId, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data-leaguepedia/drafts/patterns/`);

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchChampionPoolLeaguepedia = async (teamId, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data-leaguepedia/champion-pool/`);

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchFlexLeaguepedia = async (side, teamId, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data-leaguepedia/flex/`);

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSynergiesLeaguepedia = async (side, synergy, teamId, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data-leaguepedia/synergies/`);

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (synergy !== undefined && synergy !== null) {
    url.searchParams.append('synergy', synergy);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPicksLeaguepedia = async (dataType, side, role, teamId, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data-leaguepedia/picks/`);

  if (dataType !== undefined && dataType !== null) {
    url.searchParams.append('data_type', dataType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchEnemyDataLeaguepedia = async (
  dataType,
  side,
  role,
  teamId,
  startDate,
  endDate,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data-leaguepedia/enemy-data/`);

  if (dataType !== undefined && dataType !== null) {
    url.searchParams.append('data_type', dataType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};
