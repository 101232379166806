// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchAccounts = () =>
  axiosLoadingInstance
    .get('accounts/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchAccountsAvailable = () =>
  axiosLoadingInstance
    .get('accounts/?available=true')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleAccount = (id) =>
  axiosLoadingInstance
    .get(`accounts/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fixTiersForAccounts = () =>
  axiosInstance
    .get(`accounts/fix-tiers/`)
    .then((res) => ({ data: res.data, message: 'Accounts have been fixed!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importAccountsFromOtherResources = () =>
  axiosInstance
    .get(`accounts/import-accounts/`)
    .then((res) => ({ data: res.data, message: 'Accounts have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addRankedGamesForAccount = (id) =>
  axiosInstance
    .get(`accounts/add-ranked-games/${id}/`)
    .then((res) => ({ data: res.data, message: 'Ranked games have been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const assignRankedGamesToAccount = (id) =>
  axiosInstance
    .get(`accounts/assign-ranked-games/${id}/`)
    .then((res) => ({ data: res.data, message: 'Ranked games have been assigned!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addAccount = (data) =>
  axiosInstance
    .post('accounts/add/', data)
    .then((res) => ({ data: res.data, message: 'Account has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateAccount = (id, data) =>
  axiosInstance
    .put(`accounts/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Account has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteAccount = (id) =>
  axiosInstance
    .delete(`accounts/${id}/`)
    .then((res) => ({ data: res.data, message: 'Account has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
