// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchServers = () =>
  axiosLoadingInstance
    .get('servers/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleServer = (id) =>
  axiosLoadingInstance
    .get(`servers/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addServer = (data) =>
  axiosInstance
    .post('servers/add/', data)
    .then((res) => ({ data: res.data, message: 'Server has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateServer = (id, data) =>
  axiosInstance
    .put(`servers/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Server has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteServer = (id) =>
  axiosInstance
    .delete(`servers/${id}/`)
    .then((res) => ({ data: res.data, message: 'Server has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
