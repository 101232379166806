// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import { Box, Grid, Typography } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';

// Component
const BasicManageData = ({ label, fields, value, handleChange }) => (
  <Box>
    <Typography variant="subtitle1">{label}</Typography>
    <Grid container>
      <Grid item xs={12} md={5}>
        {fields.map(({ name: fieldName, label: fieldLabel }) => (
          <TextField
            key={fieldName}
            name={`data.${fieldName}`}
            label={fieldLabel}
            value={(value && fieldName in value && value[fieldName]) || ''}
            onChange={handleChange}
            fullWidth
          />
        ))}
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField
          name="data.notes"
          label="Notes"
          value={value?.notes || ''}
          onChange={handleChange}
          rows={5}
          multiline
          fullWidth
        />
      </Grid>
    </Grid>
  </Box>
);

BasicManageData.propTypes = {
  label: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default BasicManageData;
