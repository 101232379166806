// React
import React, { useContext, useState } from 'react';

// Router
import { useNavigate, useLocation } from 'react-router-dom';

// Context
import { Context } from 'context';

// Helmet
import { Helmet } from 'react-helmet-async';

// Formik
import { Formik } from 'formik';

// Cookies
import { useCookies } from 'react-cookie';

// Notistack
import { useSnackbar } from 'notistack';

// Yup
import * as Yup from 'yup';

// Routes
import { homeURL, scrimsBasicURL } from 'routes/app';

// Services
import { checkMe } from 'services/users';
import { loginUser, registerUser } from 'services/guests';

// Constants
import { COOKIE_NAME, COOKIES } from 'constants/cookie';
import { SIGN_IN_TEXT, REGISTER_TEXT } from 'constants/text';
import { SUCCESS_SNACKBAR, ERROR_SNACKBAR } from 'constants/snackbar';

// Schemas
import { STRING_REQUIRED_SCHEMA } from 'schemas/base';
import { PASSWORD_SCHEMA } from 'schemas/users';

// Logo
import logo from 'assets/img/logo.svg';

// Elements
import { Grid, Box, Button, Typography } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';

const LoginView = () => {
  const { setToken, setUser } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const [cookie, setCookie] = useCookies([COOKIE_NAME]);
  const [isLoginPage, setIsLoginPage] = useState(true);

  const fields = [
    {
      id: 'username',
      label: 'Username',
    },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
    },
  ];

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    username: STRING_REQUIRED_SCHEMA,
    password: isLoginPage ? STRING_REQUIRED_SCHEMA : PASSWORD_SCHEMA,
  });

  const handleLogin = (values, snackbar) => {
    let variantSnackbar = snackbar;

    loginUser(values).then(({ data, error, message }) => {
      if (data) {
        const resToken = data?.access_token;

        setCookie(COOKIE_NAME, resToken, COOKIES);
        setToken(resToken);

        checkMe(resToken).then(({ data: resData }) => {
          if (resData) {
            if (
              resData?.teams_access &&
              resData?.teams_access.length > 0 &&
              location?.pathname === homeURL
            ) {
              navigate(scrimsBasicURL);
            }

            setUser(resData);
          }
        });
      }

      if (error) {
        variantSnackbar = ERROR_SNACKBAR;
      }

      enqueueSnackbar(message, {
        variant: variantSnackbar,
      });
    });
  };

  const handleRegister = (values, snackbar, resetFn) => {
    let variantSnackbar = snackbar;

    registerUser(values).then(({ data, error, message }) => {
      if (data) {
        setIsLoginPage(true);

        resetFn();
      }

      if (error) {
        variantSnackbar = ERROR_SNACKBAR;
      }

      enqueueSnackbar(message, {
        variant: variantSnackbar,
      });
    });
  };

  const handleChangePage = (resetFn) => {
    resetFn();

    setIsLoginPage(!isLoginPage);
  };

  return (
    <>
      <Helmet>
        <title>{isLoginPage ? SIGN_IN_TEXT : REGISTER_TEXT}</title>
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mb={3}
        >
          <img src={logo} alt="LoLScout" width={128} height={128} />
          <Typography variant="h6" component="div" mt={1 / 2}>
            LoLScout
          </Typography>
        </Box>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={8} sm={5} md={4} lg={3} xl={2}>
            <Typography variant="h5" component="h1" align="center">
              {isLoginPage ? SIGN_IN_TEXT : REGISTER_TEXT}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                const variantSnackbar = SUCCESS_SNACKBAR;
                const params = new URLSearchParams();

                Object.keys(values).map((el) => params.append(el, values[el]));

                if (isLoginPage) {
                  handleLogin(params, variantSnackbar);
                } else {
                  handleRegister(params, variantSnackbar, resetForm);
                }
              }}
            >
              {({ values, errors, touched, handleChange, handleReset, handleSubmit }) => (
                <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit}>
                  {fields.map(({ id, label, type }) => (
                    <TextField
                      key={id}
                      type={type}
                      name={id}
                      label={label}
                      value={values[id]}
                      onChange={handleChange}
                      error={touched[id] && !!errors[id]}
                      helperText={touched[id] && errors[id]}
                    />
                  ))}
                  <Box mt={1}>
                    <Grid container>
                      <Grid item xs={7}>
                        <Button
                          type="submit"
                          variant="contained"
                          color={isLoginPage ? 'blue' : 'red'}
                          fullWidth
                        >
                          {isLoginPage ? SIGN_IN_TEXT : REGISTER_TEXT}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button onClick={() => handleChangePage(handleReset)} fullWidth>
                          {isLoginPage ? REGISTER_TEXT : SIGN_IN_TEXT}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LoginView;
