// React
import React, { useContext } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Router
import { Link } from 'react-router-dom';

// Context
import { Context } from 'context';

// Assets
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyIcon from '@mui/icons-material/Key';

// Elements
import { Box, IconButton } from '@mui/material';

// Component
const RowActions = ({
  row,
  passwordBtn,
  passwordLink,
  viewBtn,
  viewLink,
  editBtn,
  deleteBtn,
  baseURL,
  setRowFn,
}) => {
  const { setIsDialogOpen } = useContext(Context);

  const handleDelete = () => {
    setIsDialogOpen(true);
    setRowFn(row);
  };

  return (
    <Box>
      {passwordBtn ? (
        <IconButton component={Link} to={passwordLink}>
          <KeyIcon fontSize="small" />
        </IconButton>
      ) : null}
      {viewBtn ? (
        <IconButton component={Link} to={viewLink}>
          <VisibilityIcon fontSize="small" />
        </IconButton>
      ) : null}
      {editBtn ? (
        <IconButton component={Link} to={`${baseURL}/${row.original.id}`}>
          <EditIcon fontSize="small" />
        </IconButton>
      ) : null}
      {deleteBtn ? (
        <IconButton onClick={handleDelete}>
          <DeleteIcon fontSize="small" color="error" />
        </IconButton>
      ) : null}
    </Box>
  );
};

RowActions.propTypes = {
  row: PropTypes.object,
  passwordBtn: PropTypes.bool,
  passwordLink: PropTypes.string,
  viewBtn: PropTypes.bool,
  viewLink: PropTypes.string,
  editBtn: PropTypes.bool,
  baseURL: PropTypes.string,
  deleteBtn: PropTypes.bool,
  setRowFn: PropTypes.func,
};

RowActions.defaultProps = {
  row: null,
  passwordBtn: false,
  passwordLink: null,
  viewBtn: false,
  viewLink: null,
  editBtn: true,
  baseURL: null,
  deleteBtn: true,
  setRowFn: null,
};

export default RowActions;
