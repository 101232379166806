// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchWardsObjectvies } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Grid } from '@mui/material';
import GameMap from 'components/molecules/GameMap/GameMap';

const TeamSingleWardsObjectivesView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        wardObjective,
        roleAll,
        wardType,
        teamObjective,
        patch,
        patches,
        opponent,
        opponents,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    objectiveData,
    roleData,
    wardTypeData,
    teamObjectiveData,
    teamId,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchWardsObjectvies(
      itemType,
      sideData,
      objectiveData,
      roleData,
      wardTypeData,
      teamObjectiveData,
      teamId,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
    ).then((res) => {
      setData(res.data);
      setCount(res.count);

      setFilters((prevValue) => ({
        ...prevValue,
        team: { ...prevValue.team, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        wardObjective,
        roleAll,
        wardType,
        teamObjective,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
      );
    }
  }, [
    matchType,
    rangeDate,
    side,
    wardObjective,
    roleAll,
    wardType,
    teamObjective,
    team,
    patch,
    opponent,
    blitz,
  ]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Wards Objectives`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            roleAllFilter
            wardTypeFilter
            wardObjectivesFilter
            teamObjectiveFilter
            patchesFilter={patches}
            opponentsFilter={opponents}
            blitzFilter
            type="team"
          />
          <Grid container>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <GameMap wards={data} count={count} />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default TeamSingleWardsObjectivesView;
