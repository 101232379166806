// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchDrafts } from 'services/data';

// Constants
import { MATCH_TYPES } from 'constants/match';

// Elements
import DraftsTemplate from 'components/templates/DraftsTemplate/DraftsTemplate';

const ScrimsDraftsView = () => {
  const {
    setTitleSEO,
    filters: {
      scrims: { rangeDate, side, result, patch, patches, opponent, opponents, blitz, draftFilter },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Scrims Drafts';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const fetchData = (
    itemType,
    sideData,
    resultData,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
    draftFilterData,
  ) => {
    setIsLoading(true);

    fetchDrafts(
      itemType,
      sideData,
      resultData,
      null,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
      draftFilterData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        scrims: { ...prevValue.scrims, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(
        MATCH_TYPES.SCRIM,
        side,
        result,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
        draftFilter,
      );
    }
  }, [rangeDate, side, result, patch, opponent, blitz, draftFilter]);

  return <DraftsTemplate data={data} title={title} patches={patches} opponents={opponents} />;
};

export default ScrimsDraftsView;
