// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchRegions = () =>
  axiosLoadingInstance
    .get('regions/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleRegion = (id) =>
  axiosLoadingInstance
    .get(`regions/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addRegion = (data) =>
  axiosInstance
    .post('regions/add/', data)
    .then((res) => ({ data: res.data, message: 'Region has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateRegion = (id, data) =>
  axiosInstance
    .put(`regions/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Region has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteRegion = (id) =>
  axiosInstance
    .delete(`regions/${id}/`)
    .then((res) => ({ data: res.data, message: 'Region has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
