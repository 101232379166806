// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminTeamsURL } from 'routes/admin';

// Services
import { fetchSplits } from 'services/splits';
import { addTeam } from 'services/teams';
import { fetchPlayersByRole } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminTeamsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [splitOptions, setSplitOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState({
    ALL: [],
    TOP: [],
    JNG: [],
    MID: [],
    BOT: [],
    SUP: [],
  });
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSplits().then((res) => setSplitOptions(res));

    fetchPlayersByRole().then((res) => setPlayerOptions(res));

    setIsLoading(false);
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Teams ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    short: '',
    provider_id: '',
    splits: [],
    leaguepedia: '',
    image_url: '',
    get_data: false,
    player_top: null,
    player_jng: null,
    player_mid: null,
    player_bot: null,
    player_sup: null,
    sub_top: [],
    sub_jng: [],
    sub_mid: [],
    sub_bot: [],
    sub_sup: [],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    short: Yup.string().required('Required'),
    provider_id: Yup.string(),
    leaguepedia: Yup.string().required('Required'),
    image_url: Yup.string().url(),
    get_data: Yup.boolean(),
    player_top: Yup.object().nullable(),
    player_jng: Yup.object().nullable(),
    player_mid: Yup.object().nullable(),
    player_bot: Yup.object().nullable(),
    player_sup: Yup.object().nullable(),
    sub_top: Yup.array().nullable(),
    sub_jng: Yup.array().nullable(),
    sub_mid: Yup.array().nullable(),
    sub_bot: Yup.array().nullable(),
    sub_sup: Yup.array().nullable(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'short',
      label: 'Short',
      typeField: 'textfield',
    },
    {
      id: 'provider_id',
      label: 'Provider ID',
      typeField: 'textfield',
    },
  ];
  const fields2 = [
    {
      id: 'splits',
      label: 'Splits',
      typeField: 'multiautocomplete',
      options: splitOptions,
    },
    {
      id: 'leaguepedia',
      label: 'Leaguepedia',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
    {
      id: 'get_data',
      label: 'Get data',
      typeField: 'checkbox',
    },
  ];
  const fields3 = [
    {
      id: 'player_top',
      label: 'TOP',
      typeField: 'autocomplete',
      options: playerOptions.TOP,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'player_jng',
      label: 'JNG',
      typeField: 'autocomplete',
      options: playerOptions.JNG,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'player_mid',
      label: 'MID',
      typeField: 'autocomplete',
      options: playerOptions.MID,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'player_bot',
      label: 'BOT',
      typeField: 'autocomplete',
      options: playerOptions.BOT,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'player_sup',
      label: 'SUP',
      typeField: 'autocomplete',
      options: playerOptions.SUP,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
  ];
  const fields4 = [
    {
      id: 'sub_top',
      label: 'SUBS TOP',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'sub_jng',
      label: 'SUBS JNG',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'sub_mid',
      label: 'SUBS MID',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'sub_bot',
      label: 'SUBS BOT',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
    {
      id: 'sub_sup',
      label: 'SUBS SUP',
      typeField: 'multiautocomplete',
      options: playerOptions.ALL,
      dependsDisplayField: 'get_data',
      dependsDisplayValue: false,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFields4={fields4}
      formikFn={addTeam}
      formikRedirect={adminTeamsURL}
      formikBtnText="Add team"
    />
  );
};

export default AdminTeamsAddView;
