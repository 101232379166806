// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box } from '@mui/material';
import PlayerMatches from 'components/molecules/PlayerMatches/PlayerMatches';

// Component
const TeamPlayersMatches = ({ data }) => (
  <Box
    display="grid"
    gridTemplateColumns={{
      xs: '1fr',
      sm: 'repeat(2, 1fr)',
      lg: 'repeat(3, 1fr)',
      xl: 'repeat(5, 1fr)',
    }}
    gap={1}
    mt={1}
  >
    {Object.entries(data).map(([key, value]) => (
      <PlayerMatches key={key} title={`${key} (${value.count} games)`} data={value.data} />
    ))}
  </Box>
);

TeamPlayersMatches.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TeamPlayersMatches;
