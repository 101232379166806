// Axios
import { axiosInstance } from 'utils/axios';

export const searchVods = (data) =>
  axiosInstance
    .post('leaguepedia/search/vods/', data)
    .then((res) => ({ data: res.data, message: 'VODs have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const searchCounters = (data) =>
  axiosInstance
    .post('leaguepedia/search/counters/', data)
    .then((res) => ({ data: res.data, message: 'Counters have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const searchChampionsData = (data) =>
  axiosInstance
    .post('leaguepedia/champions-data/', data)
    .then((res) => ({ data: res.data, message: 'Champions data have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const searchWorldsMatchups = (data) =>
  axiosInstance
    .post('leaguepedia/worlds-matchups/', data)
    .then((res) => ({ data: res.data, message: 'Worlds matchups have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
