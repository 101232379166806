// Yup
import * as Yup from 'yup';

// Constants
import { REQUIRED_TEXT } from 'constants/text';

export const STRING_SCHEMA = Yup.string();
export const STRING_REQUIRED_SCHEMA = Yup.string().required(REQUIRED_TEXT);

export const OBJECT_NULLABLE_SCHEMA = Yup.object().nullable();
export const OBJECT_REQUIRED_SCHEMA = Yup.object().required(REQUIRED_TEXT);

export const BOOLEAN_SCHEMA = Yup.boolean();
