// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminPatchesURL } from 'routes/admin';

// Services
import { fetchPatchesGame, fetchSinglePatch, updatePatch } from 'services/patches';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminPatchesEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSinglePatch(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.name} - Patches ${ADMIN_TITLE_POSTFIX}`);

      fetchPatchesGame().then((res) => {
        const newOptions = res.filter((item) => {
          const newItem = item.name.split('.');
          const shortName = newItem.slice(0, 2).join('.');

          if (shortName === data.name) {
            return true;
          }

          return false;
        });

        setOptions(newOptions);
      });
    }
  }, [data]);

  const initialValues = {
    id: data?.id || '',
    name: data?.name || '',
    patches: (options.length > 0 && data?.patches) || [],
  };
  const validationSchema = Yup.object().shape({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
    patches: Yup.array(),
  });
  const fields = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'patches',
      label: 'Patches',
      typeField: 'multiautocomplete',
      options,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields}
      formikFn={updatePatch}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminPatchesURL}
      formikBtnText="Update patch"
    />
  );
};

export default AdminPatchesEditView;
