// React
import React from 'react';

// React Router
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { TOOLTIP_SLOTS } from 'constants/tooltip';
import { FREE_DAY_TEXT, GAME_DAY_TEXT, SPORT_DAY_TEXT } from 'constants/text';

// Assets
import SettingsIcon from '@mui/icons-material/Settings';

// Elements
import { Typography, Grid, Box, Card, CardContent, Chip, IconButton, Tooltip } from '@mui/material';
import BooleanData from 'components/atoms/BooleanData/BooleanData';
import MealData from 'components/atoms/MealData/MealData';

const PerformanceCard = ({ id, title, data, background, freeDay, gameDay, sportDay, edit }) => {
  const renderChip = (label, color) => (
    <Chip label={label} color={color} size="small" sx={{ fontSize: 12, mr: 1 / 2 }} />
  );
  const fontSizeSubText = 12;

  return (
    <Card
      style={{
        backgroundColor: background,
        height: '100%',
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          minHeight={30}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body2" component="p" color="text.secondary" mr={1}>
              {title}
            </Typography>
            {freeDay ? renderChip(FREE_DAY_TEXT, 'info') : null}
            {gameDay ? renderChip(GAME_DAY_TEXT, 'warning') : null}
            {sportDay ? renderChip(SPORT_DAY_TEXT, 'success') : null}
          </Box>
          {!freeDay && edit ? (
            <Link
              to={id ? `/performance/edit/${id}` : '/performance/add'}
              state={{ date: !id && title }}
            >
              <IconButton aria-label="Manage" size="small">
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Link>
          ) : null}
        </Box>
        {data && Object.keys(data).length > 0 && !freeDay ? (
          <Grid container>
            <Grid item xs={4} />
            <Grid item xs={2}>
              <Tooltip title={data?.sport} slotProps={TOOLTIP_SLOTS}>
                <Typography
                  variant="body2"
                  component="p"
                  color="text.secondary"
                  textAlign="center"
                  fontSize={fontSizeSubText}
                >
                  Sport
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={data?.lunch} slotProps={TOOLTIP_SLOTS}>
                <Typography
                  variant="body2"
                  component="p"
                  color="text.secondary"
                  textAlign="center"
                  fontSize={fontSizeSubText}
                >
                  Lunch
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={data?.snack} slotProps={TOOLTIP_SLOTS}>
                <Typography
                  variant="body2"
                  component="p"
                  color="text.secondary"
                  textAlign="center"
                  fontSize={fontSizeSubText}
                >
                  Snack
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={data?.dinner} slotProps={TOOLTIP_SLOTS}>
                <Typography
                  variant="body2"
                  component="p"
                  color="text.secondary"
                  textAlign="center"
                  fontSize={fontSizeSubText}
                >
                  Dinner
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}
        {data && Object.keys(data).length > 0 && !freeDay
          ? Object.keys(data.players).map((player) => {
              const playerData = data.players[player];

              return (
                <Grid key={player} container>
                  <Grid item xs={4}>
                    <Typography variant="body2" component="p" mr={1}>
                      {player}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <BooleanData value={playerData.sport} />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <MealData value={playerData.lunch} info={playerData.lunch_info} />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <MealData value={playerData.snack} info={playerData.snack_info} />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <MealData value={playerData.dinner} info={playerData.dinner_info} />
                    </Box>
                  </Grid>
                </Grid>
              );
            })
          : null}
      </CardContent>
    </Card>
  );
};

PerformanceCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  data: PropTypes.object,
  background: PropTypes.string,
  freeDay: PropTypes.bool,
  gameDay: PropTypes.bool,
  sportDay: PropTypes.bool,
  edit: PropTypes.bool,
};

PerformanceCard.defaultProps = {
  id: null,
  title: '',
  data: {},
  background: null,
  freeDay: false,
  gameDay: false,
  sportDay: false,
  edit: false,
};

export default PerformanceCard;
