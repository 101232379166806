// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Recharts
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Component
const ChartScatter = ({ data }) => (
  <ResponsiveContainer width="100%" aspect={3}>
    <ScatterChart>
      <XAxis type="number" dataKey="x" name="GD" />
      <YAxis type="number" dataKey="y" name="WR" unit="%" />
      <Tooltip />
      <Scatter data={data} fill="#ffffff" />
    </ScatterChart>
  </ResponsiveContainer>
);

ChartScatter.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ChartScatter;
