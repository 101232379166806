// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const checkMe = (token) =>
  axiosInstance
    .get('users/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => ({ data: res.data, error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchUsers = () =>
  axiosLoadingInstance
    .get('users/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleUser = (id) =>
  axiosLoadingInstance
    .get(`users/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addUser = (data) =>
  axiosInstance
    .post('users/', data)
    .then((res) => ({ data: res.data, message: 'User has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateUser = (id, data) =>
  axiosInstance
    .put(`users/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'User has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const changePasswordUser = (id, data) =>
  axiosInstance
    .put(`users/password/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'User password has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateUserCurrentTeam = (teamId) =>
  axiosInstance
    .put(`users/current-team/${teamId}/`)
    .then((res) => ({ data: res.data, message: 'User current team has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteUser = (id) =>
  axiosInstance
    .delete(`users/${id}/`)
    .then((res) => ({ data: res.data, message: 'User has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
