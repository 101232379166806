// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchSummoners } from 'services/data';

// Constants
import { MATCHES_TABLE } from 'constants/mrt';
import { TEAM_MATCHES_SUMMONER_COLS, TEAM_GAMES_SUMMONER_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';

const TeamSingleSummonersView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        role,
        summoner,
        patch,
        patches,
        opponent,
        opponents,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    roleData,
    summonerType,
    teamId,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchSummoners(
      itemType,
      sideData,
      roleData,
      summonerType,
      teamId,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        team: { ...prevValue.team, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        role,
        summoner,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
      );
    }
  }, [matchType, rangeDate, side, role, summoner, team, patch, opponent, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Summoners`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  const columns = useMemo(() => TEAM_MATCHES_SUMMONER_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...MATCHES_TABLE(TEAM_GAMES_SUMMONER_COLS(summoner), true),
  });

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            patchesFilter={patches}
            opponentsFilter={opponents}
            roleFilter
            summonersFilter
            blitzFilter
            type="team"
          />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default TeamSingleSummonersView;
