// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminServersURL } from 'routes/admin';

// Services
import { fetchRegions } from 'services/regions';
import { fetchSingleServer, updateServer } from 'services/servers';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminServersEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleServer(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });

    fetchRegions().then((res) => setOptions(res));
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.name} - Servers ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    name: data?.name || '',
    region: (options.length > 0 && data?.region) || null,
    platform: data?.platform || '',
    ladder: data?.ladder || false,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    region: Yup.object().typeError('Choose region!').required('Required'),
    platform: Yup.string().required('Required'),
    ladder: Yup.boolean(),
  });
  const fields = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'region',
      label: 'Region',
      typeField: 'autocomplete',
      options,
    },
    {
      id: 'platform',
      label: 'Platform',
      typeField: 'textfield',
    },
    {
      id: 'ladder',
      label: 'Ladder',
      typeField: 'checkbox',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields}
      formikFn={updateServer}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminServersURL}
      formikBtnText="Update server"
    />
  );
};

export default AdminServersEditView;
