// React
import React, { useState, useEffect, useRef, useContext } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Services
import { fetchChampions } from 'services/champions';

// Constants
import { ROLES } from 'constants/role';
import { ALL_DRAFT_ORDER } from 'constants/order';
import { DRAFT_TEAM_TYPE, DRAFT_SIDE_TYPE } from 'constants/team';

// Elements
import { Box, Grid, Button, Modal, Backdrop } from '@mui/material';
import Select from 'components/atoms/Select/Select';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';

// Component
const DraftFilter = ({ type }) => {
  const { filters, setFilters, setIsLoading } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [champions, setChampions] = useState([]);
  const [role, setRole] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = async () => {
    setIsLoading(true);

    const res = await fetchChampions();
    const championsData = [
      {
        id: 'ANY',
        name: 'ANY',
        value: 'ANY',
      },
      ...res,
    ];

    setChampions(championsData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();

      const roleData = [
        {
          id: 'ANY',
          label: 'ANY',
          value: 'ANY',
        },
        ...ROLES,
      ];

      setRole(roleData);
    }

    renderAfterCalled.current = true;
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {};

  const validationSchema = Yup.object().shape({
    champion_1: Yup.object().required('Required'),
    champion_1_team: Yup.string().required('Required'),
    champion_1_side: Yup.string().required('Required'),
    champion_1_role: Yup.string().required('Required'),
    champion_1_order: Yup.string().required('Required'),
  });

  for (let i = 1; i <= 5; i += 1) {
    const draftChampionKey = `champion_${i}`;
    const draftChampionTeamKey = `champion_${i}_team`;
    const draftChampionSideKey = `champion_${i}_side`;
    const draftChampionRoleKey = `champion_${i}_role`;
    const draftChampionOrderKey = `champion_${i}_order`;

    initialValues[draftChampionKey] = filters[type]?.draftFilter?.[draftChampionKey] || {
      id: 'ANY',
      name: 'ANY',
      value: 'ANY',
    };
    initialValues[draftChampionTeamKey] =
      filters[type]?.draftFilter?.[draftChampionTeamKey] || 'ANY';
    initialValues[draftChampionSideKey] =
      filters[type]?.draftFilter?.[draftChampionSideKey] || 'BOTH';
    initialValues[draftChampionRoleKey] =
      filters[type]?.draftFilter?.[draftChampionRoleKey] || 'ANY';
    initialValues[draftChampionOrderKey] = filters[type]?.draftFilter?.[draftChampionOrderKey] || 0;

    if (i > 1) {
      validationSchema[draftChampionKey] = Yup.object();
      validationSchema[draftChampionTeamKey] = Yup.string();
      validationSchema[draftChampionSideKey] = Yup.string();
      validationSchema[draftChampionRoleKey] = Yup.string();
      validationSchema[draftChampionOrderKey] = Yup.string();
    }
  }

  const handleSubmitCustom = (values) => {
    setFilters((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        draftFilter: values,
      },
    }));

    handleClose();
  };

  const handleResetCustom = (resetForm) => {
    const newValues = {};

    for (let i = 1; i <= 5; i += 1) {
      const draftChampionKey = `champion_${i}`;
      const draftChampionTeamKey = `champion_${i}_team`;
      const draftChampionSideKey = `champion_${i}_side`;
      const draftChampionRoleKey = `champion_${i}_role`;
      const draftChampionOrderKey = `champion_${i}_order`;

      newValues[draftChampionKey] = {
        id: 'ANY',
        name: 'ANY',
        value: 'ANY',
      };

      newValues[draftChampionTeamKey] = 'ANY';
      newValues[draftChampionSideKey] = 'BOTH';
      newValues[draftChampionRoleKey] = 'ANY';
      newValues[draftChampionOrderKey] = 0;
    }

    resetForm({ values: newValues });
  };

  return (
    <>
      <Box alignSelf="center">
        <Button variant="contained" color="primary" size="small" onClick={handleOpen}>
          Draft
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '60%',
            bgcolor: 'background.paper',
            p: 4,
          }}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmitCustom}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              resetForm,
            }) => (
              <Box component="form" onSubmit={handleSubmit}>
                <Grid container>
                  {Array.from({ length: 5 }, (_, i) => i + 1).map((i) => {
                    const draftChampionKey = `champion_${i}`;
                    const draftChampionTeamKey = `champion_${i}_team`;
                    const draftChampionSideKey = `champion_${i}_side`;
                    const draftChampionRoleKey = `champion_${i}_role`;
                    const draftChampionOrderKey = `champion_${i}_order`;

                    return (
                      <React.Fragment key={i}>
                        <Grid item xs={12} md={12 / 5}>
                          <Autocomplete
                            id={draftChampionKey}
                            label={`Champion ${i}`}
                            options={champions}
                            onChange={(_, newValue) => {
                              setFieldValue(draftChampionKey, newValue);
                            }}
                            disabled={champions.length === 0}
                            error={touched[draftChampionKey] && !!errors[draftChampionKey]}
                            helperText={touched[draftChampionKey] && errors[draftChampionKey]}
                            value={values[draftChampionKey]}
                          />
                        </Grid>
                        <Grid item xs={12} md={12 / 5}>
                          <Select
                            disabled={DRAFT_TEAM_TYPE.length === 0}
                            error={touched[draftChampionTeamKey] && !!errors[draftChampionTeamKey]}
                            helperText={
                              touched[draftChampionTeamKey] && errors[draftChampionTeamKey]
                            }
                            name={draftChampionTeamKey}
                            label="Team"
                            options={DRAFT_TEAM_TYPE}
                            displayEmpty={false}
                            value={values[draftChampionTeamKey]}
                            compareValue="value"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={12 / 5}>
                          <Select
                            disabled={DRAFT_SIDE_TYPE.length === 0}
                            error={touched[draftChampionSideKey] && !!errors[draftChampionSideKey]}
                            helperText={
                              touched[draftChampionSideKey] && errors[draftChampionSideKey]
                            }
                            name={draftChampionSideKey}
                            label="Side"
                            options={DRAFT_SIDE_TYPE}
                            displayEmpty={false}
                            value={values[draftChampionSideKey]}
                            compareValue="value"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={12 / 5}>
                          <Select
                            disabled={role.length === 0}
                            error={touched[draftChampionRoleKey] && !!errors[draftChampionRoleKey]}
                            helperText={
                              touched[draftChampionRoleKey] && errors[draftChampionRoleKey]
                            }
                            name={draftChampionRoleKey}
                            label="Role"
                            optionLabel="label"
                            options={role}
                            displayEmpty={false}
                            value={values[draftChampionRoleKey]}
                            compareValue="value"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={12 / 5}>
                          <Select
                            disabled={role.length === 0}
                            error={
                              touched[draftChampionOrderKey] && !!errors[draftChampionOrderKey]
                            }
                            helperText={
                              touched[draftChampionOrderKey] && errors[draftChampionOrderKey]
                            }
                            name={draftChampionOrderKey}
                            label="Order"
                            options={ALL_DRAFT_ORDER}
                            displayEmpty={false}
                            value={values[draftChampionOrderKey]}
                            compareValue="value"
                            onChange={handleChange}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                  <Button
                    variant="contained"
                    type="button"
                    color="error"
                    onClick={() => handleResetCustom(resetForm)}
                  >
                    Reset
                  </Button>
                  <Button variant="contained" type="submit">
                    Show
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

DraftFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default DraftFilter;
