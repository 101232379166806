// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminChampionsURL } from 'routes/admin';

// Services
import { addChampion } from 'services/champions';
import { fetchTags } from 'services/tags';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminChampionsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [options, setOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchTags().then((res) => {
      setOptions(res);
    });
  };

  useEffect(() => {
    setIsLoading(false);

    if (!renderAfterCalled.current) {
      fetchData();

      setTitleSEO(`Add - Champions ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    id: '',
    name: '',
    api_name: '',
    image_url: '',
    tags: [],
  };
  const validationSchema = Yup.object().shape({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
    api_name: Yup.string().required('Required'),
    image_url: Yup.string().url().required('Required'),
  });
  const fields = [
    {
      id: 'id',
      label: 'ID',
      typeField: 'textfield',
    },
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'api_name',
      label: 'API Name',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
    {
      id: 'tags',
      label: 'Tags',
      typeField: 'multiautocomplete',
      options,
      optionLabel: 'name',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields}
      formikFn={addChampion}
      formikRedirect={adminChampionsURL}
      formikBtnText="Add champion"
    />
  );
};

export default AdminChampionsAddView;
