// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchItems = () =>
  axiosLoadingInstance
    .get('items/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleItem = (id) =>
  axiosLoadingInstance
    .get(`items/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importItems = () =>
  axiosInstance
    .get('items/import/')
    .then((res) => ({ data: res.data, message: 'Items have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addItem = (data) =>
  axiosInstance
    .post('items/add/', data)
    .then((res) => ({ data: res.data, message: 'Item has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateItem = (id, data) =>
  axiosInstance
    .put(`items/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Item has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteItem = (id) =>
  axiosInstance
    .delete(`items/${id}/`)
    .then((res) => ({ data: res.data, message: 'Item has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
