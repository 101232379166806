// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { GREEN_A50, RED_A50 } from 'constants/color';

// Elements
import { Box } from '@mui/material';

// Elements
const ResultBox = ({ win }) => (
  <Box
    backgroundColor={win ? GREEN_A50 : RED_A50}
    width={20}
    height={20}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {win ? 'W' : 'L'}
  </Box>
);

ResultBox.propTypes = {
  win: PropTypes.bool.isRequired,
};

export default ResultBox;
