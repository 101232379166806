// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import { Grid, Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';

// Component
const GameDataInfo = ({ gold, items, events, proximity }) => {
  const fontSize = 12;

  const renderGold = () => {
    if (gold) {
      return (
        <Typography variant="body2" component="p">
          Gold: {gold}
        </Typography>
      );
    }

    return null;
  };

  const renderItems = () => {
    if (items && items.length > 0) {
      return (
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={0.5} mt={1}>
          {items.map(({ id: itemId, name: itemName, image: itemImage, sell: itemSell }) => (
            <ImageItem
              key={itemId}
              src={itemImage}
              alt={itemName}
              ban={itemSell}
              width={28}
              autoHeight
            />
          ))}
        </Box>
      );
    }

    return null;
  };

  const renderProximity = () => {
    if (proximity && Object.keys(proximity).length > 0) {
      return (
        <Box mt={4}>
          <Typography variant="body2" component="p">
            Proximity
          </Typography>
          <Box mt={1}>
            {Object.keys(proximity).map((key) => (
              <Box key={key} display="flex" alignItems="center">
                <Typography variant="body2" component="p" mr={1} sx={{ fontSize }}>
                  {key}: {proximity[key].value}%
                </Typography>
                <GameDiffData value={proximity[key].diff} small fixNumbers postFix="%" />
              </Box>
            ))}
          </Box>
        </Box>
      );
    }

    return null;
  };

  const renderEvents = () => {
    if (events && events.length > 0) {
      return (
        <Box mt={4}>
          {events.map(
            ({
              id: eventId,
              time: eventTime,
              event: eventName,
              name: eventVictimChampionName,
              image: eventVictimChampionImage,
            }) => (
              <Grid key={eventId} container alignItems="center">
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2" component="p" sx={{ fontSize }}>
                      {secondsToHms(eventTime)}
                    </Typography>
                    <Typography variant="body2" component="p" sx={{ fontSize }}>
                      {eventName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <ImageItem
                    src={eventVictimChampionImage}
                    alt={eventVictimChampionName}
                    width={28}
                    autoHeight
                  />
                </Grid>
              </Grid>
            ),
          )}
        </Box>
      );
    }

    return null;
  };

  return (
    <Box>
      {renderGold()}
      {renderItems()}
      {renderProximity()}
      {renderEvents()}
    </Box>
  );
};

GameDataInfo.propTypes = {
  gold: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  proximity: PropTypes.object,
};

GameDataInfo.defaultProps = {
  gold: null,
  items: null,
  events: null,
  proximity: null,
};

export default GameDataInfo;
