// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { RED_MAIN, WHITE_MAIN } from 'constants/color';

// Assets
import CircleIcon from '@mui/icons-material/Circle';

// Component
const IsLiveData = ({ cell }) => {
  const { getValue } = cell;
  const isLiveData = getValue() === true;
  const color = isLiveData ? RED_MAIN : WHITE_MAIN;

  return <CircleIcon fontSize="small" sx={{ color }} />;
};

IsLiveData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default IsLiveData;
