// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchSummonerSpells = () =>
  axiosLoadingInstance
    .get('summoner-spells/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importSummonerSpells = () =>
  axiosInstance
    .get('summoner-spells/import/')
    .then((res) => ({ data: res.data, message: 'Summoner spells have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteSummonerSpell = (id) =>
  axiosInstance
    .delete(`summoner-spells/${id}/`)
    .then((res) => ({ data: res.data, message: 'Summoner spell has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
