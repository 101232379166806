// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Assets
import InfoIcon from '@mui/icons-material/Info';

// Elements
import { Tooltip } from '@mui/material';

// Component
const InfoTooltip = ({ data, bigIcon }) => (
  <Tooltip title={data}>
    <InfoIcon color="info" sx={{ fontSize: bigIcon ? 18 : 14 }} />
  </Tooltip>
);

InfoTooltip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bigIcon: PropTypes.bool,
};

InfoTooltip.defaultProps = {
  bigIcon: false,
};

export default InfoTooltip;
