// Calculate gradient offset for area chart
export const gradientOffset = (data, key) => {
  const dataMax = Math.max(...data.map((i) => i[key]));
  const dataMin = Math.min(...data.map((i) => i[key]));

  if (dataMax <= 0) {
    return 0;
  }
  if (dataMin >= 0) {
    return 1;
  }

  return dataMax / (dataMax - dataMin);
};
