// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Component
const YAxisPositionMap = ({ x, y, payload }) => {
  let axisValue = payload.value;

  if (axisValue === 0) {
    axisValue = 'BOT';
  } else if (axisValue === 0.5) {
    axisValue = 'MID';
  } else if (axisValue === 1) {
    axisValue = 'TOP';
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="end" fill="#666">
        {axisValue}
      </text>
    </g>
  );
};

YAxisPositionMap.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

YAxisPositionMap.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};

export default YAxisPositionMap;
