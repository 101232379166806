// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const BansData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue() || getValue().length === 0) {
    return null;
  }

  return (
    <Box display="flex" gap={0.5} flexWrap="wrap">
      {getValue().map(({ id, name, image_url, count }) => (
        <Box key={id} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2" component="span">
            {count}
          </Typography>
          <ImageItem src={image_url} alt={name} />
        </Box>
      ))}
    </Box>
  );
};

BansData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default BansData;
