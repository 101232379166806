export const PLATES = 'PLATES';
export const PLATES_PER_ROLE = 'PLATES PER ROLE';
export const HERALDS = 'HERALDS';
export const DRAGONS = 'DRAGONS';
export const BARONS = 'BARONS';
export const TOWERS = 'TOWERS';

export const DRAGON_1 = '1ST DRAGON';
export const DRAGON_2 = '2ND DRAGON';
export const DRAGON_3 = '3RD DRAGON';
export const DRAGON_4 = '4TH DRAGON';
export const HERALD_1 = '1ST HERALD';
export const HERALD_2 = '2ND HERALD';
export const BARON_1 = '1ST BARON';
export const BARON_2 = '2ND BARON';
