// React
import React, { useState } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { WHITE_MAIN, YELLOW_MAIN } from 'constants/color';

// Utils
import { replaceWithBr } from 'utils/text';

// Assets
import TimerIcon from '@mui/icons-material/Timer';

// Elements
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const Matchups = ({ data }) => {
  const [value, setValue] = useState('0');

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {data.map(
            (
              {
                champion_1: { name: champion1Name, image_url: champion1Image },
                champion_2,
                enemy_champion_1: { name: enemyChampion1Name, image_url: enemyChampion1Image },
                enemy_champion_2,
              },
              matchupIndex,
            ) => (
              <Tab
                key={`${champion1Name}-${enemyChampion1Name}`}
                value={matchupIndex.toString()}
                label={
                  <Box display="flex" alignItems="center" px={2}>
                    <Box display="flex" flexDirection="column">
                      <ImageItem src={champion1Image} alt={champion1Name} />
                      {champion_2 && champion_2.name && champion_2.image_url && (
                        <ImageItem src={champion_2.image_url} alt={champion_2.name} />
                      )}
                    </Box>
                    <Typography variant="body2" component="span" color={WHITE_MAIN} mx={1}>
                      vs.
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      <ImageItem src={enemyChampion1Image} alt={enemyChampion1Name} />
                      {enemy_champion_2 && enemy_champion_2.name && enemy_champion_2.image_url && (
                        <ImageItem src={enemy_champion_2.image_url} alt={enemy_champion_2.name} />
                      )}
                    </Box>
                  </Box>
                }
              />
            ),
          )}
        </TabList>
        {data.map(
          (
            {
              champion_1: { name: champion1Name },
              enemy_champion_1: { name: enemyChampion1Name },
              enemy_1: { name: enemy1Name },
              enemy_2,
              duration,
              notes,
            },
            matchupIndex,
          ) => (
            <TabPanel
              key={`${champion1Name}-${enemy1Name}-${enemyChampion1Name}-${duration}`}
              value={matchupIndex.toString()}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h5" textTransform="uppercase">
                  {enemy1Name}
                  {enemy_2 && enemy_2.name && ` & ${enemy_2.name}`}
                </Typography>
                <Box display="flex" alignItems="center" ml={2}>
                  <TimerIcon fontSize="small" sx={{ color: YELLOW_MAIN }} />
                  <Typography variant="body1" ml={1 / 2}>
                    {duration}
                  </Typography>
                </Box>
              </Box>
              <Box mt={3}>
                <Typography variant="h6">Notes:</Typography>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: replaceWithBr(notes) }}
                />
              </Box>
            </TabPanel>
          ),
        )}
      </TabContext>
    </Box>
  );
};

Matchups.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Matchups;
