export const homeURL = '/';

export const scrimsMatchesURL = '/scrims/matches';
export const scrimsDraftsURL = '/scrims/drafts';
export const scrimsChampionPoolURL = '/scrims/champion-pool';
export const scrimsPerformanceURL = '/scrims/performance';
export const scrimsBasicURL = '/scrims';

export const dailyReportBasicURL = '/daily/report';
export const dailyAddURL = '/daily/add/:name';
export const dailyEditURL = '/daily/edit/:id/:name';

export const soloQLpByTeamReportBasicURL = '/soloq/report/lp';
export const soloQMatchesReportBasicURL = '/soloq/report/matches';
export const soloQChampionPoolReportBasicURL = '/soloq/report/champion-pool';
export const soloQHoursReportBasicURL = '/soloq/report/hours';
export const soloQCountReportBasicURL = '/soloq/report';

export const splitsSingleMatchesURL = '/split/:leagueSlug/:splitSlug/matches';
export const splitsSingleChampionPoolURL = '/split/:leagueSlug/:splitSlug/champion-pool';
export const splitsSingleSynergiesURL = '/split/:leagueSlug/:splitSlug/synergies';
export const splitsSinglePicksURL = '/split/:leagueSlug/:splitSlug/picks';
export const splitsSingleTeamsStatisticsURL = '/split/:leagueSlug/:splitSlug/teams-statistics';
export const splitsSinglePlayersStatisticsURL = '/split/:leagueSlug/:splitSlug/players-statistics';
export const splitsSingleWinRateURL = '/split/:leagueSlug/:splitSlug/win-rate';
export const splitsSingleSoloQURL = '/split/:leagueSlug/:splitSlug/soloq';
export const splitsSingleLpGainURL = '/split/:leagueSlug/:splitSlug/lp-gain';
export const splitsSingleURL = '/split/:leagueSlug/:splitSlug';
export const splitsSingleBasicURL = '/split';
export const splitsBasicURL = '/splits';

export const teamsSingleMatchesURL = '/team/:teamSlug/matches';
export const teamsSingleDraftsPatternsLeaguepediaURL =
  '/team/:teamSlug/drafts/patterns/leaguepedia';
export const teamsSingleDraftsPatternsURL = '/team/:teamSlug/drafts/patterns';
export const teamsSingleDraftsLeagupediaURL = '/team/:teamSlug/drafts/leaguepedia';
export const teamsSingleDraftsURL = '/team/:teamSlug/drafts';
export const teamsSingleChampionPoolLeaguepediaURL = '/team/:teamSlug/champion-pool/leaguepedia';
export const teamsSingleChampionPoolURL = '/team/:teamSlug/champion-pool';
export const teamsSingleFlexLeaguepediaURL = '/team/:teamSlug/flex/leaguepedia';
export const teamsSingleFlexURL = '/team/:teamSlug/flex';
export const teamsSingleSynergiesLeaguepediaURL = '/team/:teamSlug/synergies/leaguepedia';
export const teamsSingleSynergiesURL = '/team/:teamSlug/synergies';
export const teamsSinglePicksLeaguepediaURL = '/team/:teamSlug/picks/leaguepedia';
export const teamsSinglePicksURL = '/team/:teamSlug/picks';
export const teamsSingleEnemyDataLeaguepediaURL = '/team/:teamSlug/enemy-data/leaguepedia';
export const teamsSingleEnemyDataURL = '/team/:teamSlug/enemy-data';
export const teamsSingleJungleStatisticsURL = '/team/:teamSlug/jungle/statistics';
export const teamsSingleJungleURL = '/team/:teamSlug/jungle';
export const teamsSingleLvL1WardsURL = '/team/:teamSlug/lvl1/wards';
export const teamsSingleLvL1PositionsURL = '/team/:teamSlug/lvl1';
export const teamsSingleShopURL = '/team/:teamSlug/shop';
export const teamsSingleWardsStatisticsURL = '/team/:teamSlug/wards/statistics';
export const teamsSingleWardsObjectivesURL = '/team/:teamSlug/wards/objectives';
export const teamsSingleWardsSurvivedURL = '/team/:teamSlug/wards/survived';
export const teamsSingleWardsTimelineURL = '/team/:teamSlug/wards/timeline';
export const teamsSingleWardsURL = '/team/:teamSlug/wards';
export const teamsSingleOracleLensURL = '/team/:teamSlug/oracle-lens';
export const teamsSingleHeatmapURL = '/team/:teamSlug/heatmap';
export const teamsSinglePositionsOnMapURL = '/team/:teamSlug/positions-on-map';
export const teamsSingleObjectivesURL = '/team/:teamSlug/objectives';
export const teamsSingleSummonersURL = '/team/:teamSlug/summoners';
export const teamsSingleRecallsURL = '/team/:teamSlug/recalls';
export const teamsSingleGraphsURL = '/team/:teamSlug/graphs';
export const teamsSingleWinRateURL = '/team/:teamSlug/win-rate';
export const teamsSinglePerformanceURL = '/team/:teamSlug/performance';
export const teamsSingleScoresURL = '/team/:teamSlug/scores';
export const teamsSingleHoursURL = '/team/:teamSlug/hours';
export const teamsSingleURL = '/team/:teamSlug';
export const teamsSingleBasicURL = '/team';
export const teamsBasicURL = '/teams';

export const playersSingleChampionPoolURL = '/player/:playerSlug/champion-pool';
export const playersSingleStatisticsURL = '/player/:playerSlug/statistics';
export const playersSingleGraphsURL = '/player/:playerSlug/graphs';
export const playersSingleHoursURL = '/player/:playerSlug/hours';
export const playersSingleURL = '/player/:playerSlug';
export const playersSingleBasicURL = '/player';
export const playersBasicURL = '/players';

export const championsSingleURL = '/champion/:championSlug';
export const championsSingleBasicURL = '/champion';
export const championsBasicURL = '/champions';

export const gamesSingleTeamFightsURL = '/game/:gameId/team-fights';
export const gamesSinglePositionsOnMapURL = '/game/:gameId/positions-on-map';
export const gamesSingleScoreboardURL = '/game/:gameId/scoreboard';
export const gamesSingleURL = '/game/:gameId';
export const gamesBasicURL = '/game';

export const searchVodBasicURL = '/search/vod';
export const searchCounterBasicURL = '/search/counter';
export const searchChampionsDataBasicURL = '/search/champions-data';
export const searchWorldsMatchupsBasicURL = '/search/worlds-matchups';

export const comparePlayersBasicURL = '/compare/players';

export const replaysBasicURL = '/replays';

export const settingsBasicURL = '/settings';

export const performanceBasicURL = '/performance';
export const performanceAddURL = '/performance/add';
export const performanceEditURL = '/performance/edit/:id';
export const performanceReportBasicURL = '/performance/report';

export const scoutingSoloqView = '/scouting/soloq';
export const scoutingCompetitiveView = '/scouting/competitive';

export const editorDraftEditURL = '/editor/draft/:id';
export const editorDraftBasicURL = '/editor/draft';

export const socialMediaH2HBasicURL = '/social-media/h2h';
export const socialMediaBasicURL = '/social-media';
