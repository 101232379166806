// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchNationalities = () =>
  axiosLoadingInstance
    .get('nationalities/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleNationality = (id) =>
  axiosLoadingInstance
    .get(`nationalities/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importNationalities = () =>
  axiosInstance
    .get(`nationalities/import/`)
    .then((res) => ({ data: res.data, message: 'Nationalities have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addNationality = (data) =>
  axiosInstance
    .post('nationalities/add/', data)
    .then((res) => ({ data: res.data, message: 'Nationality has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateNationality = (id, data) =>
  axiosInstance
    .put(`nationalities/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Nationality has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteNationality = (id) =>
  axiosInstance
    .delete(`nationalities/${id}/`)
    .then((res) => ({ data: res.data, message: 'Nationality has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
