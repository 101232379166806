// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { ROW_ACTIONS_PROPS } from 'constants/tableHead';
import { ENABLE_FILTERS } from 'constants/mrt';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import RowActions from 'components/atoms/RowActions/RowActions';

const ItemsListTemplate = ({ title, data, columns, rowActions, options }) => {
  const rowActionsOption = Object.keys(rowActions).length > 0;

  const renderRowActions = ({ row }) => {
    if (!rowActionsOption) {
      return null;
    }

    let rowLink = rowActions.baseLink || '';

    if (rowActions.pathVariables && rowActions.pathVariables.length > 0) {
      rowActions.pathVariables.forEach((pathVariable) => {
        rowLink += `/${row.original[pathVariable]}`;
      });
    }

    return (
      <RowActions
        row={row}
        viewBtn={!!rowActions.baseLink}
        viewLink={rowLink}
        editBtn={!!rowActions.edit}
        deleteBtn={!!rowActions.delete}
      />
    );
  };

  const table = useMaterialReactTable({
    columns,
    data,
    ...ENABLE_FILTERS,
    enableColumnFilters: !!options?.filter,
    enableRowActions: rowActionsOption,
    positionActionsColumn: rowActionsOption && 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': rowActionsOption && ROW_ACTIONS_PROPS,
    },
    renderRowActions,
  });

  return (
    <>
      {title && <PageTitle title={title} />}
      <MaterialReactTable table={table} />
    </>
  );
};

ItemsListTemplate.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowActions: PropTypes.object,
  options: PropTypes.object,
};

ItemsListTemplate.defaultProps = {
  title: '',
  rowActions: {},
  options: {},
};

export default ItemsListTemplate;
