// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { dailyReportBasicURL } from 'routes/app';

// Services
import { addDailyReport } from 'services/daily';
import { fetchPlayers } from 'services/players';
import { fetchChampions } from 'services/champions';

// Schemas
import { STRING_REQUIRED_SCHEMA } from 'schemas/base';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const DailyAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { state } = useLocation();
  const [playerOptions, setPlayerOptions] = useState([]);
  const [championOptions, setChampionOptions] = useState([]);
  const renderAfterCalled = useRef(false);
  const date = state ? state.date : null;
  const player = state ? state.player : null;
  const name = state ? state.name : null;
  const dataName = state ? state.dataName : null;
  const isList = state ? state.isList : null;

  useEffect(() => {
    setIsLoading(false);

    if (!date || !player) {
      return;
    }

    fetchPlayers().then((res) => setPlayerOptions(res));
    fetchChampions().then((res) => setChampionOptions(res));

    if (!renderAfterCalled.current) {
      setTitleSEO(`Add ${name} for ${player?.name} - ${date}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    player_id: player.id || '',
    date,
    data: isList ? [] : {},
  };
  const validationSchemaDict = {
    player_id: STRING_REQUIRED_SCHEMA,
    date: STRING_REQUIRED_SCHEMA,
  };

  const validationSchema = Yup.object().shape(validationSchemaDict);

  const fields1 = [
    {
      id: 'player_id',
      label: 'Player',
      typeField: 'select',
      options: playerOptions,
      disabled: true,
    },
    {
      id: 'date',
      label: 'Date',
      typeField: 'textfield',
      disabled: true,
    },
  ];

  const fields2 = [
    {
      id: 'data',
      label: 'data',
      typeField: 'daily',
      loadData: {
        players: playerOptions,
        champions: championOptions,
        role: player.role,
        tab: dataName,
      },
    },
  ];

  return playerOptions && Object.keys(playerOptions).length > 0 ? (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields2Size={3}
      formikFn={addDailyReport}
      formikAdditionalFnArgs={[dataName]}
      formikRedirect={dailyReportBasicURL}
      formikBtnText="Add report"
    />
  ) : null;
};

export default DailyAddView;
