// React
import React, { useContext } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Services
import { fetchSocialMediaGameURL } from 'services/socialMedia';

// Constants
import { BLUE_MAIN } from 'constants/color';

// Assets
import DownloadIcon from '@mui/icons-material/Download';

// Elements
import { IconButton } from '@mui/material';

// Component
const SocialMediaFile = ({ id, filename }) => {
  if (!id) {
    return null;
  }

  const { setIsLoading } = useContext(Context);

  const handleDownload = async (e) => {
    try {
      e.stopPropagation();

      setIsLoading(true);

      fetchSocialMediaGameURL(id)
        .then((response) => response)
        .then((blob) => {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: 'application/octet-stream' }),
          );

          const a = document.createElement('a');

          a.href = url;
          a.download = filename;
          a.click();
          a.remove();

          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IconButton aria-label="download" onClick={handleDownload} size="small">
      <DownloadIcon fontSize="small" sx={{ color: BLUE_MAIN }} />
    </IconButton>
  );
};

SocialMediaFile.propTypes = {
  id: PropTypes.number,
  filename: PropTypes.string,
};

SocialMediaFile.defaultProps = {
  id: null,
  filename: '',
};

export default SocialMediaFile;
