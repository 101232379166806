export const timeMarks = [
  {
    value: 0,
    label: '00:00',
  },
  {
    value: 420,
    label: '07:00',
  },
  {
    value: 840,
    label: '14:00',
  },
  {
    value: 1260,
    label: '21:00',
  },
  {
    value: 1680,
    label: '28:00',
  },
  {
    value: 2100,
    label: '35:00',
  },
];

export const timerangeMinDistance = 30;
