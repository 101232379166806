// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchPerks = () =>
  axiosLoadingInstance
    .get('perks/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importPerks = () =>
  axiosInstance
    .get('perks/import/')
    .then((res) => ({ data: res.data, message: 'Perks have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deletePerk = (id) =>
  axiosInstance
    .delete(`perks/${id}/`)
    .then((res) => ({ data: res.data, message: 'Perk has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
