// React
import React, { useMemo } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION } from 'constants/mrt';

// Component
const GameTable = ({ data, columns }) => {
  const cols = useMemo(() => columns, []);
  const table = useMaterialReactTable({
    columns: cols,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });

  return <MaterialReactTable table={table} />;
};

GameTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GameTable;
