// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchKeystones = () =>
  axiosLoadingInstance
    .get('keystones/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importKeystones = () =>
  axiosInstance
    .get('keystones/import/')
    .then((res) => ({ data: res.data, message: 'Keystones have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteKeystone = (id) =>
  axiosInstance
    .delete(`keystones/${id}/`)
    .then((res) => ({ data: res.data, message: 'Keystone has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
