// React
import React, { useMemo } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION, ROW_NUMBERS } from 'constants/mrt';
import { TEAM_GAMES_SHOP_COLS } from 'constants/tableHead';

// Component
const GamesShopTable = ({ data }) => {
  const columns = useMemo(() => TEAM_GAMES_SHOP_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
    ...ROW_NUMBERS,
  });

  return <MaterialReactTable table={table} />;
};

GamesShopTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GamesShopTable;
