// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchItems } from 'services/items';
import { searchChampionsData } from 'services/leaguepedia';

// Constants
import { BASIC_TABLE } from 'constants/mrt';
import { SEARCH_CHAMPIONS_DATA_COLS } from 'constants/tableHead';
import { ROLES } from 'constants/role';
import { LOADING_TEXT } from 'constants/text';

// Schemas
import { STRING_SCHEMA, OBJECT_REQUIRED_SCHEMA } from 'schemas/base';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from 'components/atoms/Select/Select';

const SearchChampionsDataView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Search Champions Data';

  const fetchData = () => {
    setIsLoading(true);

    fetchItems().then((res) => {
      const itemsList = [
        ...new Map(res.filter((item) => !item.ornn_item).map((item) => [item.name, item])).values(),
      ];

      setItems(itemsList);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    item: null,
    patch: '',
    role: '',
  };

  const validationSchema = Yup.object().shape({
    item: OBJECT_REQUIRED_SCHEMA,
    patch: STRING_SCHEMA,
    role: STRING_SCHEMA,
  });

  const columns = useMemo(() => SEARCH_CHAMPIONS_DATA_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...BASIC_TABLE,
  });

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={9} lg={5} xl={4}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setData([]);

                searchChampionsData(values).then(({ data: respData }) => {
                  setData(respData);

                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <Autocomplete
                          id="item"
                          label="Item"
                          options={items}
                          onChange={(_, newValue) => {
                            setFieldValue('item', newValue);
                          }}
                          disabled={items.length === 0}
                          error={touched.item && !!errors.item}
                          helperText={touched.item && errors.item}
                          value={values.item}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          error={touched.patch && !!errors.patch}
                          helperText={touched.patch && errors.patch}
                          name="patch"
                          label="Patch"
                          value={values.patch}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Select
                          disabled={ROLES.length === 0}
                          error={touched.role && !!errors.role}
                          helperText={touched.role && errors.role}
                          name="role"
                          label="Role"
                          optionLabel="label"
                          options={ROLES}
                          value={values.role}
                          compareValue="value"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                  <Box mt={1}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      loadingIndicator={LOADING_TEXT}
                    >
                      Search
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12} lg={7} xl={6}>
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchChampionsDataView;
