// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { searchWorldsMatchups } from 'services/leaguepedia';

// Constants
import { MATCHUPS_TABLE } from 'constants/mrt';
import { SEARCH_WORLDS_MATCHUPS_COLS } from 'constants/tableHead';
import { ROLES } from 'constants/role';
import { LOADING_TEXT } from 'constants/text';

// Schemas
import { STRING_REQUIRED_SCHEMA } from 'schemas/base';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from 'components/atoms/Select/Select';
import InfoTooltip from 'components/atoms/InfoTooltip/InfoTooltip';

const SearchWorldsMatchupsView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Search Worlds matchups';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      setIsLoading(false);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    role: '',
  };

  const validationSchema = Yup.object().shape({
    role: STRING_REQUIRED_SCHEMA,
  });

  const columns = useMemo(() => SEARCH_WORLDS_MATCHUPS_COLS(false), []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...MATCHUPS_TABLE(SEARCH_WORLDS_MATCHUPS_COLS(true)),
  });

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setData([]);

                searchWorldsMatchups(values).then(({ data: respData }) => {
                  setData(respData);

                  setSubmitting(false);
                });
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="center">
                          <Select
                            disabled={ROLES.length === 0}
                            error={touched.role && !!errors.role}
                            helperText={touched.role && errors.role}
                            name="role"
                            label="Role"
                            optionLabel="label"
                            options={ROLES}
                            displayEmpty={false}
                            value={values.role}
                            compareValue="value"
                            onChange={handleChange}
                          />
                          <Box mt={1} ml={1}>
                            <InfoTooltip data="Data from 2020" bigIcon />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                  <Box mt={1}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      loadingIndicator={LOADING_TEXT}
                    >
                      Search
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12} md={9} lg={6} xl={4}>
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchWorldsMatchupsView;
