// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Assets
import InfoIcon from '@mui/icons-material/Info';

// Elements
import { Box, Typography, Tooltip, useMediaQuery } from '@mui/material';
import GameMap from 'components/molecules/GameMap/GameMap';

// Component
const SummonerUsageData = ({ cell, name }) => {
  const { getValue } = cell;

  if (getValue() === null) {
    return 'Played without this summoner';
  }

  const isTabletViewport = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Box display="flex" gap={2.5}>
      {getValue()?.map(({ id, time, start, end }) => (
        <Tooltip
          key={id}
          slotProps={{
            tooltip: {
              sx: {
                bgcolor: 'transparent',
              },
            },
          }}
          title={
            <Box width={isTabletViewport ? 350 : 200}>
              <GameMap teleport={{ start, end }} />
            </Box>
          }
          placement="left"
        >
          <Box display="flex" alignItems="center" gap={1} minWidth={120}>
            <Typography variant="body2" component="span" textAlign="center">
              {secondsToHms(time)}
            </Typography>
            {name === 'SummonerTeleport' && <InfoIcon fontSize="small" />}
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

SummonerUsageData.propTypes = {
  cell: PropTypes.object.isRequired,
  name: PropTypes.string,
};

SummonerUsageData.defaultProps = {
  name: null,
};

export default SummonerUsageData;
