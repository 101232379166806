// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Typography } from '@mui/material';

// Component
const PageTitle = ({ title, ml, variant, component, disableGutterBottom }) => (
  <Typography variant={variant} component={component} ml={ml} gutterBottom={!disableGutterBottom}>
    {title}
  </Typography>
);

PageTitle.propTypes = {
  title: PropTypes.string,
  ml: PropTypes.number,
  variant: PropTypes.string,
  component: PropTypes.string,
  disableGutterBottom: PropTypes.bool,
};

PageTitle.defaultProps = {
  title: '',
  ml: 0,
  variant: 'h5',
  component: 'h1',
  disableGutterBottom: false,
};

export default PageTitle;
