// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';

// Component
const ItemsData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue()) {
    return null;
  }

  return (
    <Box display="flex" gap={0.5}>
      {getValue().map(({ id, name, image, count, percentage }) => (
        <Box
          key={id}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ minWidth: 50 }}
        >
          <Typography variant="body2" component="span" textAlign="center">
            {count}
          </Typography>
          <Box my={0.25}>
            <ImageItem key={id} src={image} alt={name} width={32} height={32} />
          </Box>
          <GamePercentageData value={percentage} fixNumbers withoutColors />
        </Box>
      ))}
    </Box>
  );
};

ItemsData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default ItemsData;
