// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminUsersURL } from 'routes/admin';

// Services
import { fetchSingleUser, changePasswordUser } from 'services/users';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminUsersEditPasswordView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleUser(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Change password ${data?.username} - Users ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    new_password: '',
    new_password_check: '',
  };
  const validationSchema = Yup.object().shape({
    new_password: Yup.string().required('Required'),
    new_password_check: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
  });
  const fields = [
    {
      id: 'new_password',
      label: 'New password',
      typeField: 'textfield',
      type: 'password',
    },
    {
      id: 'new_password_check',
      label: 'Repeat new password',
      typeField: 'textfield',
      type: 'password',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields}
      formikFn={changePasswordUser}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminUsersURL}
      formikBtnText="Change password"
    />
  );
};

export default AdminUsersEditPasswordView;
