// Axios
import axios from 'axios';

// Cookies
import { Cookies } from 'react-cookie';

// JSON Big Int
import JSONbigint from 'json-bigint';

const baseURL = process.env.REACT_APP_API_URL;
const JSONbigintParser = JSONbigint({ storeAsString: true });

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const cookies = new Cookies();

    newConfig.withCredentials = true;
    newConfig.headers.Authorization = `Bearer ${cookies.get('token')}`;

    return newConfig;
  },
  (error) => Promise.reject(error),
);

export const axiosLoadingInstance = axios.create({
  baseURL,
});

axiosLoadingInstance.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const cookies = new Cookies();

    newConfig.withCredentials = true;
    newConfig.headers.Authorization = `Bearer ${cookies.get('token')}`;
    newConfig.transformResponse = [(data) => data];

    return newConfig;
  },
  (error) => Promise.reject(error),
);

axiosLoadingInstance.interceptors.response.use(
  (response) => {
    const newResponse = response;

    newResponse.data = JSONbigintParser.parse(newResponse.data);

    return newResponse;
  },
  (error) => Promise.reject(error),
);
