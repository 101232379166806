// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchPlayers = () =>
  axiosLoadingInstance
    .get('players/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchPlayersVisible = () =>
  axiosLoadingInstance
    .get('players/?visible=true')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchPlayersByRole = () =>
  axiosLoadingInstance
    .get('players/by-role/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSinglePlayer = (id) =>
  axiosLoadingInstance
    .get(`players/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSinglePlayerBySlug = (playerSlug) =>
  axiosLoadingInstance
    .get(`players/${playerSlug}/?key=slug`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importAllImages = () =>
  axiosInstance
    .get(`players/import-images/`)
    .then((res) => ({ data: res.data, message: 'Images have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importAccountsForPlayer = (id) =>
  axiosInstance
    .get(`players/import-accounts/${id}/`)
    .then((res) => ({ data: res.data, message: 'Accounts have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addPlayer = (data) =>
  axiosInstance
    .post('players/add/', data)
    .then((res) => ({ data: res.data, message: 'Player has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updatePlayer = (id, data) =>
  axiosInstance
    .put(`players/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Player has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deletePlayer = (id) =>
  axiosInstance
    .delete(`players/${id}/`)
    .then((res) => ({ data: res.data, message: 'Player has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
