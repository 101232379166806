// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchRankedGames = () =>
  axiosLoadingInstance
    .get('ranked-games/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addRankedGamesForProPlayers = () =>
  axiosInstance
    .get(`ranked-games/add/pro/`)
    .then((res) => ({ data: res.data, message: 'Ranked games have been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const assignRankedGamesToAccount = (id) =>
  axiosInstance
    .get(`ranked-games/assign/${id}/`)
    .then((res) => ({ data: res.data, message: 'Ranked games have been assigned!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteRankedGame = (id) =>
  axiosInstance
    .delete(`ranked-games/${id}/`)
    .then((res) => ({ data: res.data, message: 'Ranked game has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
