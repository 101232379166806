export const TEAM_PLAYERS = {
  player_top: null,
  player_jng: null,
  player_mid: null,
  player_bot: null,
  player_sup: null,
  sub_top: [],
  sub_jng: [],
  sub_mid: [],
  sub_bot: [],
  sub_sup: [],
};
