// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchWards } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Grid } from '@mui/material';
import Heatmap from 'components/atoms/Heatmap/Heatmap';
import AreaMap from 'components/atoms/AreaMap/AreaMap';

const TeamSingleWardsView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        roleAll,
        wardType,
        timerange,
        patch,
        patches,
        opponent,
        opponents,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [count, setCount] = useState(0);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    roleData,
    wardTypeData,
    timerangeData,
    teamId,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchWards(
      itemType,
      sideData,
      roleData,
      wardTypeData,
      timerangeData,
      teamId,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
    ).then((res) => {
      setData(res.data.data);
      setAreaData(res.data.areas);
      setCount(res.count);

      setFilters((prevValue) => ({
        ...prevValue,
        team: { ...prevValue.team, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        roleAll,
        wardType,
        timerange,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
      );
    }
  }, [matchType, rangeDate, side, roleAll, wardType, timerange, team, patch, opponent, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Wards`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            roleAllFilter
            wardTypeFilter
            timerangeFilter
            patchesFilter={patches}
            opponentsFilter={opponents}
            blitzFilter
            type="team"
          />
          <Grid container>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <Heatmap data={data} count={count} />
            </Grid>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <AreaMap data={areaData} />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default TeamSingleWardsView;
