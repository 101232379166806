// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Assets
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Elements
import { TextField, Checkbox, Autocomplete as AutocompleteMUI, Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Component
const Autocomplete = ({
  id,
  label,
  options,
  onChange,
  disabled,
  error,
  helperText,
  value,
  compareValue,
  optionLabel,
}) => {
  const handleAutocompleteChange = (_, newValue) => {
    onChange(id, newValue);
  };

  const renderTags = (el, getTagProps) =>
    el.map((option, index) => (
      <Chip key={option.id} label={option[optionLabel]} {...getTagProps({ index })} />
    ));

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      {option[optionLabel]}
    </li>
  );

  const renderInput = (params) => (
    <TextField {...params} label={label} error={error} helperText={helperText} />
  );

  return (
    <AutocompleteMUI
      disabled={disabled}
      disableCloseOnSelect
      id={id}
      options={options}
      value={value}
      fullWidth
      onChange={handleAutocompleteChange}
      isOptionEqualToValue={(option, val) => option[compareValue] === val[compareValue]}
      renderTags={renderTags}
      getOptionLabel={(option) => option[optionLabel]}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

Autocomplete.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.object,
  compareValue: PropTypes.string,
  optionLabel: PropTypes.string,
};

Autocomplete.defaultProps = {
  disabled: false,
  error: false,
  helperText: '',
  value: null,
  compareValue: 'id',
  optionLabel: 'name',
};

export default Autocomplete;
