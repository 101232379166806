// React
import React, { useContext, useState, useEffect } from 'react';

// Router
import { useParams, useLocation } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleGameScoreboard } from 'services/games';
import { fetchGameScoreboardForPick } from 'services/gamePicks';

// Constants
import { BLUE_MAIN, BLACK_MAIN, YELLOW_MAIN, GREEN_MAIN, WHITE_A10 } from 'constants/color';
import { GAME_MENU } from 'constants/menu';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Box, Grid, Modal, Backdrop, Typography, useMediaQuery } from '@mui/material';
import GameCard from 'components/organisms/GameCard/GameCard';
import GameScoreboard from 'components/molecules/GameScoreboard/GameScoreboard';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

const GameSingleScoreboardView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { gameId } = useParams();
  const { pathname } = useLocation();
  const [game, setGame] = useState(null);
  const [menu, setMenu] = useState([]);
  const [menu2, setMenu2] = useState([]);
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const isTabletViewport = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isBigDesktopViewport = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const handleOpen = (pickIdValue) => {
    fetchGameScoreboardForPick(pickIdValue).then((res) => {
      setModalData(res);

      setOpen(true);
    });
  };
  const handleClose = () => setOpen(false);

  const fetchInfo = (gameIdValue) => {
    setIsLoading(true);

    fetchSingleGameScoreboard(gameIdValue).then((res) => {
      setGame(res.data);

      const newMenu = [];

      res.games.forEach((item, index) => {
        newMenu.push({
          id: index,
          title: `Game ${index + 1}`,
          link: pathname.replace(gameId, item),
        });
      });

      setMenu(newMenu);
      setMenu2(GAME_MENU);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (gameId) {
      fetchInfo(gameId);
    }
  }, [gameId]);

  useEffect(() => {
    if (game) {
      setTitle(`${game.name}`);
    }
  }, [game]);

  useEffect(() => {
    setTitleSEO(`${title} Scoreboard`);
  }, [title]);

  return (
    <ViewWithSidebar
      menu={menu}
      menu2={menu2}
      component={
        !game || Object.keys(game).length === 0 ? null : (
          <>
            <Box mb={4}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item sm={7} md={10} lg={3} xl={2}>
                  <GameCard
                    data={{
                      date: game?.date,
                      patch: game?.patch,
                      index: game?.index,
                      blue_team: game?.blue_team,
                      red_team: game?.red_team,
                      winner_side: game?.winner_side,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid justifyContent="center" container>
              <Grid item xs={12} lg={6} xl={4.5}>
                <GameScoreboard data={game?.draft?.blue?.picks} openModal={handleOpen} />
              </Grid>
              {isBigDesktopViewport ? <Grid item xl={1} /> : null}
              <Grid item xs={12} lg={6} xl={4.5}>
                <GameScoreboard data={game?.draft?.red?.picks} openModal={handleOpen} side="red" />
              </Grid>
            </Grid>
            <Modal
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  minWidth: isTabletViewport ? '50%' : '90%',
                  maxWidth: '90%',
                  bgcolor: 'background.paper',
                  p: isTabletViewport ? 4 : 2,
                }}
              >
                <Box display="flex" alignItems="center" mb={2}>
                  <ImageItem src={modalData?.champion_image} alt={modalData?.champion} />
                  <Typography variant="h6" component="h2" ml={1}>
                    {modalData?.player} - {modalData?.role}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" component="h3" gutterBottom>
                  Levels
                </Typography>
                <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
                  {modalData?.levels?.map(({ id: levelId, level, time: levelTime }) => (
                    <Box
                      key={levelId}
                      sx={{
                        width: isTabletViewport ? 48 : 36,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          width: isTabletViewport ? 24 : 18,
                          height: isTabletViewport ? 24 : 18,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: BLUE_MAIN,
                          mb: 0.5,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          component="p"
                          fontSize={isTabletViewport ? 14 : 12}
                        >
                          {level}
                        </Typography>
                      </Box>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        fontSize={isTabletViewport ? 14 : 12}
                      >
                        {secondsToHms(levelTime)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {isTabletViewport ? (
                  <>
                    <Typography variant="subtitle1" component="h3" gutterBottom>
                      Skills order
                    </Typography>
                    <Box>
                      {modalData?.skill_orders &&
                        Object.keys(modalData?.skill_orders).map((key) => (
                          <Box key={key} display="flex" alignItems="center" mb={0.5}>
                            <Box
                              sx={{
                                width: 24,
                                height: 24,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: BLACK_MAIN,
                                backgroundColor: YELLOW_MAIN,
                              }}
                            >
                              <Typography variant="subtitle2" component="p">
                                {key}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" ml={1}>
                              {Array.from(Array(18).keys()).map((item) => (
                                <Box
                                  key={item}
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: BLACK_MAIN,
                                    backgroundColor: modalData?.skill_orders[key].includes(item)
                                      ? GREEN_MAIN
                                      : WHITE_A10,
                                    ml: item > 0 && 0.5,
                                  }}
                                >
                                  {modalData?.skill_orders[key].includes(item) && (
                                    <Typography variant="subtitle2" component="p">
                                      {item + 1}
                                    </Typography>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </>
                ) : null}
              </Box>
            </Modal>
          </>
        )
      }
    />
  );
};

export default GameSingleScoreboardView;
