// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchTiers = () =>
  axiosLoadingInstance
    .get('tiers/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importAccountsForTier = (id) =>
  axiosInstance
    .get(`tiers/import-accounts/${id}/`)
    .then((res) => ({ data: res.data, message: 'Accounts have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addRankedGamesForTier = (id) =>
  axiosInstance
    .get(`tiers/add-ranked-games/${id}/`)
    .then((res) => ({ data: res.data, message: 'Ranked games have been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleTier = (id) =>
  axiosLoadingInstance
    .get(`tiers/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addTier = (data) =>
  axiosInstance
    .post('tiers/add/', data)
    .then((res) => ({ data: res.data, message: 'Tier has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateTier = (id, data) =>
  axiosInstance
    .put(`tiers/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Tier has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteTier = (id) =>
  axiosInstance
    .delete(`tiers/${id}/`)
    .then((res) => ({ data: res.data, message: 'Tier has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
