// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePikcer from 'components/atoms/DatePicker/DatePicker';

// Component
const DateRangePicker = ({ data, onChangeFn, filter, resetFn, type, wrap }) => {
  const { start, end } = data;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" gap={1} flexWrap={wrap ? 'wrap' : 'nowrap'}>
        <DatePikcer
          name={start.name}
          label={start.label}
          value={start.value}
          onChangeFn={onChangeFn}
          id={start.id}
          disabled={start.disabled}
          filter={filter}
          resetFn={resetFn}
          type={type}
          endValue={end.value}
        />
        <DatePikcer
          name={end.name}
          label={end.label}
          value={end.value}
          onChangeFn={onChangeFn}
          id={end.id}
          disabled={end.disabled}
          filter={filter}
          resetFn={resetFn}
          type={type}
          startValue={start.value}
        />
      </Box>
    </LocalizationProvider>
  );
};

DateRangePicker.propTypes = {
  data: PropTypes.object.isRequired,
  onChangeFn: PropTypes.func,
  filter: PropTypes.bool,
  resetFn: PropTypes.func,
  type: PropTypes.string,
  wrap: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  onChangeFn: null,
  filter: false,
  resetFn: null,
  type: '',
  wrap: false,
};

export default DateRangePicker;
