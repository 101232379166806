// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Recharts
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Constants
import { GRAY_A50, BLUE_MAIN, RED_MAIN, YELLOW_MAIN } from 'constants/color';

// Elements
import ChartTooltip from 'components/molecules/ChartTooltip/ChartTooltip';

// Component
const ChartComposed = ({ data }) => (
  <ResponsiveContainer width="100%" aspect={2}>
    <ComposedChart data={data}>
      <CartesianGrid stroke={GRAY_A50} />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip content={<ChartTooltip radar />} />
      <Bar dataKey="value" fill={BLUE_MAIN} />
      <Line dataKey="median" dot={false} stroke={RED_MAIN} strokeWidth={2} />
      <Line dataKey="avg" dot={false} stroke={YELLOW_MAIN} strokeWidth={2} />
    </ComposedChart>
  </ResponsiveContainer>
);

ChartComposed.propTypes = {
  data: PropTypes.array,
};

ChartComposed.defaultProps = {
  data: [],
};

export default ChartComposed;
