// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, RED_MAIN, WHITE_MAIN } from 'constants/color';

// Elements
import { Typography } from '@mui/material';

// Component
const GameThresholdData = ({ value, threshold }) => {
  let color = WHITE_MAIN;

  if (value !== null) {
    if (value > threshold) {
      color = GREEN_MAIN;
    } else if (value < threshold) {
      color = RED_MAIN;
    }
  }

  return (
    <Typography
      variant="body2"
      component="p"
      color={color}
      textAlign="center"
    >
      {value}
    </Typography>
  );
};

GameThresholdData.propTypes = {
  value: PropTypes.number,
  threshold: PropTypes.number,
};

GameThresholdData.defaultProps = {
  value: null,
  threshold: null,
};

export default GameThresholdData;
