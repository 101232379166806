// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchTags = () =>
  axiosLoadingInstance
    .get('tags/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleTag = (id) =>
  axiosLoadingInstance
    .get(`tags/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addTag = (data) =>
  axiosInstance
    .post('tags/add/', data)
    .then((res) => ({ data: res.data, message: 'Tag has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateTag = (id, data) =>
  axiosInstance
    .put(`tags/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Tag has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteTag = (id) =>
  axiosInstance
    .delete(`tags/${id}/`)
    .then((res) => ({ data: res.data, message: 'Tag has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
