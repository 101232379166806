// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Constants
import { RED_MAIN } from 'constants/color';

// Elements
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select as SelectMUI,
  FormHelperText,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@mui/material';

// Component
const MultiSelect = ({
  name,
  label,
  optionLabel,
  options,
  value,
  compareValue,
  onChange,
  error,
  helperText,
  margin,
  minWidth,
  disabled,
}) => (
  <Box sx={{ minWidth }}>
    <FormControl margin={margin} fullWidth>
      <InputLabel>{label}</InputLabel>
      <SelectMUI
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(', ')}
        disabled={disabled}
        error={error}
        multiple
      >
        {Object.keys(options).length > 0
          ? options.map((el) => (
              <MenuItem key={el.id} value={el[compareValue]}>
                <Checkbox checked={value.indexOf(el[compareValue]) > -1} />
                <ListItemText primary={el[optionLabel]} />
              </MenuItem>
            ))
          : null}
      </SelectMUI>
      {helperText ? <FormHelperText sx={{ color: RED_MAIN }}>{helperText}</FormHelperText> : null}
    </FormControl>
  </Box>
);

MultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.array,
  compareValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  minWidth: PropTypes.number,
  disabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
  name: '',
  label: '',
  value: [],
  optionLabel: 'name',
  compareValue: 'id',
  error: false,
  helperText: '',
  margin: 'dense',
  minWidth: 150,
  disabled: false,
};

export default MultiSelect;
