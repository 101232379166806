export const GENERAL = 'General';
export const BATTLE = 'Battle';
export const VISION = 'Vision';
export const OBJECTIVES = 'Objectives';
export const DIFFERENCE = 'Difference';

export const PLAYERS_STATISTICS = () => {
  const statisticsList = [
    {
      id: 1,
      value: 'count',
      label: 'GAMES',
    },
  ];

  let index = 2;

  const perMinute = {
    gold: 'GPM',
    cs: 'CSPM',
    dmg: 'DPM',
    xp: 'XPM',
    kills: 'KILLS PER MINUTE',
    deaths: 'DEATHS PER MINUTE',
    assists: 'ASSISTS PER MINUTE',
    wards_placed: 'WPPM',
    wards_killed: 'WKPM',
    control_wards_placed: 'CWPM',
  };

  Object.keys(perMinute).forEach((key) => {
    statisticsList.push({
      id: index,
      value: `${key}_per_minute`,
      label: perMinute[key],
    });

    index += 1;
  });

  const timeline = {
    gold: 'GOLD',
    cs: 'CS',
    xp: 'XP',
    kills: 'KILLS',
    deaths: 'DEATHS',
    assists: 'ASSISTS',
    wards_placed: 'WARDS PLACED',
    wards_killed: 'WARDS KILLED',
    control_wards_placed: 'CONTROL WARDS',
  };

  Object.keys(timeline).forEach((key) => {
    for (let i = 1; i < 21; i += 1) {
      statisticsList.push({
        id: index,
        value: `${key}__${i}`,
        label: `${timeline[key]}@${i}`,
      });

      index += 1;
    }
  });

  const timelineDiff = {
    gold: 'GD',
    cs: 'CSD',
    xp: 'XPD',
    kills: 'KILLS DIFF',
    deaths: 'DEATHS DIFF',
    assists: 'ASSISTS DIFF',
    wards_placed: 'WARDS PLACED DIFF',
    wards_killed: 'WARDS KILLED DIFF',
    control_wards_placed: 'CONTROL WARDS DIFF',
  };

  Object.keys(timelineDiff).forEach((key) => {
    for (let i = 1; i < 21; i += 1) {
      statisticsList.push({
        id: index,
        value: `${key}_diff__${i}`,
        label: `${timelineDiff[key]}@${i}`,
      });

      index += 1;
    }
  });

  const normalStats = {
    gold: 'GOLD',
    cs: 'CS',
    xp: 'XP',
    dmg: 'DMG',
    dmg_taken: 'DMG TAKEN',
    kills: 'KILLS',
    deaths: 'DEATHS',
    assists: 'ASSISTS',
    solo_kills: 'SOLO KILLS',
    kp: 'KP%',
    plates: 'PLATES',
    vision_score: 'VISION SCORE',
    wards_placed: 'WARDS PLACED',
    wards_killed: 'WARDS KILLED',
    control_wards_placed: 'CONTROL WARDS',
    kills_deaths_ratio: 'K/D',
    dmg_gold_ratio: 'DMG/GOLD',
    skill_shots_hit: 'SKILL SHOTS HIT',
    skill_shots_dodged: 'SKILL SHOTS DODGED',
  };

  Object.keys(normalStats).forEach((key) => {
    statisticsList.push({
      id: index,
      value: key,
      label: normalStats[key],
    });

    index += 1;
  });

  statisticsList.sort((a, b) => {
    const aSplit = a.label.split('@');
    const bSplit = b.label.split('@');

    if (aSplit[0] === bSplit[0]) {
      return parseInt(aSplit[1], 10) - parseInt(bSplit[1], 10);
    }

    return aSplit[0] > bSplit[0] ? 1 : -1;
  });

  return statisticsList;
};
