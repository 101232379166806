// React
import React, { useContext, useState, useEffect } from 'react';

// Router
import { useParams, useLocation } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleGamePositionsOnMap } from 'services/games';

// Constants
import { GAME_MENU } from 'constants/menu';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Box, Grid } from '@mui/material';
import GameCard from 'components/organisms/GameCard/GameCard';
import GameDraft from 'components/organisms/GameDraft/GameDraft';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import ChartPositionMap from 'components/molecules/ChartPositionMap/ChartPositionMap';

const GameSinglePositionsOnMapView = () => {
  const {
    filters: {
      game: { role, timerange },
    },
    setTitleSEO,
    setIsLoading,
  } = useContext(Context);
  const { gameId } = useParams();
  const { pathname } = useLocation();
  const [game, setGame] = useState(null);
  const [menu, setMenu] = useState([]);
  const [menu2, setMenu2] = useState([]);
  const [title, setTitle] = useState('');

  const fetchInfo = (gameIdValue, roleValue, timerangeValue) => {
    setIsLoading(true);

    fetchSingleGamePositionsOnMap(gameIdValue, roleValue, timerangeValue).then((res) => {
      setGame(res.data);

      const newMenu = [];

      res.games.forEach((item, index) => {
        newMenu.push({
          id: index,
          title: `Game ${index + 1}`,
          link: pathname.replace(gameId, item),
        });
      });

      setMenu(newMenu);
      setMenu2(GAME_MENU);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (gameId) {
      fetchInfo(gameId, role, timerange);
    }
  }, [gameId, role, timerange]);

  useEffect(() => {
    if (game) {
      setTitle(`${game.name}`);
    }
  }, [game]);

  useEffect(() => {
    setTitleSEO(`${title} Positions on map`);
  }, [title]);

  return (
    <ViewWithSidebar
      menu={menu}
      menu2={menu2}
      component={
        !game || Object.keys(game).length === 0 ? null : (
          <>
            <Box mb={4}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item sm={7} md={5} lg sx={{ order: { xs: 1, lg: 'inherit' } }}>
                  <GameDraft picks={game?.draft?.blue?.picks} bans={game?.draft?.blue?.bans} />
                </Grid>
                <Grid item sm={7} md={10} lg={3} xl={2}>
                  <GameCard
                    data={{
                      date: game?.date,
                      patch: game?.patch,
                      index: game?.index,
                      blue_team: game?.blue_team,
                      red_team: game?.red_team,
                      winner_side: game?.winner_side,
                    }}
                  />
                </Grid>
                <Grid item sm={7} md={5} lg sx={{ order: { xs: 2, lg: 'inherit' } }}>
                  <GameDraft
                    picks={game?.draft?.red?.picks}
                    bans={game?.draft?.red?.bans}
                    side="red"
                  />
                </Grid>
              </Grid>
            </Box>
            <DataFilters roleFilter timerangeFilter type="game" />
            {game.data && game.data.length > 0 ? (
              <Grid container>
                <Grid item sm={12} lg={10}>
                  <ChartPositionMap data={game.data} />
                </Grid>
              </Grid>
            ) : null}
          </>
        )
      }
    />
  );
};

export default GameSinglePositionsOnMapView;
