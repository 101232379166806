// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';
import CardTitle from 'components/atoms/CardTitle/CardTitle';
import CardText from 'components/atoms/CardText/CardText';
import CardValue from 'components/atoms/CardValue/CardValue';

// Component
const PlayerStatisticCard = ({ title, data }) => {
  const renderStatisticComponent = (singleStatisticValue, singleStatisticType) => {
    if (singleStatisticType === 'percentage') {
      return <GamePercentageData value={singleStatisticValue} fixNumbers withoutColors />;
    }

    if (singleStatisticType === 'diff') {
      return <GameDiffData value={singleStatisticValue} />;
    }

    return <CardValue value={singleStatisticValue} />;
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <CardTitle text={title} />
        {data
          ? Object.entries(data).map(
              ([item, { value: singleStatisticValue, type: singleStatisticType }]) => (
                <Box key={item} display="flex" alignItems="center" mb={0.5}>
                  <CardText text={`${item}:`} mr={1} />
                  {renderStatisticComponent(singleStatisticValue, singleStatisticType)}
                </Box>
              ),
            )
          : null}
      </CardContent>
    </Card>
  );
};

PlayerStatisticCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default PlayerStatisticCard;
