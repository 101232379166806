// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Utils
import { createOpGgLink } from 'utils/opgg';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import Link from 'components/atoms/Link/Link';
import ServerChip from 'components/atoms/ServerChip/ServerChip';
import CardTitle from 'components/atoms/CardTitle/CardTitle';

// Component
const PlayerAccounts = ({ accounts }) => (
  <Card>
    <CardContent>
      <CardTitle text="Accounts" />
      <Box display="flex" flexDirection="column" gap={1}>
        {accounts.map(({ id, name, tag, server: { name: serverName } }) => {
          const accountLink = createOpGgLink(name, tag, serverName);

          return accountLink ? (
            <Box key={id} display="flex" alignItems="center" justifyContent="space-between">
              <Link text={name} href={accountLink} />
              <ServerChip server={serverName} />
            </Box>
          ) : null;
        })}
      </Box>
    </CardContent>
  </Card>
);

PlayerAccounts.propTypes = {
  accounts: PropTypes.array.isRequired,
};

export default PlayerAccounts;
