// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Dayjs
import dayjs from 'dayjs';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchChampions } from 'services/champions';
import { fetchScoutingSoloqData } from 'services/scouting';

// Constants
import { BASIC_TABLE } from 'constants/mrt';
import { SCOUTING_SOLOQ_COLS } from 'constants/tableHead';
import { ROLES } from 'constants/role';
import { LOADING_TEXT } from 'constants/text';

// Schemas
import { STRING_REQUIRED_SCHEMA, OBJECT_NULLABLE_SCHEMA } from 'schemas/base';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import Select from 'components/atoms/Select/Select';
import MultiAutocomplete from 'components/atoms/MultiAutocomplete/MultiAutocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import DateRangePicker from 'components/molecules/DateRangePicker/DateRangePicker';
import { Grid, Box } from '@mui/material';
import { CLASSES } from 'constants/class';

const ScoutingSoloqView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const [championOptions, setChampionOptions] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Scouting SoloQ';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchChampions().then((res) => {
        setChampionOptions(res);

        setIsLoading(false);
      });
    }

    renderAfterCalled.current = true;
  }, []);

  const now = dayjs();

  const initialValues = {
    type: 'SOLOQ',
    start_date: now.startOf('year'),
    end_date: now,
    role: 'TOP',
    classes: [],
    champions: [],
  };

  const validationSchema = Yup.object().shape({
    type: STRING_REQUIRED_SCHEMA,
    start_date: OBJECT_NULLABLE_SCHEMA,
    end_date: OBJECT_NULLABLE_SCHEMA,
    role: STRING_REQUIRED_SCHEMA,
    classes: Yup.array(),
    champions: Yup.array(),
  });

  const columns = useMemo(() => SCOUTING_SOLOQ_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...BASIC_TABLE,
  });

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setData([]);

            fetchScoutingSoloqData(values).then(({ data: respData }) => {
              setData(respData);

              setSubmitting(false);
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={6} md={3}>
                  <DateRangePicker
                    data={{
                      start: { name: 'start_date', label: 'Start Date', value: values.start_date },
                      end: { name: 'end_date', label: 'End Date', value: values.end_date },
                    }}
                    onChangeFn={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <Select
                    disabled={ROLES.length === 0}
                    error={touched.role && !!errors.role}
                    helperText={touched.role && errors.role}
                    name="role"
                    label="Role"
                    optionLabel="label"
                    options={ROLES}
                    displayEmpty={false}
                    value={values.role}
                    compareValue="value"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <MultiAutocomplete
                    disabled={CLASSES.length === 0}
                    error={touched.classes && !!errors.classes}
                    helperText={touched.classes && errors.classes}
                    name="classes"
                    label="Classes"
                    optionLabel="label"
                    options={CLASSES}
                    value={values.classes}
                    compareValue="value"
                    onChangeFunction={setFieldValue}
                    onChangeField="classes"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <MultiAutocomplete
                    disabled={championOptions.length === 0}
                    error={touched.champions && !!errors.champions}
                    helperText={touched.champions && errors.champions}
                    name="champions"
                    label="Champions"
                    optionLabel="name"
                    options={championOptions}
                    value={values.champions}
                    compareValue="id"
                    onChangeFunction={setFieldValue}
                    onChangeField="champions"
                  />
                </Grid>
              </Grid>
              <Box mt={1}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                  loadingIndicator={LOADING_TEXT}
                >
                  Show data
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Formik>
        {data && data.length > 0 ? (
          <Box mt={2}>
            <MaterialReactTable table={table} />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ScoutingSoloqView;
