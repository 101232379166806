// Yup
import * as Yup from 'yup';

// Schemas
import { STRING_REQUIRED_SCHEMA } from 'schemas/base';

const MIN_PASSWORD_LENGTH = 8;
const PASSWORD_AT_LEAST = 'Password must contain at least one';

export const PASSWORD_SCHEMA = STRING_REQUIRED_SCHEMA.min(
  MIN_PASSWORD_LENGTH,
  `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
)
  .matches(/\d/, `${PASSWORD_AT_LEAST} digit`)
  .matches(/[A-Z]/, `${PASSWORD_AT_LEAST} uppercase letter`)
  .matches(/[a-z]/, `${PASSWORD_AT_LEAST} lowercase letter`);

export const PASSWORD_CHECK_SCHEMA = STRING_REQUIRED_SCHEMA.oneOf(
  [Yup.ref('new_password'), null],
  'Passwords must match',
);
