// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Utils
import { generateUniqueKey } from 'utils/key';

// Assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Elements
import {
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';
import Select from 'components/atoms/Select/Select';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';

// Component
const TeamsManageData = ({ value, disabled, loadData, handleChange, setFieldValue }) => {
  const handleTeamChange = (teamIndex, field, newValue) => {
    setFieldValue(`teams[${teamIndex}].${field}`, newValue);
  };

  const addNewTeam = () => {
    setFieldValue('teams', [
      ...value,
      {
        key: generateUniqueKey(),
        team_id: '',
        team: null,
        role: '',
        is_sub: false,
        start_date: '',
        end_date: '',
      },
    ]);
  };

  const removeTeam = (index) => {
    const updatedTeams = [...value];
    updatedTeams.splice(index, 1);

    setFieldValue('teams', updatedTeams);
  };

  return (
    <Box>
      <Typography variant="subtitle1">Teams</Typography>
      {value.map(({ key, team_id, start_date }, teamIndex) => (
        <Grid container key={key || `${team_id}_${start_date}`}>
          <Grid item xs={12} md={10}>
            <Grid container spacing={0} rowSpacing={0} columnSpacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id={value[teamIndex].team_id.toString()}
                  label="Team"
                  options={loadData.teams}
                  onChange={(_, newValue) => {
                    handleTeamChange(teamIndex, 'team_id', newValue.id);
                    handleTeamChange(teamIndex, 'team', newValue);
                  }}
                  disabled={disabled || loadData.teams.length === 0}
                  value={value[teamIndex].team || null}
                  compareValue="id"
                  optionLabel="name"
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  disabled={disabled || loadData.roles.length === 0}
                  name={`teams[${teamIndex}].role`}
                  label="Role"
                  optionLabel="label"
                  options={loadData.roles}
                  value={value[teamIndex].role || ''}
                  compareValue="value"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={5}>
                    <TextField
                      name={`teams[${teamIndex}].start_date`}
                      label="Start date"
                      value={value[teamIndex].start_date || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name={`teams[${teamIndex}].end_date`}
                      label="End date"
                      value={value[teamIndex].end_date || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={`teams[${teamIndex}].is_sub`}
                          name={`teams[${teamIndex}].is_sub`}
                        />
                      }
                      label="Substitute"
                      checked={value[teamIndex].is_sub || false}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box mt={3}>
              <IconButton aria-label="delete" onClick={() => removeTeam(teamIndex)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ))}
      {loadData.teams.length > 0 && loadData.teams.length * loadData.roles.length > value.length ? (
        <Button variant="text" size="small" startIcon={<AddIcon />} onClick={addNewTeam}>
          Add team
        </Button>
      ) : null}
    </Box>
  );
};

TeamsManageData.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  loadData: PropTypes.shape({
    teams: PropTypes.arrayOf(PropTypes.object),
    roles: PropTypes.arrayOf(PropTypes.object),
  }),
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

TeamsManageData.defaultProps = {
  disabled: false,
  loadData: {
    teams: [],
    roles: [],
  },
};

export default TeamsManageData;
