// Constants
import { MATCH_TYPES } from 'constants/match';

export const SEARCH_COUNTER_MATCH_TYPE_LIST = [
  {
    id: 'competitive',
    label: MATCH_TYPES.COMPETITIVE,
    value: MATCH_TYPES.COMPETITIVE,
  },
  {
    id: 'soloq',
    label: MATCH_TYPES.SOLOQ,
    value: MATCH_TYPES.SOLOQ,
  },
];
