// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminOptionsURL } from 'routes/admin';

// Services
import { fetchSingleOption, updateOption } from 'services/options';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminOptionsEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleOption(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.name} - Options ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    name: data?.name || '',
    value: data?.value || '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'value',
      label: 'Value',
      typeField: 'textfield',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFn={updateOption}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminOptionsURL}
      formikBtnText="Update option"
    />
  );
};

export default AdminOptionsEditView;
