// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Yup
import * as Yup from 'yup';

// Routes
import { adminPlayersURL } from 'routes/admin';

// Services
import { fetchNationalities } from 'services/nationalities';
import { fetchAccounts } from 'services/accounts';
import { fetchTeams } from 'services/teams';
import { fetchSinglePlayer, importAccountsForPlayer, updatePlayer } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ROLES } from 'constants/role';

// Assets
import RefreshIcon from '@mui/icons-material/Refresh';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminPlayersEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSinglePlayer(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });

    fetchNationalities().then((res) => setNationalityOptions(res));
    fetchTeams().then((res) => setTeamOptions(res));
    fetchAccounts().then((res) => setAccountOptions(res));
  };

  const importAccounts = () => {
    setBtnLoading(true);

    importAccountsForPlayer(id).then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchAccounts().then((it) => {
        setAccountOptions(it);

        fetchSinglePlayer(id).then((el) => {
          setData(el);
          setBtnLoading(false);
        });
      });
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data.name} - Players ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    name: data?.name || '',
    role: (ROLES.length > 0 && data?.role) || '',
    provider_id: data?.provider_id || '',
    image_url: data?.image_url || '',
    leaguepedia: data?.leaguepedia || '',
    birthday: data?.birthday || '',
    lolpros: data?.lolpros || '',
    nationalities: (nationalityOptions.length > 0 && data?.nationalities) || [],
    teams: (teamOptions.length > 0 && data?.teams) || [],
    accounts: (accountOptions.length > 0 && data?.accounts) || [],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    provider_id: Yup.string(),
    image_url: Yup.string().url(),
    leaguepedia: Yup.string().required('Required'),
    birthday: Yup.string(),
    lolpros: Yup.string(),
    nationalities: Yup.array(),
    teams: Yup.array(),
    accounts: Yup.array(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'role',
      label: 'Role',
      typeField: 'select',
      options: ROLES,
      optionLabel: 'label',
      compareValue: 'value',
    },
    {
      id: 'provider_id',
      label: 'Provider ID',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
  ];
  const fields2 = [
    {
      id: 'leaguepedia',
      label: 'Leaguepedia',
      typeField: 'textfield',
    },
    {
      id: 'birthday',
      label: 'Birthday',
      typeField: 'textfield',
    },
    {
      id: 'lolpros',
      label: 'LoLPros',
      typeField: 'textfield',
    },
    {
      id: 'nationalities',
      label: 'Nationalities',
      typeField: 'nationalities',
      loadData: {
        nationalities: nationalityOptions,
      },
    },
  ];
  const fields3 = [
    {
      id: 'accounts',
      label: 'Accounts',
      typeField: 'multiautocomplete',
      options: accountOptions,
    },
    {
      id: 'teams',
      label: 'Teams',
      typeField: 'teams',
      loadData: {
        roles: ROLES,
        teams: teamOptions,
      },
    },
  ];
  const buttons = [
    {
      id: 1,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import accounts',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importAccounts,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFn={updatePlayer}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminPlayersURL}
      formikBtnText="Update player"
      additionalBtns={buttons}
    />
  );
};

export default AdminPlayersEditView;
