// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';
import CardText from 'components/atoms/CardText/CardText';
import CardValue from 'components/atoms/CardValue/CardValue';
import CardTitle from 'components/atoms/CardTitle/CardTitle';

// Component
const TeamStatisticCard = ({ title, data }) => (
  <Card>
    <CardContent>
      <CardTitle text={title} />
      {data.map(({ condition, key, name, value, type, mt }) => {
        if (condition !== undefined && !condition) return null;

        let statisticComponent;

        if (type === 'percentage') {
          statisticComponent = <GamePercentageData value={value} fixNumbers />;
        } else if (type === 'diff') {
          statisticComponent = <GameDiffData value={value} />;
        } else {
          statisticComponent = <CardValue value={value} />;
        }

        return (
          <Box
            key={key || name}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            mt={mt}
          >
            <CardText text={name} />
            {statisticComponent}
          </Box>
        );
      })}
    </CardContent>
  </Card>
);

TeamStatisticCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

TeamStatisticCard.defaultProps = {
  title: '',
};

export default TeamStatisticCard;
