export const calculateAge = (birthday) => {
  const dateBirthday = new Date(birthday);
  const today = new Date();

  const age = today.getFullYear() - dateBirthday.getFullYear();
  const ageCorrection =
    today.getMonth() > dateBirthday.getMonth() ||
    (today.getMonth() === dateBirthday.getMonth() && today.getDate() >= dateBirthday.getDate())
      ? 0
      : 1;

  return age - ageCorrection;
};
