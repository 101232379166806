// React
import React, { useState } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// React Router
import { Link } from 'react-router-dom';

// Constants
import { BLUE_MAIN, WHITE_MAIN, YELLOW_MAIN } from 'constants/color';

// Utils
import { replaceWithBr } from 'utils/text';

// Assets
import TimerIcon from '@mui/icons-material/Timer';

// Elements
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// Component
const VODsSoloQ = ({ data }) => {
  const [value, setValue] = useState('0');

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {data.map(({ opgg, duration }, vodIndex) => (
            <Tab
              key={`${opgg}-${duration}`}
              value={vodIndex.toString()}
              label={
                <Box display="flex" alignItems="center" px={1}>
                  <Link
                    to={opgg}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Box backgroundColor={BLUE_MAIN} p={1 / 2}>
                      <Typography variant="body2" color={WHITE_MAIN} fontSize={9} fontWeight="bold">
                        OPGG
                      </Typography>
                    </Box>
                  </Link>
                  <Typography variant="body2" component="span" color={WHITE_MAIN} ml={1}>
                    Game {vodIndex + 1}
                  </Typography>
                </Box>
              }
            />
          ))}
        </TabList>
        {data.map(({ opgg, duration, notes }, vodIndex) => (
          <TabPanel key={`${opgg}-${duration}`} value={vodIndex.toString()}>
            <Box display="flex" alignItems="center">
              <TimerIcon fontSize="small" sx={{ color: YELLOW_MAIN }} />
              <Typography variant="body1" ml={1 / 2}>
                {duration}
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography variant="h6">Notes:</Typography>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: replaceWithBr(notes) }}
              />
            </Box>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

VODsSoloQ.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VODsSoloQ;
