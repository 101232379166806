// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchDraftsLeaguepedia } from 'services/dataLeaguepedia';

// Elements
import DraftsTemplate from 'components/templates/DraftsTemplate/DraftsTemplate';

const TeamSingleDraftsLeaguepediaView = () => {
  const {
    setTitleSEO,
    filters: {
      team: { rangeDate, side, draftFilter },
    },
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (itemType, sideData, teamId, startDateData, endDateData, draftFilterData) => {
    setIsLoading(true);

    fetchDraftsLeaguepedia(
      itemType,
      sideData,
      teamId,
      startDateData,
      endDateData,
      draftFilterData,
    ).then((res) => {
      setData(res.data);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(side, team.id, rangeDate[0], rangeDate[1], draftFilter);
    }
  }, [rangeDate, side, team, draftFilter]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Drafts`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return <DraftsTemplate data={data} team={team} title={title} leaguepedia />;
};

export default TeamSingleDraftsLeaguepediaView;
