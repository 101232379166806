// React
import React, { useState, useEffect } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Recharts
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Constants
import { BLUE_MAIN, RED_MAIN, WHITE_MAIN } from 'constants/color';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import { useMediaQuery } from '@mui/material';
import YAxisPositionMap from 'components/atoms/YAxisPositionMap/YAxisPositionMap';
import ChartTooltip from 'components/molecules/ChartTooltip/ChartTooltip';

// Component
const ChartPositionMap = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  let aspectRatio = 3;

  if (useMediaQuery((theme) => theme.breakpoints.up('lg'))) {
    aspectRatio = 3;
  }
  if (useMediaQuery((theme) => theme.breakpoints.up('xl'))) {
    aspectRatio = 3;
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const newData = data.map((item) => {
        const newItem = { ...item };

        newItem.transformedName = secondsToHms(item.x);

        return newItem;
      });

      setChartData(newData);
    }
  }, [data]);

  const ticksX = data
    .filter((item, index) => item.x % 60 === 0 || index === 0 || index === data.length - 1)
    .map((item) => secondsToHms(item.x));

  return (
    <ResponsiveContainer width="100%" aspect={aspectRatio}>
      <LineChart data={chartData}>
        <XAxis dataKey="transformedName" ticks={ticksX} />
        <YAxis domain={[0, 1]} ticks={[0, 0.5, 1]} tick={<YAxisPositionMap />} />
        <Tooltip content={<ChartTooltip positionMap />} />
        <Line
          dataKey="y"
          dot={false}
          stroke={WHITE_MAIN}
          strokeWidth={2}
          strokeDasharray="4 4 4"
          isAnimationActive={false}
        />
        <Line
          dataKey="y_blue"
          dot={false}
          stroke={BLUE_MAIN}
          strokeWidth={2}
          isAnimationActive={false}
        />
        <Line
          dataKey="y_red"
          dot={false}
          stroke={RED_MAIN}
          strokeWidth={2}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

ChartPositionMap.propTypes = {
  data: PropTypes.array,
};

ChartPositionMap.defaultProps = {
  data: [],
};

export default ChartPositionMap;
