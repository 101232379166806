// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import { CircularProgress } from '@mui/material';

// Styles
import { StyledBox } from './Loading.styles';

// Component
const Loading = ({ loading }) => (
  <StyledBox loading={loading}>
    <CircularProgress />
  </StyledBox>
);

Loading.propTypes = {
  loading: PropTypes.number.isRequired,
};

export default Loading;
