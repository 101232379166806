// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { editorDraftBasicURL } from 'routes/app';

// Services
import { fetchChampions } from 'services/champions';
import { fetchTeams } from 'services/teams';
import { fetchSingleGameDraft, updateGameDraft } from 'services/games';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const EditorDraftEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [championOptions, setChampionOptions] = useState([]);
  const [bluePickOptions, setBluePickOptions] = useState([]);
  const [redPickOptions, setRedPickOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [winnerOptions, setWinnerOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleGameDraft(id).then((res) => {
      setData(res);

      setBluePickOptions(res?.blue_picks);
      setRedPickOptions(res?.red_picks);

      setIsLoading(false);
    });

    fetchChampions().then((res) => setChampionOptions(res));
    fetchTeams().then((res) => setTeamOptions(res));
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Editor Draft ${data?.id}`);
    }
  }, [data]);

  useEffect(() => {
    if (data && teamOptions.length > 0) {
      setWinnerOptions(
        teamOptions.filter((team) => team.id === data.blue_team_id || team.id === data.red_team_id),
      );
    }
  }, [data, teamOptions]);

  const initialValues = {
    id: data?.id || '',
    datetime: data?.datetime || '',
    blue_team_id: (teamOptions.length > 0 && data?.blue_team_id) || '',
    red_team_id: (teamOptions.length > 0 && data?.red_team_id) || '',
    winner_team_id: (winnerOptions.length > 0 && data?.winner_team_id) || '',
    blitz: data?.blitz || false,
    blue_bans: data?.blue_bans || [],
    blue_picks: data?.blue_picks || [],
    red_bans: data?.red_bans || [],
    red_picks: data?.red_picks || [],
  };
  const validationSchema = Yup.object().shape({
    blue_bans: Yup.array().of(Yup.object().nullable()),
    blue_picks: Yup.array().of(Yup.object().nullable()),
    red_bans: Yup.array().of(Yup.object().nullable()),
    red_picks: Yup.array().of(Yup.object().nullable()),
  });
  const fields1 = [
    {
      id: 'datetime',
      label: 'Datetime',
      typeField: 'textfield',
      disabled: true,
    },
    {
      id: 'blue_team_id',
      label: 'Blue Team',
      typeField: 'select',
      options: teamOptions,
      disabled: true,
    },
    {
      id: 'red_team_id',
      label: 'Red Team',
      typeField: 'select',
      options: teamOptions,
      disabled: true,
    },
    {
      id: 'winner_team_id',
      label: 'Winner Team',
      typeField: 'select',
      options: winnerOptions,
      disabled: true,
    },
    {
      id: 'blitz',
      label: 'Blitz game',
      typeField: 'checkbox',
    },
  ];
  const fields2 = [
    {
      id: 'blue_bans',
      label: 'Blue bans',
      typeField: 'multiautocomplete',
      options: championOptions,
      optionLabel: 'name',
      compareValue: 'id',
      isOptionEqualToValue: () => false,
    },
    {
      id: 'blue_picks',
      label: 'Blue picks',
      typeField: 'multiautocomplete',
      options: bluePickOptions,
      optionLabel: 'name',
      compareValue: 'id',
    },
  ];
  const fields3 = [
    {
      id: 'red_bans',
      label: 'Red bans',
      typeField: 'multiautocomplete',
      options: championOptions,
      optionLabel: 'name',
      compareValue: 'id',
      isOptionEqualToValue: () => false,
    },
    {
      id: 'red_picks',
      label: 'Red picks',
      typeField: 'multiautocomplete',
      options: redPickOptions,
      optionLabel: 'name',
      compareValue: 'id',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFn={updateGameDraft}
      formikAdditionalFnArgs={[id]}
      formikRedirect={editorDraftBasicURL}
      formikBtnText="Update draft"
    />
  );
};

export default EditorDraftEditView;
