export const CLASSES = [
  {
    id: 1,
    label: 'AP',
    value: 'AP',
  },
  {
    id: 2,
    label: 'AD',
    value: 'AD',
  },
  {
    id: 3,
    label: 'RANGED',
    value: 'Ranged',
  },
  {
    id: 4,
    label: 'MELEE',
    value: 'Melee',
  },
  {
    id: 5,
    label: 'TANK',
    value: 'Tank',
  },
  {
    id: 6,
    label: 'MAGE',
    value: 'Mage',
  },
  {
    id: 7,
    label: 'ASSASSIN',
    value: 'Assassin',
  },
  {
    id: 8,
    label: 'SUPPORT',
    value: 'Support',
  },
  {
    id: 9,
    label: 'FIGHTER',
    value: 'Fighter',
  },
  {
    id: 10,
    label: 'MARKSMAN',
    value: 'Marksman',
  },
  {
    id: 11,
    label: 'VANGUARD',
    value: 'Vanguard',
  },
  {
    id: 12,
    label: 'WARDEN',
    value: 'Warden',
  },
  {
    id: 13,
    label: 'SPECIALIST',
    value: 'Specialist',
  },
  {
    id: 14,
    label: 'BURST',
    value: 'Burst',
  },
  {
    id: 15,
    label: 'SKIRMISHER',
    value: 'Skirmisher',
  },
  {
    id: 16,
    label: 'BATTLEMAGE',
    value: 'Battlemage',
  },
  {
    id: 17,
    label: 'DIVER',
    value: 'Diver',
  },
  {
    id: 18,
    label: 'CATCHER',
    value: 'Catcher',
  },
  {
    id: 19,
    label: 'JUGGERNAUT',
    value: 'Juggernaut',
  },
  {
    id: 20,
    label: 'ARTILLERY',
    value: 'Artillery',
  },
  {
    id: 21,
    label: 'ENCHANTER',
    value: 'Enchanter',
  },
];
