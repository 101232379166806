// Yup
import * as Yup from 'yup';

const MIN_PLAYERS = 2;
const MAX_PLAYERS = 5;

export const COMPARE_PLAYERS_SCHEMA = Yup.array()
  .min(MIN_PLAYERS, `You must choose at least ${MIN_PLAYERS} players!`)
  .max(MAX_PLAYERS, `You can choose up to ${MAX_PLAYERS} players!`)
  .required('Required');
