// React
import React, { useEffect, useRef, useCallback } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Theme
import { areaScale } from 'theme/theme';

// Utils
import { centerTextInCanvas } from 'utils/canvas';
import { hexToRgbA } from 'utils/color';

// Constants
import { BLACK_MAIN, BLACK_A50 } from 'constants/color';
import { CANVAS_PROPORTION } from 'constants/canvas';

// Assets
import mapImage from 'assets/img/map.png';

// Styles
import { StyledMap, StyledCanvas } from 'assets/styles/Map.styles';

// Component
const AreaMap = ({ data }) => {
  const canvasRef = useRef(null);

  const drawMap = useCallback(() => {
    const mapCanvas = canvasRef.current;

    if (mapCanvas) {
      const mapItem = mapCanvas.parentElement;
      const mapWidth = mapItem.clientWidth;
      const mapHeight = mapItem.clientHeight;

      mapCanvas.width = mapWidth;
      mapCanvas.height = mapHeight;

      const ctx = mapCanvas.getContext('2d');
      const scale = Math.min(mapWidth, mapHeight) / CANVAS_PROPORTION;
      const mapWidthQuarter = mapWidth / 4;
      const mapHeightQuarter = mapHeight / 4;

      ctx.lineWidth = 1 * scale;
      centerTextInCanvas(ctx, 20 * scale);

      for (let i = 0; i < 4; i += 1) {
        for (let j = 0; j < 4; j += 1) {
          const rectHeight = mapHeightQuarter;
          const rectX = mapWidthQuarter * j;
          const rectY = mapHeightQuarter * i;
          const textValue = data[i * 4 + j] || 0;

          ctx.beginPath();
          ctx.strokeStyle = BLACK_A50;
          ctx.fillStyle = hexToRgbA(areaScale[Math.round(textValue / 10)], 0.75);
          ctx.rect(rectX, rectY, rectHeight, rectHeight);
          ctx.stroke();
          ctx.fill();

          ctx.fillStyle = BLACK_MAIN;
          ctx.fillText(
            `${textValue.toFixed(2)}%`,
            rectX + mapWidthQuarter / 2,
            rectY + rectHeight / 2,
          );
        }
      }
    }
  }, [data]);

  useEffect(() => {
    drawMap();

    const handleResize = () => {
      drawMap();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [drawMap]);

  return (
    <StyledMap image={mapImage}>
      <StyledCanvas ref={canvasRef} />
    </StyledMap>
  );
};

AreaMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default AreaMap;
