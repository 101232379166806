// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminUsersURL } from 'routes/admin';

// Services
import { addUser } from 'services/users';
import { fetchTeams } from 'services/teams';
import { fetchPlayers } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminUsersAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [teamOptions, setTeamOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchTeams().then((res) => {
      setTeamOptions(res);
    });

    fetchPlayers().then((res) => {
      setPlayerOptions(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Users ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    username: '',
    password: '',
    is_verified: false,
    is_admin: false,
    is_editor: false,
    teams_access: [],
    players_home: [],
    user_object: null,
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    is_verified: Yup.boolean(),
    is_admin: Yup.boolean(),
    is_editor: Yup.boolean(),
    teams_access: Yup.array(),
    players_home: Yup.array(),
    user_object: Yup.object().nullable(),
  });
  const fields1 = [
    {
      id: 'username',
      label: 'Username',
      typeField: 'textfield',
    },
    {
      id: 'password',
      label: 'Password',
      typeField: 'textfield',
      type: 'password',
    },
    {
      id: 'is_verified',
      label: 'Verified',
      typeField: 'checkbox',
    },
    {
      id: 'is_admin',
      label: 'Admin',
      typeField: 'checkbox',
    },
    {
      id: 'is_editor',
      label: 'Editor',
      typeField: 'checkbox',
    },
  ];
  const fields2 = [
    {
      id: 'teams_access',
      label: 'Teams access',
      typeField: 'multiautocomplete',
      options: teamOptions,
    },
    {
      id: 'players_home',
      label: 'Players home page',
      typeField: 'multiautocomplete',
      options: playerOptions,
    },
    {
      id: 'user_object',
      label: 'User object',
      typeField: 'autocomplete',
      options: playerOptions,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFn={addUser}
      formikRedirect={adminUsersURL}
      formikBtnText="Add user"
    />
  );
};

export default AdminUsersAddView;
