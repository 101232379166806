// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Yup
import * as Yup from 'yup';

// Routes
import { adminSplitsURL } from 'routes/admin';

// Services
import { fetchLeagues } from 'services/leagues';
import { fetchSingleSplit, importDataForSplit, updateSplit } from 'services/splits';
import { fetchTeams } from 'services/teams';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Assets
import RefreshIcon from '@mui/icons-material/Refresh';

// Elements
import AdminManage from 'components/organisms/AdminManage/AdminManage';

const AdminSplitsEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [currentTeams, setCurrentTeams] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleSplit(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });

    fetchLeagues().then((res) => setLeagueOptions(res));

    fetchTeams().then((res) => setTeamOptions(res));
  };

  const importData = () => {
    setBtnLoading(true);

    importDataForSplit(id).then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchData();

      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      if (data.teams && data.teams.length > 0) {
        const teams = data.teams.map(({ team }) => team);

        setCurrentTeams(teams);
      }

      setTitleSEO(`Edit ${data?.name} - Splits ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    name: data?.name || '',
    league: (leagueOptions.length > 0 && data?.league) || null,
    provider_id: data?.provider_id || '',
    start_date: data?.start_date || '',
    end_date: data?.end_date || '',
    leaguepedia: data?.leaguepedia || '',
    teams: currentTeams,
    visible: data?.visible || false,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    league: Yup.object().typeError('Choose league!').required('Required'),
    provider_id: Yup.string(),
    start_date: Yup.string(),
    end_date: Yup.string(),
    leaguepedia: Yup.string().required('Required'),
    teams: Yup.array(),
    visible: Yup.boolean(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'provider_id',
      label: 'Provider ID',
      typeField: 'textfield',
    },
    {
      id: 'leaguepedia',
      label: 'Leaguepedia',
      typeField: 'textfield',
    },
    {
      id: 'visible',
      label: 'Visible',
      typeField: 'checkbox',
    },
  ];
  const fields2 = [
    {
      id: 'league',
      label: 'League',
      typeField: 'autocomplete',
      options: leagueOptions,
    },
    {
      id: 'start_date',
      label: 'Start',
      typeField: 'textfield',
    },
    {
      id: 'end_date',
      label: 'End',
      typeField: 'textfield',
    },
    {
      id: 'teams',
      label: 'Teams',
      typeField: 'multiautocomplete',
      options: teamOptions,
    },
  ];
  const buttons = [
    {
      id: 1,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import data',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importData,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFn={updateSplit}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminSplitsURL}
      formikBtnText="Update split"
      additionalBtns={buttons}
    />
  );
};

export default AdminSplitsEditView;
