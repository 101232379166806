// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Chip } from '@mui/material';

// Component
const ServerChip = ({ server, ml }) => (
  <Chip
    label={server}
    size="small"
    sx={{
      minWidth: 45,
      fontSize: 12,
      fontWeight: 700,
      textAlign: 'center',
      ml,
    }}
  />
);

ServerChip.propTypes = {
  server: PropTypes.string.isRequired,
  ml: PropTypes.number,
};

ServerChip.defaultProps = {
  ml: 0,
};

export default ServerChip;
