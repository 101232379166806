// Theme
import { createTheme } from '@mui/material/styles';

export const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1200,
    xl: 1600,
    xxl: 2400,
  },
};

export const typography = {
  fontSize: 14,
};

export const palette = {
  mode: 'dark',
  background: {
    main: '#121212',
    a50: 'rgba(18,18,18,0.5)',
    a10: 'rgba(18,18,18,0.1)',
  },
  tableBackground: {
    main: '#202020',
    a50: 'rgba(32,32,32,0.5)',
    a10: 'rgba(32,32,32,0.1)',
  },
  white: {
    main: '#ffffff',
    a50: 'rgba(255,255,255,0.5)',
    a10: 'rgba(255,255,255,0.1)',
  },
  black: {
    main: '#000000',
    a50: 'rgba(0,0,0,0.5)',
    a10: 'rgba(0,0,0,0.1)',
  },
  blue: {
    main: '#2196f3',
    a50: 'rgba(33,150,243,0.5)',
    a10: 'rgba(33,150,243,0.1)',
  },
  red: {
    main: '#f44336',
    a50: 'rgba(244,67,54,0.5)',
    a10: 'rgba(244,67,54,0.1)',
  },
  green: {
    main: '#39f436',
    a50: 'rgba(57,244,54,0.5)',
    a10: 'rgba(57,244,54,0.1)',
  },
  yellow: {
    main: '#ffeb3b',
    a50: 'rgba(255,235,59,0.5)',
    a10: 'rgba(255,235,59,0.1)',
  },
  gray: {
    main: '#9e9e9e',
    a50: 'rgba(158,158,158,0.5)',
    a10: 'rgba(158,158,158,0.1)',
  },
  top: {
    main: '#ff9800',
    a50: 'rgba(255,152,0,0.5)',
    a10: 'rgba(255,152,0,0.1)',
  },
  jng: {
    main: '#4caf50',
    a50: 'rgba(76,175,80,0.5)',
    a10: 'rgba(76,175,80,0.1)',
  },
  mid: {
    main: '#03a9f4',
    a50: 'rgba(3,169,244,0.5)',
    a10: 'rgba(3,169,244,0.1)',
  },
  bot: {
    main: '#e91e63',
    a50: 'rgba(233,30,99,0.5)',
    a10: 'rgba(233,30,99,0.1)',
  },
  sup: {
    main: '#9c27b0',
    a50: 'rgba(156,39,176,0.5)',
    a10: 'rgba(156,39,176,0.1)',
  },
};

export const scale = [
  '#e67c73',
  '#e98872',
  '#ed9570',
  '#f0a26e',
  '#f4af6c',
  '#f7bc6a',
  '#fbc968',
  '#ffd666',
  '#ffd666',
  '#dbd06d',
  '#b7ca75',
  '#93c47d',
  '#93c47d',
  '#8cc37e',
  '#84c280',
  '#7dc181',
  '#75c083',
  '#6ebf85',
  '#66be86',
  '#5fbd88',
  '#57bb8a',
];

export const areaScale = [
  '#e67c73',
  '#f4af6c',
  '#f7bc6a',
  '#fbc968',
  '#dbd06d',
  '#93c47d',
  '#84c280',
  '#7dc181',
  '#75c083',
  '#6ebf85',
  '#57bb8a',
];

export const scoreScale = [
  '#e67c73',
  '#f0a26e',
  '#f7bc6a',
  '#ffd666',
  '#b7ca75',
  '#93c47d',
  '#8cc37e',
  '#7dc181',
  '#6ebf85',
  '#5fbd88',
  '#57bb8a',
];

export const compareScale = [
  palette.top.main,
  palette.jng.main,
  palette.mid.main,
  palette.bot.main,
  palette.sup.main,
];

export const components = {
  MuiContainer: {
    defaultProps: {
      maxWidth: 'xxl',
    },
  },
  MuiGrid: {
    defaultProps: {
      spacing: {
        xs: 1,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
  },
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
      margin: 'dense',
    },
  },
  MuiTextField: {
    defaultProps: {
      margin: 'dense',
    },
  },
};

export const theme = createTheme({
  breakpoints,
  typography,
  palette,
  components,
});
