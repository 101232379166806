// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box } from '@mui/material';
import PlayerCard from 'components/molecules/PlayerCard/PlayerCard';
import PlayerAccounts from 'components/molecules/PlayerAccounts/PlayerAccounts';

// Component
const PlayerInfo = ({ player, title }) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <PlayerCard object={player} title={title} />
    <PlayerAccounts accounts={player.accounts} />
  </Box>
);

PlayerInfo.propTypes = {
  player: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default PlayerInfo;
