// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Services
import { fetchPlayers } from 'services/players';
import { fetchSocialMediaH2HData } from 'services/socialMedia';

// Constants
import { LOADING_TEXT } from 'constants/text';

// Schemas
import { OBJECT_REQUIRED_SCHEMA } from 'schemas/base';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid } from '@mui/material';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const SocialMediaH2HView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [players, setPlayers] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Social Media H2H';

  const fetchData = () => {
    setIsLoading(true);

    fetchPlayers().then((res) => {
      setPlayers(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    player_1_id: '',
    player_1: null,
    player_2_id: '',
    player_2: null,
  };

  const validationSchema = Yup.object().shape({
    player_1: OBJECT_REQUIRED_SCHEMA,
    player_2: OBJECT_REQUIRED_SCHEMA,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <Box my={2}>
            <Grid container>
              <Grid item xs={12} lg={5} xl={4}>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    fetchSocialMediaH2HData(values)
                      .then((response) => response)
                      .then((blob) => {
                        const url = window.URL.createObjectURL(
                          new Blob([blob], { type: 'application/octet-stream' }),
                        );

                        const a = document.createElement('a');

                        a.href = url;
                        a.download = 'h2h.txt';
                        a.click();
                        a.remove();

                        setSubmitting(false);
                      });
                  }}
                >
                  {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            disabled={players.length === 0}
                            error={touched.player_1 && !!errors.player_1}
                            helperText={touched.player_1 && errors.player_1}
                            id="player_1"
                            label="Player 1"
                            options={players}
                            onChange={(_, newValue) => {
                              setFieldValue('player_1_id', newValue?.id);
                              setFieldValue('player_1', newValue);
                            }}
                            value={values.player_1}
                            compareValue="id"
                            optionLabel="name"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            disabled={players.length === 0}
                            error={touched.player_2 && !!errors.player_2}
                            helperText={touched.player_2 && errors.player_2}
                            id="player_2"
                            label="Player 2"
                            options={players}
                            onChange={(_, newValue) => {
                              setFieldValue('player_2_id', newValue?.id);
                              setFieldValue('player_2', newValue);
                            }}
                            value={values.player_2}
                            compareValue="id"
                            optionLabel="name"
                          />
                        </Grid>
                      </Grid>
                      <Box mt={1}>
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={isSubmitting}
                          loadingIndicator={LOADING_TEXT}
                        >
                          Download File
                        </LoadingButton>
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Box>
        </>
      }
    />
  );
};

export default SocialMediaH2HView;
