// Styles
import styled from 'styled-components';

export const StyledItemName = styled.span`
  && {
    color: ${({
      theme: {
        palette: {
          red: { main: redMain },
        },
      },
    }) => redMain};
    text-transform: uppercase;
  }
`;
