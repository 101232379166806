// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, RED_MAIN, WHITE_MAIN } from 'constants/color';

// Elements
import { Typography } from '@mui/material';

// Component
const GamePercentageData = ({
  cell,
  value,
  threshold,
  fixNumbers,
  withoutColors,
  minWidth,
  small,
  inverse,
}) => {
  const valueData = cell !== null ? cell.getValue() : value;
  let color = WHITE_MAIN;

  if (!withoutColors) {
    if (valueData > threshold) {
      color = inverse ? RED_MAIN : GREEN_MAIN;
    } else if (valueData < threshold) {
      color = inverse ? GREEN_MAIN : RED_MAIN;
    }
  }

  if (valueData === null || valueData === undefined || valueData === '') {
    return null;
  }

  return (
    <Typography
      variant="body2"
      component="span"
      color={color}
      minWidth={minWidth}
      textAlign={minWidth && 'right'}
      sx={{
        fontSize: small && 12,
      }}
    >
      {fixNumbers ? valueData.toFixed(2) : valueData}%
    </Typography>
  );
};

GamePercentageData.propTypes = {
  cell: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  threshold: PropTypes.number,
  fixNumbers: PropTypes.bool,
  withoutColors: PropTypes.bool,
  minWidth: PropTypes.number,
  small: PropTypes.bool,
  inverse: PropTypes.bool,
};

GamePercentageData.defaultProps = {
  cell: null,
  value: null,
  threshold: 50,
  fixNumbers: false,
  withoutColors: false,
  minWidth: null,
  small: false,
  inverse: false,
};

export default GamePercentageData;
