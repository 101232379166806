// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchPerformanceData = async (startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}performance/data/`);

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPerformanceReport = async (startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}performance/report/`);

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSinglePerformanceReport = (id) =>
  axiosLoadingInstance
    .get(`performance/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addPerformanceReport = (data) =>
  axiosInstance
    .post('performance/add/', data)
    .then((res) => ({ data: res.data, message: 'Performance report has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updatePerformanceReport = (id, data) =>
  axiosInstance
    .put(`performance/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Performance report has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
