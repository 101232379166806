// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Typography } from '@mui/material';

// Component
const CardValue = ({ value, ml, mr, center, right }) => {
  let textAlign = 'left';

  if (center) {
    textAlign = 'center';
  } else if (right) {
    textAlign = 'right';
  }

  return (
    <Typography variant="body2" component="p" textAlign={textAlign} ml={ml} mr={mr}>
      {value}
    </Typography>
  );
};

CardValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.number,
  mr: PropTypes.number,
  center: PropTypes.bool,
  right: PropTypes.bool,
};

CardValue.defaultProps = {
  value: 0,
  ml: 0,
  mr: 0,
  center: false,
  right: false,
};

export default CardValue;
