// Utils
import { mapPosition } from 'utils/map';
import { secondsToHms } from 'utils/time';

// Constants
import {
  BLACK_MAIN,
  WHITE_MAIN,
  BLUE_MAIN,
  RED_MAIN,
  GREEN_MAIN,
  YELLOW_MAIN,
} from 'constants/color';
import { T1_COORDS } from 'constants/role';

// Constants
import { CANVAS_CHAMPION_IMAGE_SIZE, CANVAS_WARD_FONT_SIZE } from 'constants/canvas';

export const centerTextInCanvas = (ctx, fontSize) => {
  ctx.font = `bold ${fontSize}px sans-serif`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
};

export const drawWards = (ctx, wards, mapWidth, mapHeight, scale, current) => {
  wards.forEach(({ id, type, event, x, y, time: wardTime, role }) => {
    if (event && event !== 'PLACE') {
      return;
    }

    let opacity = 1;

    if (current && id !== current) {
      opacity = 0.1;
    }

    ctx.globalAlpha = opacity;

    const newX = mapPosition(x, mapWidth);
    const newY = mapHeight - mapPosition(y, mapHeight);

    let circleBG = GREEN_MAIN;

    if (type === 'SIGHT') {
      circleBG = YELLOW_MAIN;
    } else if (type === 'BLUE') {
      circleBG = BLUE_MAIN;
    } else if (type === 'CONTROL') {
      circleBG = RED_MAIN;
    }

    ctx.setLineDash([]);
    ctx.fillStyle = circleBG;
    ctx.lineWidth = 1 * scale;
    ctx.strokeStyle = BLACK_MAIN;

    ctx.beginPath();
    ctx.arc(newX, newY, 4 * scale, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();

    ctx.setLineDash([8 * scale, 4 * scale]);
    ctx.lineWidth = 2 * scale;
    ctx.strokeStyle = WHITE_MAIN;

    ctx.beginPath();
    ctx.arc(newX, newY, 30 * scale, 0, 2 * Math.PI);
    ctx.stroke();

    ctx.setLineDash([]);
    ctx.fillStyle = circleBG;
    ctx.lineWidth = 1 * scale;

    centerTextInCanvas(ctx, CANVAS_WARD_FONT_SIZE * scale);
    ctx.fillStyle = WHITE_MAIN;
    ctx.textBaseline = 'bottom';

    if (wardTime) {
      const circleTextTime = secondsToHms(wardTime);

      ctx.fillText(circleTextTime, newX, newY - 7 * scale);
    }

    if (role) {
      ctx.fillText(role, newX, newY + 20 * scale);
    }
  });
};

export const drawPositions = (ctx, positions, mapWidth, mapHeight, scale) => {
  positions.forEach(({ image, side: championSide, role, x, y, dead }) => {
    const championPosition = role && T1_COORDS[role][championSide];
    const posX = x || championPosition[0];
    const posY = y || championPosition[1];

    const newPosX = mapPosition(posX, mapWidth);
    const newPosY = mapHeight - mapPosition(posY, mapHeight);

    const imageSize = CANVAS_CHAMPION_IMAGE_SIZE * scale;
    const imageObject = new Image();

    imageObject.crossOrigin = 'anonymous';

    imageObject.onload = () => {
      const tempCanvas = document.createElement('canvas');

      tempCanvas.width = imageObject.width;
      tempCanvas.height = imageObject.height;

      const tempCtx = tempCanvas.getContext('2d');

      tempCtx.drawImage(imageObject, 0, 0);

      const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
      const { data } = imageData;

      if (dead) {
        for (let i = 0; i < data.length; i += 4) {
          const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;

          data[i] = avg;
          data[i + 1] = avg;
          data[i + 2] = avg;
        }
      }

      tempCtx.putImageData(imageData, 0, 0);

      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = championSide === 'BLUE' ? BLUE_MAIN : RED_MAIN;
      ctx.lineWidth = 3;
      ctx.arc(newPosX, newPosY, imageSize / 2, 0, Math.PI * 2, false);
      ctx.stroke();
      ctx.clip();
      ctx.closePath();

      ctx.drawImage(
        tempCanvas,
        newPosX - imageSize / 2,
        newPosY - imageSize / 2,
        imageSize,
        imageSize,
      );

      ctx.restore();
    };

    imageObject.src = image;
  });
};
