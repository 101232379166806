// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchDraftsPatterns } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Box, Grid, useMediaQuery } from '@mui/material';
import DraftPatternRole from 'components/molecules/DraftPatternRole/DraftPatternRole';
import BiggerScreen from 'components/atoms/BiggerScreen/BiggerScreen';

const TeamSingleDraftsPatternsView = () => {
  const {
    setTitleSEO,
    filters: {
      team: { matchType, rangeDate, side, result, patch, patches, opponent, opponents, blitz },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);
  const isTabletViewport = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    resultData,
    teamId,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchDraftsPatterns(
      itemType,
      sideData,
      resultData,
      teamId,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        team: { ...prevValue.team, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        result,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
      );
    }
  }, [matchType, rangeDate, side, result, team, patch, opponent, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Drafts Patterns`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            resultFilter
            patchesFilter={patches}
            opponentsFilter={opponents}
            blitzFilter
            type="team"
          />
          {!isTabletViewport && <BiggerScreen />}
          {isTabletViewport && Object.keys(data).length > 0
            ? Object.keys(data).map((key) => {
                const { count: count1, win_rate: winRate1, next: next1 } = data[key];
                const newKeys = key.split('&');

                return (
                  <Box key={key} my={4}>
                    <Grid container>
                      <Grid item md={3} xl={2}>
                        <DraftPatternRole roles={newKeys} count={count1} winRate={winRate1} />
                      </Grid>
                      <Grid item md={9} xl={8}>
                        {Object.keys(next1).length > 0 &&
                          Object.keys(next1).map((key2, key2Index) => {
                            const { count: count2, win_rate: winRate2, next: next2 } = next1[key2];
                            const newKeys2 = key2.split('&');

                            return (
                              <Grid container key={key2}>
                                <Grid item md={4} xl={3}>
                                  <DraftPatternRole
                                    roles={newKeys2}
                                    count={count2}
                                    winRate={winRate2}
                                    index={key2Index}
                                  />
                                </Grid>
                                <Grid item md={8}>
                                  {Object.keys(next2).length > 0 &&
                                    Object.keys(next2).map((key3, key3Index) => {
                                      const {
                                        count: count3,
                                        win_rate: winRate3,
                                        next: next3,
                                      } = next2[key3];
                                      const newKeys3 = key3.split('&');

                                      return (
                                        <Grid container key={key3}>
                                          <Grid item md={6} xl={4.5}>
                                            <DraftPatternRole
                                              roles={newKeys3}
                                              count={count3}
                                              winRate={winRate3}
                                              index={key2Index || key3Index}
                                            />
                                          </Grid>
                                          <Grid item md={6} xl={4.5}>
                                            {Object.keys(next3).length > 0 &&
                                              Object.keys(next3).map((key4, key4Index) => {
                                                const { count: count4, win_rate: winRate4 } =
                                                  next3[key4];
                                                const newKeys4 = key4.split('&');

                                                return (
                                                  <DraftPatternRole
                                                    key={key4}
                                                    roles={newKeys4}
                                                    count={count4}
                                                    winRate={winRate4}
                                                    index={key2Index || key3Index || key4Index}
                                                  />
                                                );
                                              })}
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            : null}
        </>
      }
    />
  );
};

export default TeamSingleDraftsPatternsView;
