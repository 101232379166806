// React
import React from 'react';

// Props Types
import PropTypes from 'prop-types';

// Constants
import { BLUE_MAIN, RED_MAIN } from 'constants/color';

// Utils
import { secondsToHms } from 'utils/time';

// Assets
import InfoIcon from '@mui/icons-material/Info';

// Elements
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const WardsListPerRole = ({ data, setCurrentWard }) => (
  <Grid container>
    {Object.keys(data).map((role) => (
      <Grid key={role} item xs={6} lg={12 / 5}>
        <Typography variant="h6" component="h3" ml={1 / 2}>
          {role}
        </Typography>
        <Box mt={1} display="inline-flex" flexDirection="column">
          {data[role].map(({ id, time, side: wardSide, picks }) => (
            <Box
              key={id}
              onMouseEnter={() => setCurrentWard(id)}
              onMouseLeave={() => setCurrentWard(null)}
              sx={{
                cursor: 'pointer',
                border: '1px solid transparent',
                padding: 1 / 2,
                '&:hover': { borderColor: wardSide === 'BLUE' ? BLUE_MAIN : RED_MAIN },
              }}
            >
              <Tooltip
                title={
                  <Box display="grid" gridTemplateColumns="1fr 1fr">
                    {Object.keys(picks).map((picksSide) => (
                      <Box key={picksSide}>
                        {picks[picksSide].map(
                          ({ id: championId, name: championName, image: championImage }) => (
                            <ImageItem
                              key={championId}
                              src={championImage}
                              alt={championName}
                              width={32}
                              autoHeight
                            />
                          ),
                        )}
                      </Box>
                    ))}
                  </Box>
                }
                placement="right-start"
                slotProps={{
                  tooltip: {
                    sx: {
                      borderRadius: 0,
                      padding: 1 / 4,
                    },
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" component="p" mr={1 / 2}>
                    {secondsToHms(time)}
                  </Typography>
                  <InfoIcon fontSize="small" />
                </Box>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Grid>
    ))}
  </Grid>
);

WardsListPerRole.propTypes = {
  data: PropTypes.object.isRequired,
  setCurrentWard: PropTypes.func.isRequired,
};

export default WardsListPerRole;
