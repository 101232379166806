export const REQUIRED_TEXT = 'Required';

export const SIGN_IN_TEXT = 'Sign in';
export const REGISTER_TEXT = 'Register';

export const USER_SIGN_OUT_TEXT = 'User sign out!';
export const TOKEN_EXPIRED_TEXT = 'Token expired!';

export const LOADING_TEXT = 'Loading...';

export const FREE_DAY_TEXT = 'F';
export const GAME_DAY_TEXT = 'G';
export const SPORT_DAY_TEXT = 'S';
