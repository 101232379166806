// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, RED_MAIN } from 'constants/color';

// Elements
import { Box } from '@mui/material';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const PlayerMatches = ({ title, data }) => (
  <Box>
    <PageTitle title={title} variant="h6" />
    {data.map(({ id, name, image_url, enemy, enemy_image_url, kda, win }) => (
      <Box key={id} display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width={26}
          height={26}
          fontSize={12}
          backgroundColor={win ? GREEN_MAIN : RED_MAIN}
          mr={1}
        >
          {win ? 'W' : 'L'}
        </Box>
        <ImageItem src={image_url} alt={name} width={32} autoHeight />
        <ImageItem src={enemy_image_url} alt={enemy} width={32} autoHeight />
        <Box fontSize={14} ml={2}>
          {kda}
        </Box>
      </Box>
    ))}
  </Box>
);

PlayerMatches.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default PlayerMatches;
