// React
import React from 'react';

// Elements
import ColumnTableData from 'components/molecules/ColumnTableData/ColumnTableData';

const avgData = (arr) =>
  Math.round((arr.reduce((total, value) => total + value, 0) / arr.length) * 100) / 100;

const findMedian = (arr) => {
  arr.sort((a, b) => a - b);

  const middleIndex = Math.floor(arr.length / 2);

  if (arr.length % 2 === 0) {
    return Math.round(((arr[middleIndex - 1] + arr[middleIndex]) / 2) * 100) / 100;
  }

  return arr[middleIndex];
};

export const footerTableData = (table, column, type, postFix, threshold, isBoolean) => {
  if (table.options.enableStickyFooter !== true) {
    return null;
  }

  let allValues = [];

  table.getFilteredRowModel().rows.map((row) => {
    if (!row?.original?.data) {
      const rowValue = row.getValue(column.id);

      if (rowValue !== null && rowValue !== undefined && rowValue !== '') {
        allValues.push(typeof rowValue === 'boolean' ? (rowValue ? 1 : 0) * 100 : rowValue);
      }
    } else {
      row?.original?.data?.map((el) => {
        const rowValue = el[column.id];

        if (rowValue !== null && rowValue !== undefined && rowValue !== '') {
          allValues.push(typeof rowValue === 'boolean' ? (rowValue ? 1 : 0) * 100 : rowValue);
        }

        return null;
      });
    }

    return null;
  });

  if (type === 'duration') {
    allValues = allValues.filter((value) => value !== 0);
  }

  if (type === 'durationDiff') {
    const columnName = column.id.replace('_diff', '');
    const columnData = table.getFilteredRowModel().rows.map((row) => {
      if (!row?.original?.data) {
        return row.getValue(columnName);
      }

      return row?.original?.data?.map((el) => el[columnName]);
    });

    const zeroIndexes = allValues
      .map((value, index) => (value === 0 ? index : undefined))
      .filter((value) => value !== undefined);

    zeroIndexes.forEach((index) => {
      if (columnData[0][index] === 0) {
        allValues.splice(index, 1);
      }
    });
  }

  const avgValue = avgData(allValues);
  const maxValue = Math.max(...allValues);
  const minValue = Math.min(...allValues);
  const medianValue = findMedian(allValues);

  if (
    avgValue === Infinity ||
    avgValue === -Infinity ||
    maxValue === Infinity ||
    maxValue === -Infinity ||
    minValue === Infinity ||
    minValue === -Infinity ||
    medianValue === Infinity ||
    medianValue === -Infinity ||
    Number.isNaN(avgValue) ||
    Number.isNaN(maxValue) ||
    Number.isNaN(minValue) ||
    Number.isNaN(medianValue) ||
    avgValue === undefined ||
    maxValue === undefined ||
    minValue === undefined ||
    medianValue === undefined
  ) {
    return null;
  }

  return (
    <ColumnTableData
      type={type}
      avgData={avgValue}
      medianData={medianValue}
      maxData={maxValue}
      minData={minValue}
      postFix={postFix}
      threshold={threshold}
      isBoolean={isBoolean}
    />
  );
};

export const footerTableTimeData = (table, column) => {
  if (table.options.enableStickyFooter !== true) {
    return null;
  }

  const allValues = [];

  table.getFilteredRowModel().rows.map((row) => {
    row?.original?.data?.map((el) => {
      const rowValue = el[column.id];

      if (rowValue !== null && rowValue !== undefined && rowValue !== '') {
        const { time } = rowValue[0];

        if (!time || time === 0) {
          return null;
        }

        allValues.push(time);
      }

      return null;
    });

    return null;
  });

  const avgValue = avgData(allValues);
  const maxValue = Math.max(...allValues);
  const minValue = Math.min(...allValues);
  const medianValue = findMedian(allValues);

  if (
    avgValue === Infinity ||
    avgValue === -Infinity ||
    maxValue === Infinity ||
    maxValue === -Infinity ||
    minValue === Infinity ||
    minValue === -Infinity ||
    medianValue === Infinity ||
    medianValue === -Infinity ||
    Number.isNaN(avgValue) ||
    Number.isNaN(maxValue) ||
    Number.isNaN(minValue) ||
    Number.isNaN(medianValue) ||
    avgValue === undefined ||
    maxValue === undefined ||
    minValue === undefined ||
    medianValue === undefined
  ) {
    return null;
  }

  return (
    <ColumnTableData
      type="duration"
      avgData={avgValue}
      medianData={medianValue}
      maxData={maxValue}
      minData={minValue}
      alignLeft
    />
  );
};
