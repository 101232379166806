// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Dayjs
import dayjs from 'dayjs';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSingleSplitBySlugs } from 'services/splits';
import { fetchMatches } from 'services/matches';

// Constants
import { MATCHES_TABLE } from 'constants/mrt';
import { MATCH_TYPES } from 'constants/match';
import { COMPETITIVES_LIST_COLS, COMPETITIVE_GAMES_LIST_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const SplitSingleMatchesView = () => {
  const {
    setTitleSEO,
    filters: {
      split: { id, rangeDate, patch, patches },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { leagueSlug, splitSlug } = useParams();
  const [split, setSplit] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (leagueValue, splitValue) => {
    setIsLoading(true);

    fetchSingleSplitBySlugs(leagueValue, splitValue).then((res) => setSplit(res));
  };

  const fetchData = (typeData, splitId, startDateData, endDateData, patchData) => {
    setIsLoading(true);

    fetchMatches(
      typeData,
      null,
      splitId,
      null,
      startDateData,
      endDateData,
      patchData,
      null,
      false,
      false,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        split: { ...prevValue.split, patches: res.patches },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(leagueSlug, splitSlug);
    }

    renderAfterCalled.current = true;
  }, [leagueSlug, splitSlug]);

  useEffect(() => {
    if (split) {
      fetchData(MATCH_TYPES.COMPETITIVE, split.id, rangeDate[0], rangeDate[1], patch);
    }
  }, [rangeDate, split, patch]);

  useEffect(() => {
    if (split) {
      const tempTitle = `${split.league_name} ${split.name} Matches`;
      const startDate = split?.start_date ? dayjs(split?.start_date) : null;
      const endDate = split?.end_date ? dayjs(split?.end_date) : null;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);

      if (id !== split.id || (rangeDate[0] === null && rangeDate[1] === null)) {
        setFilters((prevValue) => ({
          ...prevValue,
          split: {
            ...prevValue.split,
            id: split.id,
            rangeDate: [startDate, endDate],
          },
        }));
      }
    }
  }, [split]);

  const columns = useMemo(() => COMPETITIVES_LIST_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...MATCHES_TABLE(COMPETITIVE_GAMES_LIST_COLS),
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters rangeDateFilter patchesFilter={patches} type="split" />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default SplitSingleMatchesView;
