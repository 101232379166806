// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { BLUE_MAIN, GREEN_MAIN, RED_MAIN } from 'constants/color';

// Assets
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

// Component
const ResultData = ({ cell }) => {
  const { getValue } = cell;
  let component = <HorizontalRuleIcon fontSize="small" sx={{ color: BLUE_MAIN }} />;

  if (getValue() === 'W') {
    component = <CheckIcon fontSize="small" sx={{ color: GREEN_MAIN }} />;
  } else if (getValue() === 'L') {
    component = <CancelIcon fontSize="small" sx={{ color: RED_MAIN }} />;
  }

  return component;
};

ResultData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default ResultData;
