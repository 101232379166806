// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchScores } from 'services/data';

// Constants
import { TABLE_WITH_FILTERS_HEIGHT } from 'constants/table';
import { ROW_EXPAND_PROPS, TEAM_SCORE_LIST_COLS, SCRIM_SCORE_LIST_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { MaterialReactTable } from 'material-react-table';
import GamesTable from 'components/molecules/GamesTable/GamesTable';

const TeamSingleScoresView = () => {
  const {
    setTitleSEO,
    filters: {
      team: { matchType, rangeDate, side, patch, patches, opponent, opponents, blitz },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    teamId,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchScores(
      itemType,
      sideData,
      teamId,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        team: { ...prevValue.team, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(matchType, side, team.id, rangeDate[0], rangeDate[1], patch, opponent, blitz);
    }
  }, [matchType, rangeDate, side, team, patch, opponent, blitz]);

  useEffect(() => {
    if (team) {
      setTitle(`${team.name}`);
    }
  }, [team]);

  useEffect(() => {
    setTitleSEO(`${title} Scores`);
  }, [title]);

  const columns = useMemo(() => TEAM_SCORE_LIST_COLS, []);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            patchesFilter={patches}
            opponentsFilter={opponents}
            blitzFilter
            type="team"
          />
          <MaterialReactTable
            columns={columns}
            data={data}
            enableHiding={false}
            enableGlobalFilter={false}
            enableFilterMatchHighlighting={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableExpandAll={false}
            renderDetailPanel={({ row }) => (
              <GamesTable data={row.original.data} columns={SCRIM_SCORE_LIST_COLS} />
            )}
            displayColumnDefOptions={{
              'mrt-row-expand': ROW_EXPAND_PROPS,
            }}
            muiDetailPanelProps={{
              sx: { width: '100%', padding: 0, '.MuiCollapse-root': { width: '100%' } },
            }}
            initialState={{ columnPinning: { left: ['date', 'image', 'mrt-row-expand'] } }}
            enableBottomToolbar={false}
            enablePagination={false}
            enableStickyHeader
            muiTableContainerProps={{ sx: { maxHeight: TABLE_WITH_FILTERS_HEIGHT } }}
            layoutMode="grid-no-grow"
          />
        </>
      }
    />
  );
};

export default TeamSingleScoresView;
