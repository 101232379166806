// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { useMediaQuery } from '@mui/material';
import ChartBar from 'components/molecules/ChartBar/ChartBar';
import ImageAxis from 'components/atoms/ImageAxis/ImageAxis';

// Component
const GameDmgStatistics = ({ data, color, highValue, inverse }) => {
  const isTabletViewport = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <ChartBar
      data={data}
      color={color}
      highValue={highValue}
      vertical
      inverse={inverse && isTabletViewport}
      yTick={<ImageAxis inverse={inverse && isTabletViewport} />}
    />
  );
};

GameDmgStatistics.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
  highValue: PropTypes.number,
  inverse: PropTypes.bool,
};

GameDmgStatistics.defaultProps = {
  data: [],
  color: '#ffffff',
  highValue: 0,
  inverse: false,
};

export default GameDmgStatistics;
