const DRAFT_ORDER = [
  {
    id: 0,
    name: 'ANY',
    value: 0,
  },
];

for (let i = 1; i <= 10; i += 1) {
  DRAFT_ORDER.push({
    id: i,
    name: i,
    value: i,
  });
}

export const ALL_DRAFT_ORDER = [...DRAFT_ORDER];
