// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchSoloQMatchesReport } from 'services/soloq';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import TeamPlayersMatches from 'components/organisms/TeamPlayersMatches/TeamPlayersMatches';
import DataFilters from 'components/organisms/DataFilters/DataFilters';

const SoloQMatchesReportView = () => {
  const {
    setTitleSEO,
    filters: {
      soloq: { playerMainRole },
    },
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);
  const title = 'SoloQ Matches Report';

  const fetchData = (playerMainRoleData) => {
    setIsLoading(true);

    fetchSoloQMatchesReport(playerMainRoleData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(playerMainRole);
    }
  }, [playerMainRole]);

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters playerMainRoleFilter type="soloq" />
          {data && Object.keys(data).length > 0 && <TeamPlayersMatches data={data} />}
        </>
      }
    />
  );
};

export default SoloQMatchesReportView;
