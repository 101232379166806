export const MEAL_ATE = [
  {
    id: 'Y',
    value: 'Y',
    name: 'YES',
  },
  {
    id: 'N',
    value: 'N',
    name: 'NO',
  },
  {
    id: 'VL',
    value: 'VL',
    name: 'VERY LITTLE',
  },
  {
    id: 'OSE',
    value: 'OSE',
    name: 'ORDERED SOMETHING ELSE',
  },
];
