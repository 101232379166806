// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import { Box, Typography } from '@mui/material';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import BooleanData from 'components/atoms/BooleanData/BooleanData';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';

// Component
const BaronsData = ({ cell, single }) => {
  const { getValue } = cell;

  if (!getValue() || getValue().length === 0) {
    return null;
  }

  return (
    <Box display="flex" gap={2.5}>
      {getValue().map(({ id, time, team, gold }) => (
        <Box key={id} display="flex" alignItems="center" gap={1} minWidth={120}>
          {!single ? <GamePercentageData value={team} /> : null}
          <Typography variant="body2" component="span" textAlign="center">
            {secondsToHms(time)}
          </Typography>
          {single ? <BooleanData value={team} /> : null}
          {single ? <GameDiffData value={gold} small /> : null}
        </Box>
      ))}
    </Box>
  );
};

BaronsData.propTypes = {
  cell: PropTypes.object.isRequired,
  single: PropTypes.bool,
};

BaronsData.defaultProps = {
  single: false,
};

export default BaronsData;
