// React
import React from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Formik
import { Formik } from 'formik';

// Notistack
import { useSnackbar } from 'notistack';

// Elements
import { Grid, Box, Typography } from '@mui/material';
import AdminManageColumn from 'components/molecules/AdminManageColumn/AdminManageColumn';
import LoadingButton from '@mui/lab/LoadingButton';

// Component
const AdminManage = ({
  initialValues,
  validationSchema,
  formikType,
  formikFields1,
  formikFields1Size,
  formikFields2,
  formikFields2Size,
  formikFields3,
  formikFields3Size,
  formikFields4,
  formikFields4Size,
  formikFn,
  formikFormData,
  formikAdditionalFnArgs,
  formikRedirect,
  formikBtnText,
  additionalBtns,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const loadingIndicator = formikType === 'edit' ? 'Updating...' : 'Adding...';

  const handleCustomSubmit = async (values, { resetForm, setSubmitting }) => {
    let newValues = values;

    if ('start_date' in values) {
      newValues.start_date = values.start_date !== '' ? values.start_date : null;
    }

    if ('end_date' in values) {
      newValues.end_date = values.end_date !== '' ? values.end_date : null;
    }

    if (formikFormData) {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      newValues = formData;
    }

    try {
      const res = await formikFn(...formikAdditionalFnArgs, newValues);

      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      if (res.data) {
        resetForm();

        if (formikRedirect) {
          navigate(formikRedirect);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleCustomSubmit}
      >
        {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container>
              {formikFields1 && formikFields1.length > 0 && (
                <AdminManageColumn
                  fields={formikFields1}
                  size={formikFields1Size}
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              {formikFields2 && formikFields2.length > 0 && (
                <AdminManageColumn
                  fields={formikFields2}
                  size={formikFields2Size}
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              {formikFields3 && formikFields3.length > 0 && (
                <AdminManageColumn
                  fields={formikFields3}
                  size={formikFields3Size}
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              {formikFields4 && formikFields4.length > 0 && (
                <AdminManageColumn
                  fields={formikFields4}
                  size={formikFields4Size}
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
            </Grid>
            <Box mt={1}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSubmitting}
                loadingIndicator={loadingIndicator}
              >
                {formikBtnText}
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
      {additionalBtns && additionalBtns.length > 0 ? (
        <Box mt={3}>
          <Typography variant="h6" component="p">
            Actions
          </Typography>
          <Box mt={1}>
            {additionalBtns.map(
              ({ id: btnId, loadingState, loadingText, btnText, btnIcon, btnFn }, index) => (
                <LoadingButton
                  key={btnId}
                  loading={loadingState}
                  loadingIndicator={loadingText}
                  variant="contained"
                  startIcon={btnIcon}
                  onClick={btnFn}
                  sx={{ ml: index > 0 && 1 }}
                >
                  {btnText}
                </LoadingButton>
              ),
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

AdminManage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  formikType: PropTypes.string.isRequired,
  formikFields1: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      typeField: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  formikFields1Size: PropTypes.number,
  formikFields2: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      typeField: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  formikFields2Size: PropTypes.number,
  formikFields3: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      typeField: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  formikFields3Size: PropTypes.number,
  formikFields4: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      typeField: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  formikFields4Size: PropTypes.number,
  formikFn: PropTypes.func.isRequired,
  formikFormData: PropTypes.bool,
  formikAdditionalFnArgs: PropTypes.array,
  formikRedirect: PropTypes.string,
  formikBtnText: PropTypes.string.isRequired,
  additionalBtns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      loadingState: PropTypes.bool.isRequired,
      loadingText: PropTypes.string.isRequired,
      btnText: PropTypes.string.isRequired,
      btnIcon: PropTypes.element,
      btnFn: PropTypes.func.isRequired,
    }),
  ),
};

AdminManage.defaultProps = {
  validationSchema: null,
  formikFields1Size: 1,
  formikFields2: [],
  formikFields2Size: 1,
  formikFields3: [],
  formikFields3Size: 1,
  formikFields4: [],
  formikFields4Size: 1,
  formikFormData: false,
  formikAdditionalFnArgs: [],
  formikRedirect: null,
  additionalBtns: [],
};

export default AdminManage;
