// React
import React, { useContext } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Elements
import { DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// Styles
import { StyledItemName } from './DialogConfirm.styles';

// Component
const DialogConfirm = ({ title, open, itemName, dialogFn, loading }) => {
  const { setIsDialogOpen } = useContext(Context);
  const loadingIndicator = 'Deleting...';
  const minWidth = 150;

  const handleCancel = () => setIsDialogOpen(false);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        Are you sure you want delete <StyledItemName>{itemName}</StyledItemName>?
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          loadingIndicator={loadingIndicator}
          onClick={handleCancel}
          variant="outlined"
          sx={{ minWidth }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          loadingIndicator={loadingIndicator}
          onClick={dialogFn}
          variant="contained"
          sx={{ minWidth }}
        >
          Ok
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DialogConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  itemName: PropTypes.string.isRequired,
  dialogFn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

DialogConfirm.defaultProps = {
  title: 'Confirm delete',
};

export default DialogConfirm;
