// Dayjs
import dayjs from 'dayjs';

// Constants
import {
  MATCH_TYPES_ALL,
  MATCH_TYPES_S,
  MATCH_TYPES,
  SIDES,
  RESULTS,
  PATCHES,
  OPPONENTS,
  ROLES_ALL,
  ROLES,
  SYNERGIES,
  PICKS,
  ENEMY,
  CURRENT_TIME,
  WARD_TYPE,
  PATH_TYPE,
  TIME_RANGE_TYPE,
  OBJECTIVE_TYPE,
  WARD_OBJECTIVE_TYPE,
  SUMMONER_TYPE,
  GRAPH_TEAM_TYPE,
  GRAPH_PLAYER_TYPE,
  GRAPH_DATA_PLAYER_TYPE,
  STATISTIC_TEAM_TYPE,
  TEAM_OBJECTIVE_TYPE,
} from 'constants/select';

// const start = dayjs('2024-01-10');
// const end = dayjs('2024-01-12');
export const now = dayjs();

export const FILTER_CONTEXT = {
  scrims: {
    // rangeDate: [start, end],
    rangeDate: [now.subtract(10, 'day'), now],
    side: SIDES.options[0].value,
    result: RESULTS.options[0].value,
    patch: PATCHES.options[0].value,
    patches: [],
    opponent: OPPONENTS.options[0].value,
    opponents: [],
    roleAll: ROLES_ALL.options[0].value,
    blitz: true,
    draftFilter: null,
  },
  daily: {
    rangeDate: [now.subtract(7, 'day'), now.subtract(1, 'day')],
  },
  soloq: {
    rangeDate: [now.subtract(7, 'day'), now.subtract(1, 'day')],
    playerMainRole: false,
  },
  split: {
    id: null,
    rangeDate: [null, null],
    pick: PICKS.options[0].value,
    side: SIDES.options[0].value,
    patch: PATCHES.options[0].value,
    patches: [],
    opponent: OPPONENTS.options[0].value,
    opponents: [],
    roleAll: ROLES_ALL.options[0].value,
    synergy: SYNERGIES.options[0].value,
    timerange: TIME_RANGE_TYPE.value,
    statisticTeam: STATISTIC_TEAM_TYPE.options[0].value,
    soloQRangeDate: [now.subtract(7, 'day'), now],
  },
  team: {
    matchTypeAll: MATCH_TYPES_ALL.options[0].value,
    matchType: MATCH_TYPES.options[0].value,
    // rangeDate: [start, end],
    rangeDate: [now.subtract(10, 'day'), now],
    pick: PICKS.options[0].value,
    enemy: ENEMY.options[0].value,
    side: SIDES.options[0].value,
    result: RESULTS.options[0].value,
    patch: PATCHES.options[0].value,
    patches: [],
    opponent: OPPONENTS.options[0].value,
    opponents: [],
    roleAll: ROLES_ALL.options[0].value,
    role: ROLES.options[0].value,
    multirole: [ROLES.options[0].value],
    synergy: SYNERGIES.options[0].value,
    currentTime: CURRENT_TIME.options[0].value,
    wardType: WARD_TYPE.options[0].value,
    pathType: PATH_TYPE.options[0].value,
    timerange: TIME_RANGE_TYPE.value,
    objective: OBJECTIVE_TYPE.options[0].value,
    wardObjective: WARD_OBJECTIVE_TYPE.options[0].value,
    teamObjective: TEAM_OBJECTIVE_TYPE.options[0].value,
    summoner: SUMMONER_TYPE.options[0].value,
    graphTeamType: GRAPH_TEAM_TYPE.options[0].value,
    wardsGroup: false,
    playerMainRole: false,
    blitz: true,
    draftFilter: null,
  },
  player: {
    matchTypeS: MATCH_TYPES_S.options[0].value,
    // rangeDate: [start, end],
    rangeDate: [now.subtract(10, 'day'), now],
    side: SIDES.options[0].value,
    patch: PATCHES.options[0].value,
    patches: [],
    opponent: OPPONENTS.options[0].value,
    opponents: [],
    timerange: TIME_RANGE_TYPE.value,
    graphPlayerType: GRAPH_PLAYER_TYPE.options[0].value,
    graphDataPlayerType: GRAPH_DATA_PLAYER_TYPE.options[0].value,
    blitz: true,
    playerMainRole: false,
  },
  champion: {
    matchTypeS: MATCH_TYPES_S.options[0].value,
    rangeDate: [null, null],
    side: SIDES.options[0].value,
    patch: PATCHES.options[0].value,
    patches: [],
  },
  performance: {
    rangeDate: [now.subtract(6, 'day'), now],
    ate: true,
    sport: true,
    snack: true,
  },
  game: {
    role: ROLES.options[0].value,
    timerange: TIME_RANGE_TYPE.value,
  },
};
