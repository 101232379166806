// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import BooleanData from 'components/atoms/BooleanData/BooleanData';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';

// Component
const DragonsData = ({ cell, single }) => {
  if (!cell.getValue()) {
    return null;
  }

  return (
    <Box display="flex" gap={2.5}>
      {cell.getValue().map(({ id, name, image, time, team }) => (
        <Box key={id} display="flex" alignItems="center" gap={1} minWidth={110}>
          {single ? (
            <ImageItem src={image} alt={name} width={32} height={32} />
          ) : (
            <GamePercentageData value={team} />
          )}
          <Typography variant="body2" component="span" textAlign="center">
            {secondsToHms(time)}
          </Typography>
          {single ? <BooleanData value={team} /> : null}
        </Box>
      ))}
    </Box>
  );
};

DragonsData.propTypes = {
  cell: PropTypes.object.isRequired,
  single: PropTypes.bool,
};

DragonsData.defaultProps = {
  single: false,
};

export default DragonsData;
