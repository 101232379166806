// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, RED_MAIN, BLACK_MAIN } from 'constants/color';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const TeamCard = ({ team, isWin, logoSize }) => {
  const { image, name } = team;

  return (
    <Box>
      <ImageItem src={image} alt={name} width={logoSize} height={logoSize} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={isWin ? GREEN_MAIN : RED_MAIN}
        color={BLACK_MAIN}
        mt={1}
      >
        <Typography variant="body2" component="p" fontWeight="bold" textAlign="center">
          {isWin ? 'WIN' : 'LOSE'}
        </Typography>
      </Box>
    </Box>
  );
};

TeamCard.propTypes = {
  team: PropTypes.object.isRequired,
  isWin: PropTypes.bool.isRequired,
  logoSize: PropTypes.number.isRequired,
};

export default TeamCard;
