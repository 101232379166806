// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Services
import { fetchSingleSplitBySlugs, fetchSplitSoloQ } from 'services/splits';

// Constants
import { ROLES } from 'constants/role';
import { PLAYERS_STATISTICS } from 'constants/statistic';
import { LOADING_TEXT } from 'constants/text';

// Schemas
import { OBJECT_REQUIRED_SCHEMA, STRING_REQUIRED_SCHEMA } from 'schemas/base';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid } from '@mui/material';
import DateRangePicker from 'components/molecules/DateRangePicker/DateRangePicker';
import Select from 'components/atoms/Select/Select';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import ChartComposed from 'components/atoms/ChartComposed/ChartComposed';

const SplitSingleSoloQView = () => {
  const {
    setTitleSEO,
    setIsLoading,
    filters: {
      split: { soloQRangeDate },
    },
  } = useContext(Context);
  const { leagueSlug, splitSlug } = useParams();
  const [split, setSplit] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (leagueValue, splitValue) => {
    setIsLoading(true);

    fetchSingleSplitBySlugs(leagueValue, splitValue).then((res) => {
      setSplit(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(leagueSlug, splitSlug);
    }

    renderAfterCalled.current = true;
  }, [leagueSlug, splitSlug]);

  useEffect(() => {
    if (split) {
      const tempTitle = `${split.league_name} ${split.name} SoloQ`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [split]);

  const initialValues = {
    split_id: split?.id,
    role: 'TOP',
    statistic: null,
    start_date: soloQRangeDate[0],
    end_date: soloQRangeDate[1],
  };

  const validationSchema = Yup.object().shape({
    role: STRING_REQUIRED_SCHEMA,
    statistic: OBJECT_REQUIRED_SCHEMA,
    start_date: OBJECT_REQUIRED_SCHEMA,
    end_date: OBJECT_REQUIRED_SCHEMA,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <Box my={2}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} lg={5} xl={4}>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setData([]);

                    fetchSplitSoloQ(values).then(({ data: respData }) => {
                      setData(respData);

                      setSubmitting(false);
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <Box component="form" onSubmit={handleSubmit}>
                      <DateRangePicker
                        data={{
                          start: {
                            name: 'start_date',
                            label: 'Start Date',
                            value: values.start_date,
                          },
                          end: { name: 'end_date', label: 'End Date', value: values.end_date },
                        }}
                        onChangeFn={setFieldValue}
                      />
                      <Grid container>
                        <Grid item xs={12} md={6} lg={4}>
                          <Select
                            error={touched.role && !!errors.role}
                            helperText={touched.role && errors.role}
                            name="role"
                            label="Role"
                            optionLabel="label"
                            options={ROLES}
                            displayEmpty={false}
                            value={values.role}
                            compareValue="value"
                            onChange={(e) => {
                              handleChange(e);

                              setData([]);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                          <Autocomplete
                            id="statistic"
                            label="Statistic"
                            options={PLAYERS_STATISTICS()}
                            optionLabel="label"
                            onChange={(_, newValue) => {
                              setFieldValue('statistic', newValue);
                            }}
                            error={touched.statistic && !!errors.statistic}
                            helperText={touched.statistic && errors.statistic}
                            value={values.statistic}
                            compareValue="value"
                          />
                        </Grid>
                      </Grid>
                      <Box mt={1}>
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={isSubmitting}
                          loadingIndicator={LOADING_TEXT}
                        >
                          Show
                        </LoadingButton>
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={12} lg={6} xl={8}>
                {data && data.length > 0 && <ChartComposed data={data} />}
              </Grid>
            </Grid>
          </Box>
        </>
      }
    />
  );
};

export default SplitSingleSoloQView;
