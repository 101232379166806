// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Services
import { fetchPlayersByRole } from 'services/players';
import { comparePlayers } from 'services/compare';

// Constants
import { ROLES } from 'constants/role';
import { LOADING_TEXT } from 'constants/text';

// Schemas
import { STRING_REQUIRED_SCHEMA, OBJECT_NULLABLE_SCHEMA } from 'schemas/base';
import { COMPARE_PLAYERS_SCHEMA } from 'schemas/compare';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid } from '@mui/material';
import MultiAutocomplete from 'components/atoms/MultiAutocomplete/MultiAutocomplete';
import Select from 'components/atoms/Select/Select';
import DateRangePicker from 'components/molecules/DateRangePicker/DateRangePicker';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import ChartRadar from 'components/organisms/ChartRadar/ChartRadar';

const ComparePlayersView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [players, setPlayers] = useState({});
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Compare Players';

  const fetchData = () => {
    setIsLoading(true);

    fetchPlayersByRole().then((res) => {
      setPlayers(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    start: null,
    end: null,
    role: 'TOP',
    players: [],
  };

  const validationSchema = Yup.object().shape({
    start: OBJECT_NULLABLE_SCHEMA,
    end: OBJECT_NULLABLE_SCHEMA,
    role: STRING_REQUIRED_SCHEMA,
    players: COMPARE_PLAYERS_SCHEMA,
  });

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Grid container>
          <Grid item xs={12} lg={5} xl={4}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                comparePlayers(values).then(({ data: respData }) => {
                  setData(respData);

                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
                isSubmitting,
              }) => {
                const playerRoles = players[values.role] || [];

                return (
                  <Box component="form" onSubmit={handleSubmit}>
                    <DateRangePicker
                      data={{
                        start: { name: 'start', label: 'Start Date', value: values.start },
                        end: { name: 'end', label: 'End Date', value: values.end },
                      }}
                      onChangeFn={setFieldValue}
                    />
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <Select
                          error={touched.role && !!errors.role}
                          helperText={touched.role && errors.role}
                          name="role"
                          label="Role"
                          optionLabel="label"
                          options={ROLES}
                          displayEmpty={false}
                          value={values.role}
                          compareValue="value"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('players', []);

                            setData({});
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <MultiAutocomplete
                          disabled={playerRoles.length === 0}
                          error={touched.players && !!errors.players}
                          helperText={touched.players && errors.players}
                          name="players"
                          label="Players"
                          options={playerRoles}
                          value={values.players}
                          onChangeFunction={setFieldValue}
                          onChangeField="players"
                        />
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={isSubmitting}
                        loadingIndicator={LOADING_TEXT}
                      >
                        Compare
                      </LoadingButton>
                    </Box>
                  </Box>
                );
              }}
            </Formik>
          </Grid>
          {data && data.length > 0 && (
            <>
              <Box component={Grid} item lg={1} display={{ xs: 'none', lg: 'block' }} />
              <Grid item xs={12} lg={6}>
                <ChartRadar data={data} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ComparePlayersView;
