// React
import React, { useState, useEffect } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Reacharts
import {
  ComposedChart,
  Scatter,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Constants
import { BLUE_MAIN, RED_MAIN, WHITE_MAIN } from 'constants/color';

// Utils
import { gradientOffset } from 'utils/recharts';
import { secondsToHms } from 'utils/time';

// Elements
import { useMediaQuery } from '@mui/material';
import ChartTooltip from 'components/molecules/ChartTooltip/ChartTooltip';

// Component
const ChartArea = ({ data, chartKey, timeline, type, yAxis }) => {
  const [chartData, setChartData] = useState([]);

  let aspectRatio = 2;

  if (useMediaQuery((theme) => theme.breakpoints.up('lg'))) {
    aspectRatio = 3;
  }
  if (useMediaQuery((theme) => theme.breakpoints.up('xl'))) {
    aspectRatio = 4;
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const newData = data.map((item) => {
        const newItem = { ...item };

        if (timeline && Object.keys(timeline).length > 0 && timeline[item.name] && type === null) {
          newItem.timeline = true;
        }

        if (type === 'half') {
          newItem.transformedName = secondsToHms(item.name * 30);
        }

        return newItem;
      });

      setChartData(newData);
    }
  }, [data]);

  if (!data || data.length === 0) {
    return null;
  }

  const off = gradientOffset(data, chartKey);

  const ticksX = data
    .filter((item, index) => item.name % 5 === 0 || index === 0 || index === data.length - 1)
    .map((item, index) =>
      type === 'half' && (item.name % 10 === 0 || index === 0 || index === data.length - 1)
        ? secondsToHms(item.name * 30)
        : item.name,
    );

  const maxY = Math.max(...data.map((item) => item[chartKey]));
  const minY = Math.min(...data.map((item) => item[chartKey]));

  const ticksY = yAxis || [minY, 0, maxY];

  return (
    <ResponsiveContainer width="100%" height="auto" aspect={aspectRatio}>
      <ComposedChart margin={{ top: 16, right: 32, bottom: 16, left: 32 }} data={chartData}>
        <defs>
          <linearGradient id="area" x1="0" y1="0" x2="0" y2="1">
            <stop offset={off} stopColor={BLUE_MAIN} stopOpacity={1} />
            <stop offset={off} stopColor={RED_MAIN} stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid />
        <XAxis dataKey={type === 'half' ? 'transformedName' : 'name'} ticks={ticksX} />
        <YAxis ticks={ticksY} domain={yAxis || [('dataMin', 'dataMax')]} />
        <Tooltip
          content={
            <ChartTooltip
              additionalLabelText={type === 'half' ? null : 'min'}
              timeline={timeline}
            />
          }
        />
        <Area
          type="monotone"
          dataKey={chartKey}
          stroke="none"
          fill="url(#area)"
          isAnimationActive={false}
        />
        {timeline ? (
          <Scatter shape="star" dataKey="timeline" fill={WHITE_MAIN} isAnimationActive={false} />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

ChartArea.propTypes = {
  data: PropTypes.array,
  chartKey: PropTypes.string.isRequired,
  timeline: PropTypes.object,
  type: PropTypes.string,
  yAxis: PropTypes.arrayOf(PropTypes.number),
};

ChartArea.defaultProps = {
  data: [],
  timeline: null,
  type: null,
  yAxis: null,
};

export default ChartArea;
