// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Routes
import { adminTeamsAddURL, adminTeamsBaseURL } from 'routes/admin';

// Services
import { fetchTeams, importAllRosters, importAllImages, deleteTeam } from 'services/teams';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_TEAMS_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

// Elements
import AdminList from 'components/organisms/AdminList/AdminList';

const AdminTeamsView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);
  const title = `Teams ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchTeams().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  const importRosters = () => {
    setBtnLoading(true);

    importAllRosters().then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchData();

      setBtnLoading(false);
    });
  };

  const importImages = () => {
    setBtnLoading(true);

    importAllImages().then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchData();

      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const buttons = [
    {
      id: 1,
      btnText: 'Add team',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: adminTeamsAddURL,
    },
    {
      id: 2,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import rosters',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importRosters,
    },
    {
      id: 3,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import images',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importImages,
    },
  ];

  return (
    <AdminList
      buttons={buttons}
      title={title}
      tableCols={ADMIN_TEAMS_COLS}
      tableData={data}
      baseURL={adminTeamsBaseURL}
      deleteFn={deleteTeam}
      refreshFn={fetchData}
    />
  );
};

export default AdminTeamsView;
