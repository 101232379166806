// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION, FULL_HEIGHT } from 'constants/mrt';

// Elements
import { Box } from '@mui/material';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

// Component
const ChampionPool = ({ title, data, columns }) => {
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
    ...FULL_HEIGHT,
    initialState: { density: 'compact' },
  });

  return (
    <Box>
      <PageTitle title={title} variant="h6" />
      <MaterialReactTable table={table} />
    </Box>
  );
};

ChampionPool.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default ChampionPool;
