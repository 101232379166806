// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box } from '@mui/material';
import InfoTooltip from 'components/atoms/InfoTooltip/InfoTooltip';

// Component
const ColumnHeader = ({ title, tooltip }) => (
  <Box display="flex" alignItems="center">
    <Box mr={0.5}>{title}</Box>
    <InfoTooltip data={tooltip} />
  </Box>
);

ColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ColumnHeader;
